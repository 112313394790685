<template>
  <div>
    <div
      v-for="category in paymentDataByCategories"
      :key="category.categoryId"
      class="mb--30"
    >
      <div class="payment-table">
        <div class="payment-table__title center">
          <div class="payment-table__title-text">{{ category.title }}</div>
        </div>
        <div class="payment-table__content">
          <div
            v-for="(item, i) in prepareTableData(category)"
            :key="i"
            class="payment-table__item"
          >
            <div class="payment-table__item-title third-color">
              {{ item.title }}
            </div>
            <div class="payment-table__item-value">
              {{
                Array.isArray(item.value)
                  ? item.value.map(el => el.name).join(', ')
                  : item.value === ''
                    ? '-'
                    : item.value
              }}
            </div>
          </div>
          <div
            v-if="
              useAppStore().getWindowWidth() > 600 &&
              prepareTableData(category).length % 2 === 1
            "
            class="payment-table__item"
          >
            <div class="payment-table__item-title"></div>
            <div class="payment-table__item-value"></div>
          </div>
        </div>
      </div>
      <div v-if="category.items.length > 6" class="center mt--16">
        <base-button
          :text="category.showAllTable ? 'Свернуть' : 'Развернуть'"
          height="36"
          style-type="coloredBorder"
          width="186"
          @click="category.showAllTable = !category.showAllTable"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, Ref, ref } from 'vue';
import { useAppStore } from '@/store/modules/app';

type TableItemsType = { title: string; value: string }[];

type PaymentDataType = {
  categoryId: number;
  title: string;
  items: TableItemsType;
  showAllTable: boolean;
};

const props = withDefaults(
  defineProps<{
    tableData: {
      title: string;
      items: TableItemsType;
    }[];
    showAllTable?: boolean;
  }>(),
  {},
);

const prepareTableData = (item: PaymentDataType): TableItemsType => {
  return item.showAllTable ? item.items : [...item.items].slice(0, 6);
};

const paymentDataByCategories: Ref<PaymentDataType[]> = ref([]);

onMounted(() => {
  if (props.tableData) {
    props.tableData.forEach((el, index) => {
      paymentDataByCategories.value.push({
        categoryId: index,
        title: el.title,
        items: el.items,
        showAllTable: props.showAllTable,
      });
    });
  }
});
</script>

<style lang="scss" scoped src="./PassportTable.scss"></style>
