<template>
  <div v-if="windowWidth > 600" :class="{ translucent: mainPage }" class="menu">
    <div class="container menu__items">
      <span v-for="menuItem in menuItems" :key="menuItem.id" class="relative">
        <router-link
          v-if="menuItem.href"
          :to="menuItem.href"
          @click="activeMenuId = undefined"
        >
          {{ menuItem.title }}
        </router-link>
        <button v-else-if="menuItem.items" @click.stop="activeMenuId = menuItem.id">
          {{ menuItem.title }}
        </button>
        <button v-else @click="menuItem.clickHandler">
          {{ menuItem.title }}
        </button>
        <span
          v-if="activeMenuId === menuItem.id"
          v-click-outside="clickOutSize"
          class="menu__items-sub-menu"
        >
          <router-link
            v-for="subMenuItem in menuItem.items"
            :key="subMenuItem.id"
            :to="{ name: subMenuItem.href }"
            class="menu__items-sub-menu-item"
            @click="activeMenuId = undefined"
          >
            {{ subMenuItem.title }}
          </router-link>
        </span>
      </span>
    </div>
  </div>
  <div v-else v-click-outside="clickOutside" class="mobileMenu">
    <div
      :class="{ open: isMenuOpen }"
      class="mobileMenu-btn"
      @click="isMenuOpen = !isMenuOpen"
    >
      <div class="icon"></div>
    </div>
    <div v-if="isMenuOpen" class="mobileMenu__options">
      <span
        v-for="menuItem in menuItems"
        :key="menuItem.id"
        class="relative"
        @click="isMenuOpen = false"
      >
        <router-link v-if="menuItem.href" :to="menuItem.href">
          {{ menuItem.title }}
        </router-link>
        <span v-else-if="menuItem.items" class="items-in-column">
          <button class="items-in-line gap--5" @click="activeMenuId = menuItem.id">
            <base-icon
              v-if="(activeMenuId = menuItem.id)"
              name="arrow_drop_up"
              width="10"
            />
            <base-icon v-else name="arrow_drop_down" width="10" />

            {{ menuItem.title }}
          </button>
          <span v-if="(activeMenuId = menuItem.id)" class="mobileMenu__options-sub-menu">
            <router-link
              v-for="subMenuItem in menuItem.items"
              :key="subMenuItem.id"
              :to="{ name: subMenuItem.href }"
            >
              {{ subMenuItem.title }}
            </router-link>
          </span>
        </span>

        <button v-else @click="menuItem.clickHandler">{{ menuItem.title }}</button>
      </span>
    </div>
  </div>

  <ReviewModal :show-modal="showReviewModal" @close="showReviewModal = false" />
</template>

<script lang="ts" setup>
import { computed, inject, Ref, ref } from 'vue';
import { type RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import ReviewModal from '@/components/ReviewModal/ReviewModal.vue';
import { useAppStore } from '@/store/modules/app';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { SmoothScrollOptions } from 'vue3-smooth-scroll';

const showReviewModal = ref(false);
const activeMenuId: Ref<number | undefined> = ref(undefined);

const windowWidth = computed((): number => {
  return useAppStore().window;
});

type MenuItemType = {
  id: number;
  title: string;
  items?: { id: number; title: string; href?: string }[];
  clickHandler?: () => void;
  href?: RouteLocationRaw;
};
const menuItems: Ref<MenuItemType[]> = ref([
  {
    id: 1,
    title: 'Популярные объявления',
    clickHandler: () => showPopularityOffers(),
  },
  {
    id: 2,
    title: 'Застройщики',
    href: { name: 'developerCards' },
  },
  {
    id: 3,
    title: 'Поселки',
    href: { name: 'residentialComplexCards' },
  },
  {
    id: 4,
    title: 'Сервисы',
    href: {
      name: 'services',
    },
  },
  {
    id: 5,
    title: 'Ипотека',
    href: { name: 'mortgage' },
  },
  {
    id: 6,
    title: 'Отзыв / предложение',
    clickHandler: () => {
      showReviewModal.value = true;
    },
  },
]);
const isMenuOpen = ref(false);

const clickOutside = () => {
  isMenuOpen.value = false;
};

const route = useRoute();
const router = useRouter();

const mainPage = computed((): boolean => {
  return route.name === 'main';
});

const clickOutSize = () => {
  activeMenuId.value = undefined;
};
const smoothScroll = inject('smoothScroll') as (args: SmoothScrollOptions) => void;
const showPopularityOffers = async () => {
  useOfferCardsStore().returnToInitialState();
  useOfferCardsStore().updateSortParam('popularityDown');
  await router.push({ name: 'main' });
  await router.replace({ query: undefined });
  useOfferCardsStore().showResultBlock();

  const myEl = document.getElementById('offerCardsList');

  if (myEl) {
    smoothScroll({
      scrollTo: myEl,
      offset: 0,
    });
  }
};
</script>

<style lang="scss" scoped src="./Menu.scss"></style>
