<template>
  <div class="container content construction-firm-cards">
    <div class="items-in-line gap--0 flex-start">
      <h2 class="construction-firm-cards__title">
        {{
          `Список жилых комплексов / поселков Ростовской области${countResidentialComplex ? `: ${countResidentialComplex}` : ''}`
        }}
      </h2>
    </div>
    <div>
      <base-skeleton-loader
        v-if="isResidentialComplexLoading"
        :style="{ height: '30px', width: '100%' }"
        type="mediumText"
      />
      <base-input
        v-else
        v-model="searchText"
        appendIcon="search"
        label="Поиск по названию"
        @blur="searchHandler"
      />
    </div>

    <div v-if="isResidentialComplexLoading" class="construction-firm-cards__container">
      <LoadingResidentialComplexCard v-for="i in 12" :key="i" />
    </div>
    <div v-else class="construction-firm-cards__container">
      <ResidentialComplexCard
        v-for="(project, i) in residentialComplexList"
        :key="i"
        :project="project"
        version="v5"
      />
    </div>
    <base-pagination-group
      :customPage="paginationPage"
      :isLoading="isResidentialComplexLoading"
      :isMoreLoading="isMoreLoading"
      :itemCountOnPage="pageSize"
      :max-for-visible="countResidentialComplex"
      :min-for-visible="4"
      :objectCount="countResidentialComplex"
      :step-for-visible="4"
      :value="paginationPage"
      :values="residentialComplexList"
      auto-add-mobile
      @changePageAndPageSize="changePageAndPageSize($event)"
    />
  </div>
</template>

<script lang="ts" setup>
import LoadingResidentialComplexCard from '@/components/ResidentialComplexCard/LoadingResidentialComplexCard.vue';
import { onMounted, Ref, ref } from 'vue';
import { ListDataType } from '@/api/developer/types';
import { useDevelopersStore } from '@/store/modules/developers';
import { ResidentialComplexCard } from '@/components';
import { SmallCardLSDType } from '@/types';

const isResidentialComplexLoading = ref(false);
const isMoreLoading = ref(false);
const residentialComplexList: Ref<SmallCardLSDType[]> = ref([]);
const countResidentialComplex: Ref<undefined | number> = ref(undefined);
const paginationPage = ref(1);
const pageSize = ref(12);
const searchText: Ref<undefined | string> = ref(undefined);

const searchHandler = () => {
  updateResidentialComplexList({
    page: paginationPage.value,
    pageSize: pageSize.value,
    search: searchText.value,
  });
};

const changePageAndPageSize = (event: {
  page: number;
  pageSize: number;
  showMore?: true;
}) => {
  updateResidentialComplexList(
    {
      page: event.page,
      pageSize: event.pageSize,
      search: searchText.value,
    },
    event.showMore,
  );
};

const updateResidentialComplexList = async (data: ListDataType, showMore?: boolean) => {
  isResidentialComplexLoading.value = true;
  try {
    const res = await useDevelopersStore().searchAllResidentialComplex(data);

    if (res) {
      if (showMore) {
        residentialComplexList.value = [
          ...residentialComplexList.value,
          ...res.residentialComplex,
        ];
      } else {
        residentialComplexList.value = res.residentialComplex;
      }

      countResidentialComplex.value = res.count;
      pageSize.value = res.pageSize;
    }
  } finally {
    isResidentialComplexLoading.value = false;
  }
};

onMounted(async () => {
  await updateResidentialComplexList({});
});
</script>

<style lang="scss" scoped src="./ResidentialComplexCards.scss"></style>
