<template>
  <div>
    <base-card class="m-developer-card">
      <div class="m-developer-card__container">
        <component
          :is="developer.url ? 'router-link' : 'span'"
          :to="{ name: 'developer', params: { url: developer.url } }"
          class="m-developer-card__img"
        >
          <base-image
            :height="window > 600 ? '119' : '90'"
            :name="developer.title"
            :src="developer.logo"
            :title="developer.title"
            :width="window > 600 ? '204' : '100%'"
            border-radius
            contain
            version="v4"
          />
        </component>
        <div class="m-developer-card__title">{{ developer.title || '-' }}</div>
        <div class="m-developer-card__content mt--12">
          <div class="m-developer-card__content-info">
            <div>
              <div>
                <span class="dark-text-color">Сдано: </span>
                <span>{{ developer.builtCount || '-' }}</span>
              </div>
              <div>
                <span class="dark-text-color">Строится: </span>
                <span>{{ developer.buildingCount || '-' }}</span>
              </div>
              <div class="items-in-line flex-start gap--5">
                <span class="dark-text-color">Строит с: </span>
                <span>{{ developer.yearOfFoundation || '-' }}</span>
              </div>
            </div>
            <div>
              <component
                :is="developer.url ? 'router-link' : 'span'"
                :to="{
                  name: 'developer',
                  params: { url: developer.url },
                  hash: '#allLCD',
                }"
              >
                <div class="underline">Витрина ЖК</div>
              </component>
            </div>

            <base-button
              v-if="withLink"
              :disabled="!developer.url"
              :href="{ name: 'developer', params: { url: developer.url } }"
              all-block-width
              class="none-Mobile"
              style-type="coloredBorder"
              text="Подробнее"
            />
          </div>
          <div class="m-developer-card__content-icons">
            <SaveDocsBtn :docs="developer.documents || []" class="scale" />
            <base-button
              v-if="withLink"
              :disabled="!developer.url"
              :href="{ name: 'developer', params: { url: developer.url }, hash: '#map' }"
              icon
              style-type="coloredBorder"
              withoutActive
            >
              <template #icon>
                <img
                  :src="require('@/assets/img/mainPage/icons/icon-map.png')"
                  alt="map"
                />
              </template>
            </base-button>
          </div>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script lang="ts" setup>
import { SmallDeveloperCardType } from '@/types/DeveloperTypes';
import { computed } from 'vue';
import { useAppStore } from '@/store/modules/app';
import { SaveDocsBtn } from '@/components/Cards/CardsComponents';

const props = withDefaults(
  defineProps<{
    isFirst?: boolean;
    developer: SmallDeveloperCardType;
    withLink?: boolean;
  }>(),
  {
    isFirst: false,
    withLink: true,
  },
);

const window = computed((): number => {
  return useAppStore().window;
});
</script>

<style lang="scss" scoped src="./MediumDeveloperCard.scss"></style>
