<template>
  <div v-if="userInfo" class="profile-view">
    <div class="profile-view__info">
      <div class="profile-view__photo">
        <base-image
          :contain="!userInfo.logo"
          :cover="!!userInfo.logo"
          :height="userInfo.logo ? '80' : '50'"
          :src="userInfo.logo || require('@/assets/img/profile/avatar.png')"
          :width="userInfo.logo ? '80' : '50'"
          name="ava"
        />
      </div>
      <div class="medium">{{ userInfo.name || 'Имя пользователя' }}</div>
      <div>
        <span class="medium">Город: </span><span>{{ userInfo.region }}</span>
      </div>
      <div v-if="userType !== 1">
        <span class="medium">Компания: </span
        ><span>{{ userInfo.profile.titleCompany || '-' }}</span>
      </div>
      <div v-if="userType !== 1 && userInfo.profile.logoCompany">
        <div class="medium">Логотип:</div>
        <base-image
          :src="userInfo.profile.logoCompany"
          class="profile__content-avatar"
          contain
          height="80"
          name="ava"
          width="150"
        />
      </div>
    </div>
    <div>
      <div class="bold">Объявления пользователя:</div>
      <div class="mt--20">
        <div v-if="isLoadingProfileCards" class="profile-view__offerCards">
          <div v-for="i in 6" :key="i">
            <LoadingOfferCard />
          </div>
        </div>
        <div v-else class="profile-view__offerCards">
          <div v-for="card in profileCards" :key="card.id">
            <OfferSmallCard
              :card="card"
              @middle="goToCard(card.landUrl)"
              @change-active="goToCard(card.landUrl)"
            />
          </div>
        </div>
      </div>
      <div class="mt--10">
        <base-pagination-group
          :customPage="paginationData.page"
          :itemCountOnPage="paginationData.pageSize"
          :max-for-visible="24"
          :min-for-visible="6"
          :objectCount="objectCount"
          :show-btn="false"
          :step-for-visible="6"
          :value="prepareValues"
          :values="profileCards"
          auto-add-mobile
          class="mt--20"
          @change-page-and-page-size="changePageAndPageSize"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, Ref, ref } from 'vue';
import { ResultCardType } from '@/types';
import { LoadingOfferCard, OfferSmallCard } from '@/components/Cards';
import { UserType, useUserStore } from '@/store/modules/user';
import { goToCard } from '@/utils/Cards/OfferCard';

type PaginationDataType = {
  page: number;
  pageSize: number;
};

const profileCards: Ref<ResultCardType[]> = ref([]);
const prepareValues = computed((): ResultCardType[] => {
  return profileCards.value;
});
const isLoadingProfileCards = ref(false);
const userInfo = computed((): UserType | undefined => {
  return useUserStore().user;
});
const userType = computed((): number | undefined => {
  return useUserStore().user?.type;
});
const paginationData: Ref<PaginationDataType> = ref({
  page: 1,
  pageSize: 6,
});
const objectCount: Ref<number | undefined> = ref(undefined);

const getMyHouses = async () => {
  try {
    isLoadingProfileCards.value = true;
    const res = await useUserStore().getMyHouses(paginationData.value);

    if (res) {
      profileCards.value = res.found;
      objectCount.value = res.count;
    }
  } finally {
    isLoadingProfileCards.value = false;
  }
};

const changePageAndPageSize = async (data: { page: number; pageSize: number }) => {
  if (
    data.pageSize !== paginationData.value.pageSize ||
    data.page !== paginationData.value.page
  ) {
    paginationData.value = {
      page: data.page,
      pageSize: data.pageSize,
    };
  }
  await getMyHouses();
};

onMounted(async () => {
  await getMyHouses();
});
</script>

<style lang="scss" scoped src="./ProfileView.scss"></style>
