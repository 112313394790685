import request from '@/axios';
import {
  FilterBtnsApiType,
  HouseCostHistoryType,
  HouseSimilarDataType,
  OfferCardCoordinatesType,
  SearchDataByUrlType,
  SearchDataType,
  SearchHouseCoordinatesDataType,
  SearchHouseDataType,
} from '@/api/house/types';
import { useAppStore } from '@/store/modules/app';

export const searchHouseApi = (
  data: SearchHouseDataType,
): Promise<IResponse<SearchDataType>> => {
  return request.post({
    url: process.env.VUE_APP_API_HOUSE,
    data,
  });
};
export const houseSimilarApi = (
  data: HouseSimilarDataType,
): Promise<IResponse<SearchDataType>> => {
  return request.post({
    url: process.env.VUE_APP_API_HOUSE_SIMILAR,
    data,
  });
};
export const houseTopApi = (): Promise<IResponse<FilterBtnsApiType[]>> => {
  return request.get({
    url: process.env.VUE_APP_API_HOUSE_TOP,
    params: {
      region: useAppStore().regions_for_select.defaultForUser,
    },
  });
};

export const houseMyApi = (paginationData?: {
  page: number;
  pageSize: number;
}): Promise<IResponse<SearchDataType>> => {
  return request.get({
    url: process.env.VUE_APP_API_HOUSE_MY,
    params: {
      ...paginationData,
    },
  });
};

export const searchHouseByUrlApi = (
  cardUrl: string,
): Promise<IResponse<SearchDataByUrlType>> => {
  return request.post({
    url: process.env.VUE_APP_API_OFFER_CARD_PAGE,
    params: {
      url: cardUrl,
    },
  });
};
export const getHouseCostHistoryApi = (
  cardId: number,
): Promise<IResponse<HouseCostHistoryType>> => {
  return request.post({
    url: process.env.VUE_APP_API_HOUSE_COST_HISTORY,
    params: {
      id: cardId,
    },
  });
};

export const searchHouseCoordinatesApi = (
  data: SearchHouseCoordinatesDataType,
): Promise<IResponse<OfferCardCoordinatesType[]>> => {
  return request.post({
    url: process.env.VUE_APP_API_HOUSE_COORDINATES,
    data,
  });
};
