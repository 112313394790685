<template>
  <base-modal :show-modal="showModal" title="Отзыв / Предложение" @close="closeModal">
    <template #content>
      <div class="review-modal items-in-column">
        <div v-if="showSuccessMessage" class="l-font-size">Отзыв успешно отправлен!</div>

        <base-form v-else class="review-modal__full-form review-modal__form">
          <base-input
            v-model="formData.name"
            :error="errors.name"
            :invalid="!!errors.name"
            height="36"
            label="Имя"
            @blur="validationErrorsHandlerByKey('name')"
            @focus="delete errors.name"
            @input="v$.name?.$touch"
          />
          <base-input
            v-model="formData.phone"
            v-maska
            :error="errors.phone"
            :invalid="!!errors.phone"
            data-maska="(###)###-##-##"
            height="36"
            label="Телефон"
            placeholder="(999)000-99-99"
            prefix="+7 "
            @blur="validationErrorsHandlerByKey('phone')"
            @focus="delete errors.phone"
            @input="v$.phone?.$touch"
          />
          <base-input
            v-model="formData.email"
            :error="errors.email"
            :invalid="!!errors.email"
            height="36"
            label="E-mail"
            @blur="validationErrorsHandlerByKey('email')"
            @focus="delete errors.email"
            @input="v$.email?.$touch"
          />
          <base-textarea
            v-model="formData.comment"
            :error="errors.comment"
            :invalid="!!errors.comment"
            height="85"
            label="Комментарий"
            textarea
            @blur="validationErrorsHandlerByKey('comment')"
            @focus="delete errors.comment"
            @input="v$.comment?.$touch"
          />
        </base-form>
        <div class="center">
          <base-button
            v-if="showSuccessMessage"
            height="40"
            text="Закрыть"
            width="150"
            @click="closeModal"
          />
          <base-button
            v-else
            :loading="loading"
            height="40"
            text="Отправить"
            width="150"
            @click="formSubmit"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { ref, Ref } from 'vue';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { ErrorsType } from '@/types';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/utils/Common/Common';
import { vMaska } from 'maska';
import { sendReview } from '@/api/common';

const props = defineProps<{
  showModal?: boolean;
}>();
const emit = defineEmits(['close']);

type FormDataType = {
  comment: string | undefined;
  name?: string | undefined;
  phone?: string | undefined;
  email?: string | undefined;
};

const formData: Ref<FormDataType> = ref({
  phone: undefined,
  email: undefined,
  comment: undefined,
  name: undefined,
});

const clearFormData = () => {
  v$.value.$reset();
  for (const [key] of Object.entries(formData)) {
    formData.value[key as keyof FormDataType] = undefined;
  }
};
const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  comment: { required },
  email: { required, email },
  phone: { required },
  name: { required },
};

const v$ = useVuelidate<FormDataType>(rules, formData);
const errors: Ref<ErrorsType> = ref({});
const showSuccessMessage: Ref<boolean> = ref(false);
const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};

const loading = ref(false);
const formSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value?.$touch();
    validationErrorsHandler();

    return;
  }

  loading.value = true;
  const preparedFormData = {
    phone: formData.value.phone?.replace(/[^\d]/g, ''),
    email: formData.value.email,
    comment: formData.value.comment,
    name: formData.value.name,
  };

  try {
    const res = await sendReview(preparedFormData);

    if (res) {
      clearFormData();
      showSuccessMessage.value = true;
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error);
  } finally {
    loading.value = false;
  }
};

const closeModal = () => {
  clearFormData();
  emit('close');
};
</script>

<style lang="scss" scoped src="./ReviewModal.scss"></style>
