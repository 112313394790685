<template>
  <div v-if="loading" class="mt--20 container content center heightContent">
    <base-spinner :size="50" color="#009D3E" />
  </div>
  <div v-else-if="offerCardData" class="offer-card">
    <div class="container content offer-card__container">
      <div class="only-mobile">
        <button
          class="items-in-line flex-start mt--25 gap--5 pl--20"
          @click="$router.go(-1)"
        >
          <base-icon color="#009D3E" name="arrow_back" />
          <span class="underline main-color">Назад</span>
        </button>
      </div>

      <h1 class="content__title offer-card__container-title">
        {{ title }}
        <router-link
          :to="{ name: 'developer', params: { url: offerCardData.aboutDeveloper?.url } }"
          class="gradient-color"
          >СК
        </router-link>
        |
        <router-link
          :to="{
            name: 'residentialComplex',
            params: { url: offerCardData.aboutResidentialComplex?.url },
          }"
          class="gradient-color"
          >ЖК
        </router-link>
      </h1>

      <div v-if="offerCardData">
        <OfferCard
          :commission="commission"
          :offerCard="offerCardData"
          :partnerFiles="partnerFiles"
          @changeIsFavorite="changeIsFavorite"
          @showPartnersFiles="showPartnersFiles"
        />
      </div>
      <PartnersFilesModal
        v-if="offerCardData?.affiliateProgramId"
        :partner-program-id="offerCardData?.affiliateProgramId"
        :partnerFiles="partnerFiles"
        :show-modal="partnersFilesModalData.cardId"
        @close="closePartnersFilesModal"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { computed, onMounted, onUnmounted, Ref, ref, watch } from 'vue';
import { FileType, ResultCardType } from '@/types';
import { OfferCard } from '@/components/Cards';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { useUserStore } from '@/store/modules/user';
import { usePartnerProgramStore } from '@/store/modules/partnerProgram';
import { PartnersFilesModal } from '@/components';
import { useAppStore } from '@/store/modules/app';
import { errorHandle } from '@/utils/Common/Common';

type PartnersFilesModalDataType = {
  cardId: number | undefined;
  show: boolean;
};

const offerCardData: Ref<ResultCardType | undefined> = ref(undefined);
const loading = ref(false);

const commission: Ref<number | undefined> = ref(undefined);
const partnerFiles: Ref<FileType[]> = ref([]);
const partnersFilesModalData: Ref<PartnersFilesModalDataType> = ref({
  cardId: undefined,
  show: false,
});

const title = computed((): string => {
  if (offerCardData.value) {
    if (offerCardData.value.id === offerCardData.value.landId) {
      return `${offerCardData.value.name || '-'} | ${offerCardData.value.landArea || '-'} сот. | ${offerCardData.value.address || '-'} |`;
    }

    return `${offerCardData.value.name || '-'} | ${offerCardData.value.houseArea || '-'} м² | ${offerCardData.value.address || '-'} |`;
  }

  return 'Название';
});

const changeIsFavorite = async (cardId: number) => {
  await useUserStore().changeIsFavorite(cardId);
};

const showPartnersFiles = (cardId: number) => {
  partnersFilesModalData.value.cardId = cardId;
  partnersFilesModalData.value.show = true;
};

const closePartnersFilesModal = () => {
  partnersFilesModalData.value.cardId = undefined;
  partnersFilesModalData.value.show = false;
};
const route = useRoute();

const cardUrl = computed((): string => {
  if (Array.isArray(route.params.url)) {
    return route.params.url[0];
  }

  return route.params.url;
});

const getCardInfo = async () => {
  if (cardUrl.value) {
    loading.value = true;
    try {
      const res = await useOfferCardsStore().searchOfferCardByUrl(cardUrl.value);

      if (res) {
        offerCardData.value = res;

        if (offerCardData.value?.affiliateProgramId) {
          commission.value = await usePartnerProgramStore().getCommissionById(
            offerCardData.value?.affiliateProgramId,
          );

          const files = await usePartnerProgramStore().getPartnerDocsById(
            offerCardData.value?.affiliateProgramId,
          );

          if (files) {
            partnerFiles.value = files.map(el => {
              return {
                ...el,
                path: `${useAppStore().baseUrl}${process.env.VUE_APP_API_AFFILIATE_PROGRAM_DOWNLOAD_ONE_FILE}?id=${el.id}`,
              };
            });
          }
        }
      }
    } catch (error) {
      errorHandle(error);
    } finally {
      loading.value = false;
    }
  } else {
    useAppStore().togglePageNotFound(true);
  }
};

onMounted(async () => {
  if (useAppStore().getWindowWidth() < 960) {
    document.documentElement.style.setProperty('--btn-up-bottom', '120px');
  }
  await getCardInfo();
});

watch(
  () => cardUrl.value,
  () => {
    getCardInfo();
  },
);

onUnmounted(() => {
  document.documentElement.style.removeProperty('--btn-up-bottom');
});
</script>

<style lang="scss" scoped src="./OfferCardPageLayout.scss"></style>
