<template>
  <div class="input-group">
    <div
      v-for="(input, i) in inputs"
      :key="input.id"
      :style="{ gridArea: `input${i + 1}` }"
      class="input-group__input"
    >
      <div v-if="input.id === 6" class="input-group__input-address">
        <base-address-input
          v-model="input.value"
          :height="height"
          :label="input.label"
          :styles="{ border: 'none' }"
          :type="input.type"
          filled
          hideArrows
          @changeLocation="changeAddressValue($event)"
        />
      </div>
      <base-select-input
        v-else-if="input.type === 'select'"
        :height="height"
        :label="input.label"
        :modelValue="input.value"
        :options="input.options"
        :styles="{ border: 'none' }"
        clearable
        filled
        @update:modelValue="
          useOfferCardsStore().updateMainInputsParams({ id: input.id, value: $event });
          useOfferCardsStore().showResultBlock();
        "
      />
      <base-input
        v-else
        v-model="input.value"
        :height="height"
        :label="input.label"
        :max="input.max"
        :min="input.min"
        :styles="{ border: 'none' }"
        :type="input.type"
        filled
        hideArrows
        @blur="useOfferCardsStore().showResultBlock()"
      />
    </div>

    <div
      v-for="i in inputs?.length"
      :style="{ gridArea: `verticalDivider${i}` }"
      class="input-group-verticalDivider center"
    >
      <base-divider inset="80%" vertical />
    </div>

    <base-divider
      v-for="i in 3"
      :style="{ gridArea: `horizontalDivider${i}` }"
      class="input-group-horizontalDivider"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { useAppStore } from '@/store/modules/app';
import { useRouter } from 'vue-router';

const props = withDefaults(
  defineProps<{
    height?: string;
  }>(),
  {
    height: '36',
  },
);

const inputs = computed(() => {
  return useOfferCardsStore().mainInputsParams;
});

const router = useRouter();
const changeAddressValue = (point: [string, string]) => {
  useOfferCardsStore().updateCenterPointMap(point[1], point[0]);
  if (useAppStore().getWindowWidth() <= 600) {
    router.push({ name: 'fullMap' });
  }

  useOfferCardsStore().showResultBlock(true);
  if (!useOfferCardsStore().offerCards.length) {
    useOfferCardsStore().showResultBlock();
  }
};
</script>

<style lang="scss" scoped src="./InputGroup.scss"></style>
