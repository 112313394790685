<template>
  <div>
    <base-card class="user-support" full-width-mobile>
      <div v-if="isEdit" class="m-font-size mb--12">
        Редактировать партнерскую программу
      </div>
      <div v-else class="m-font-size mb--12">Создать партнерскую программу</div>
      <base-form :key="key" class="user-support__form">
        <base-select-input
          v-model="formData.residentialComplexId"
          :error="errors.residentialComplexId"
          :options="residentialComplexList"
          height="34"
          label="Выберите ЖК"
          @blur="validationErrorsHandlerByKey('residentialComplexId')"
          @focus="delete errors.residentialComplexId"
          @update:modelValue="updateResidentialComplex"
        />
        <base-select-input
          v-model="formData.queueCompanyId"
          :disabled="queueCategoryList.length <= 0"
          :error="errors.queueCompanyId"
          :options="queueCategoryList"
          height="34"
          label="Выберите очередь"
          @blur="validationErrorsHandlerByKey('queueCompanyId')"
          @focus="delete errors.queueCompanyId"
          @update:modelValue="updateQueueCategory"
        />
        <base-select-input
          v-model="formData.input_adverts_id"
          :disabled="offerCardList.length <= 0"
          :error="errors.input_adverts_id"
          :options="offerCardList"
          height="34"
          is-multi
          label="Выберите объявление"
          show-check-all
          @deleteMultiSelectItem="deleteAd"
          @focus="delete errors.input_adverts_id"
          @select-multi-item="chooseAd"
        />
        <div class="user-support__form-ads">
          <div class="third-color mb--10">Выбранные объявления:</div>
          <div
            v-if="formData.adverts_id?.length"
            class="items-in-line flex-wrap flex-start"
          >
            <div
              v-for="ad in formData.adverts_id"
              :key="ad.id"
              class="user-support__form-ads-item center"
            >
              <span>{{ ad.name }}</span>
              <base-icon
                clickable
                color="#94857d"
                font-size="20"
                name="close"
                @click="deleteAd(ad)"
              />
            </div>
          </div>
          <div v-else :class="{ 'error-color': errors.adverts_id }">
            Нет выбранных объявлений
          </div>
        </div>
        <base-input
          v-model="formData.percent"
          :error="errors.percent"
          :invalid="!!errors.percent"
          height="34"
          label="Укажите % комиссии"
          @blur="validationErrorsHandlerByKey('percent')"
          @focus="delete errors.percent"
          @input="v$.percent.$touch"
        />
        <base-date-picker
          v-model="formData.start_action"
          :error="errors.start_action"
          height="34"
          label="Период действия от"
          @blur="validationErrorsHandlerByKey('start_action')"
          @focus="delete errors.start_action"
          @input="v$.start_action.$touch"
        />
        <base-date-picker
          v-model="formData.end_action"
          :error="errors.end_action"
          height="34"
          label="Период действия до"
          @blur="validationErrorsHandlerByKey('end_action')"
          @focus="delete errors.end_action"
          @input="v$.end_action.$touch"
        />
        <div class="user-support__form-file-input">
          <base-file-input
            v-model="formData.images"
            :error="errors.images"
            :files="formData.files"
            :with-delete-file="false"
            label="Прикрепите файл"
            multiple
            @blur="validationErrorsHandlerByKey('images')"
            @focus="delete errors.images"
          />
          <span v-if="isEdit" class="error-color xs-font-size"
            >Внимание! При добавлении новых файлов, старые удалятся</span
          >
        </div>
      </base-form>
      <div class="center mt--20 gap--20">
        <base-button
          v-if="isEdit"
          height="36"
          style-type="coloredBorder"
          text="Отмена"
          width="165"
          @click="cancel"
        />
        <base-button
          :loading="isFormSaveLoading"
          height="36"
          text="Сохранить"
          type="submit"
          width="165"
          @click="handleSubmit"
        />
      </div>
    </base-card>
    <base-info-modal
      :key="key"
      :show-info-modal="showSavedDataModal"
      @close="showSavedDataModal = false"
    >
      <div>Данные успешно сохранены</div>
    </base-info-modal>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, Ref, ref, watch } from 'vue';
import {
  ErrorsType,
  FormPartnerProgramDataType,
  OptionsType,
  PartnerProgramType,
} from '@/types';
import { usePartnerProgramStore } from '@/store/modules/partnerProgram';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/utils/Common/Common';

const props = withDefaults(
  defineProps<{
    isEdit?: boolean;
    editProgram?: PartnerProgramType | undefined;
  }>(),
  {
    isEdit: false,
    editProgram: undefined,
  },
);
const emit = defineEmits(['cancel']);
const formData: Ref<FormPartnerProgramDataType> = ref({
  input_adverts_id: [],
  adverts_id: [],
  residentialComplexId: undefined,
  queueCompanyId: undefined,
  percent: '',
  start_action: '',
  end_action: '',
  images: undefined,
  files: undefined,
});

const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  adverts_id: { required },
  percent: { required },
  start_action: { required },
  end_action: { required },
};
const v$ = useVuelidate<FormPartnerProgramDataType>(rules, formData);
const errors: Ref<ErrorsType> = ref({});
const key: Ref<number> = ref(0);
const showSavedDataModal = ref(false);

const deleteAd = (option: { id: number; name: string }) => {
  formData.value.adverts_id = formData.value.adverts_id?.filter(
    el => el.id !== option.id,
  );
};
const chooseAd = (option: { id: number; name: string }) => {
  if (formData.value.adverts_id === undefined) {
    formData.value.adverts_id = [];
  }
  formData.value.adverts_id?.push(option);
};
const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};

const clearFormData = () => {
  v$.value.$reset();
  for (const [key] of Object.entries(formData.value)) {
    formData.value[key as keyof FormPartnerProgramDataType] = undefined;
  }
  key.value++;
};
const clearErrors = () => {
  errors.value = {};
};
const prepareDataForSend = (): FormData => {
  let sendData = new FormData();

  formData.value.adverts_id?.forEach(el => {
    sendData.append(`parameters[adverts_id][]`, String(el.id));
  });

  if (props.isEdit && props.editProgram) {
    sendData.append(`parameters[id]`, String(props.editProgram['id'].value));
  }

  sendData.append(`parameters[percent]`, String(formData.value.percent));
  sendData.append(
    `parameters[start_action]`,
    String(formData.value.start_action?.split(/\s/, 2)[0]),
  );
  sendData.append(
    `parameters[end_action]`,
    String(formData.value.end_action?.split(/\s/, 2)[0]),
  );

  if (formData.value.images) {
    Object.values(formData.value.images).forEach(val => {
      if (val !== null) {
        sendData.append('parameters[images][]', val);
      }
    });
  }

  return sendData;
};

const cancel = () => {
  emit('cancel');
};
const handleSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
    validationErrorsHandler();

    return;
  }
  try {
    isFormSaveLoading.value = true;
    let res;

    if (props.isEdit && props.editProgram) {
      res = await usePartnerProgramStore().updatePartnerProgram(
        prepareDataForSend(),
        +props.editProgram['id'].value,
      );
    } else {
      res = await usePartnerProgramStore().createPartnerProgram(prepareDataForSend());
    }

    if (res) {
      if (props.isEdit) {
        cancel();
      }
      showSavedDataModal.value = true;
      clearFormData();
      clearErrors();
      queueCategoryList.value = [];
      offerCardList.value = [];
      key.value++;
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error, 'parameters');
  } finally {
    isFormSaveLoading.value = false;
  }
};

const residentialComplexList: Ref<OptionsType[]> = ref([]);
const categoryMultiSelect: Ref<{ [key: number]: string }> = ref([]);
const queueCategoryList: Ref<OptionsType[]> = ref([]);
const offerCardList: Ref<OptionsType[]> = ref([]);
const isLoading = ref(false);
const isFormSaveLoading = ref(false);

const updateResidentialComplex = async (id: number) => {
  formData.value.input_adverts_id = undefined;
  formData.value.queueCompanyId = undefined;
  queueCategoryList.value = [];
  offerCardList.value = [];

  const res = await usePartnerProgramStore().getQueueList(id);

  if (res) {
    queueCategoryList.value = res.queue_category_name.map(el => {
      return {
        id: el.id,
        name: el.value,
      };
    });
  }
};

const updateQueueCategory = async (id: number) => {
  const res = await usePartnerProgramStore().getHousesList(id);

  if (res) {
    offerCardList.value = res.houses.map(el => {
      return {
        id: el.id,
        name: el.value,
      };
    });
  }
};

onMounted(async () => {
  try {
    isLoading.value = true;
    const res = await usePartnerProgramStore().getResidentialComplexes();

    if (res) {
      residentialComplexList.value = Object.entries(res.housingComplexes).map(el => {
        return {
          id: +el[0],
          name: el[1],
        };
      });
      categoryMultiSelect.value = res.categoryMultiSelect;
    }
  } finally {
    isLoading.value = false;
  }
});

watch(
  () => props.editProgram,
  () => {
    key.value++;
    if (props.isEdit && props.editProgram) {
      formData.value.percent = props.editProgram['percent'].value as string | undefined;
      formData.value.start_action = `${props.editProgram['start_action'].value} 01:00:00`;
      formData.value.end_action = `${props.editProgram['end_action'].value} 01:00:00`;

      const files = props.editProgram['files'].value as { id: number; name: string }[];

      if (Array.isArray(files)) {
        formData.value.files = files.map(el => {
          return {
            ...el,
            path: `${process.env.VUE_APP_API_AFFILIATE_PROGRAM_DOWNLOAD_ONE_FILE}?id=${el.id}`,
          };
        });
      }

      formData.value.adverts_id = (
        props.editProgram['ads'].value as { id: number; url: string }[]
      ).map(el => {
        return {
          id: el.id,
          name: categoryMultiSelect.value[el.id],
        };
      });
    } else {
      clearFormData();
    }
  },
  {
    deep: true,
  },
);
</script>

<style lang="scss" scoped src="./CreateForm.scss"></style>
