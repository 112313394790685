import { FileParamType, FileType } from '@/types/UpdateDeveloperTypes';

export type ValueType =
  | {
      type: 'main';
      value: string | boolean | null;
    }
  | {
      type: 24 | 25 | 28;
      value: {
        min: string | null;
        max: string | null;
      };
    }
  | {
      type: 3;
      value: boolean | null;
    }
  | {
      type: 4 | 5 | 6 | 19;
      value: number | null;
    }
  | {
      type: 2 | 8 | 12 | 13 | 20;
      value: string | null | undefined;
    }
  | {
      type: 15 | 10 | 11 | 26;
      value: FileParamType;
    }
  | {
      type: 14;
      value: number[] | null;
    }
  | {
      type: 22;
      value:
        | {
            id: number;
            title: string;
          }[]
        | null;
    };
export type ValueTypeFromBack =
  | {
      type: 24 | 25 | 28;
      value: null | {
        min: string;
        max: string;
      };
    }
  | {
      type: 3;
      value: boolean | null;
    }
  | {
      type: 4 | 14;
      value: number[] | null;
    }
  | {
      type: 15 | 10;
      value: FileType | null;
    }
  | {
      type: 11 | 26;
      value: FileType[] | null;
    }
  | {
      type: 2 | 5 | 6;
      value: number | null;
    }
  | {
      type: 12 | 20 | 19 | 1 | 7 | 8 | 30 | 13;
      value: string | null;
    }
  | {
      type: 22;
      value:
        | {
            id: number;
            title: string;
          }[]
        | null;
    };
export const prepareValueByType = (param: ValueTypeFromBack): ValueType => {
  let value;

  value = param.value;

  if (!param.value) {
    if (param.type === 24 || param.type === 25 || param.type === 28) {
      value = {
        min: null,
        max: null,
      };
    }
  } else {
    if (param.type === 30) {
      value = param.value.length ? +param.value : null;
    }
    if (param.type === 4) {
      value = param.value.length ? param.value[0] : null;
    }
    if (param.type === 20 || param.type === 12) {
      value = `${param.value} 01:00:00`;
    }
    if (param.type === 19) {
      value = new Date(param.value).getFullYear();
    }
    if (param.type === 24 || param.type === 25 || param.type === 28) {
      value = {
        min: param.value.min,
        max: param.value.max,
      };
    }
  }

  if (param.type === 10 || param.type === 11 || param.type === 15 || param.type === 26) {
    value = {
      oldValue: param.value,
      newValue: null,
    };
  }

  return {
    type: param.type,
    value: value,
  } as ValueType;
};
