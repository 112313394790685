<template>
  <div class="page-card">
    <div>
      <div
        v-if="slidersGroups.length"
        id="photoAndConstruction"
        class="section page-card__content"
      >
        <base-image-gallery
          :img-name="residentialComplexData.title"
          :img-title="residentialComplexData.title"
          :slides-group="slidersGroups"
          :space-between="5"
        >
          <template #item="itemProps">
            <button
              class="page-card__item-img-box"
              @click="
                showImageModal = true;
                imagesModal = itemProps.data.slides;
              "
            >
              <base-image
                :name="residentialComplexData.title"
                :src="itemProps.data.item"
                :title="residentialComplexData.title"
                border-radius
                class="page-card__item-img"
                contain
                height="100%"
                width="100%"
              />
            </button>
          </template>
        </base-image-gallery>
      </div>
      <div id="passport" class="section page-card__content">
        <PassportTable
          :tableData="[
            {
              title: 'ЖК',
              items: residentialComplexData.passportLCItems,
            },
          ]"
          class="mt--27"
        />
        <div v-if="residentialComplexData.description" class="mt--27">
          <div class="m-font-size mb--10">Описание</div>
          <div>
            {{ description || '-' }}
            <button
              v-if="showFurther"
              class="main-color underline"
              @click="showAllDescription = !showAllDescription"
            >
              {{ showAllDescription ? 'Скрыть' : 'Далее' }}
            </button>
          </div>
        </div>
      </div>
      <div id="map" class="section page-card__content page-card__map">
        <div class="font-size-title-block mb--10">На карте</div>
        <div v-if="residentialComplexData.address" class="mb--10">
          Адрес: {{ residentialComplexData.address }}
        </div>
        <base-card full-width-mobile without-mobile-shadow>
          <MapBlock
            :center-point-map="residentialComplexData.coordinates[0]?.coordinates"
            :result-cards-coordinates="residentialComplexData.coordinates"
            class="mt--20"
            height="370"
          />
        </base-card>
      </div>
      <div
        v-if="residentialComplexData.documents?.length"
        id="documentation"
        class="section page-card__content"
      >
        <div class="font-size-title-block mb--20">Документация</div>
        <div>
          <base-card
            class="page-card__documentation"
            full-width-mobile
            without-mobile-shadow
          >
            <DocumentList :documents="residentialComplexData.documents" />
          </base-card>
        </div>
      </div>
      <div id="aboutDeveloper" class="section page-card__content">
        <div class="font-size-title-block mb--20">О Застройщике</div>
        <div>
          <DeveloperSmallCard :developer="residentialComplexData.developer" is-full />
        </div>
      </div>
      <!--      <div id="news" class="mt&#45;&#45;27 section">-->
      <!--        <div class="font-size-title-block mb&#45;&#45;10">Новости</div>-->
      <!--        <div class="page-card__grid-cards">-->
      <!--          <NewsCard v-for="i in 3" :key="i" />-->
      <!--        </div>-->
      <!--        <div class="center mt&#45;&#45;30">-->
      <!--          <base-button height="36" text="Показать еще" width="165" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div id="ads" class="section page-card__content">
        <div class="font-size-title-block mb--20">Все объявления ЖК</div>
        <div v-if="useAppStore().getWindowWidth() > 600">
          <base-swiper
            :slides="resultCards"
            :space-between="20"
            :width-item="240"
            class="mt--20 mb--20"
          >
            <template #item="itemProps">
              <div class="pb--10 pt--10 center">
                <OfferSmallCard
                  :card="itemProps.data"
                  without-scale
                  @change-active="goToCard(itemProps.data.landUrl)"
                />
              </div>
            </template>
          </base-swiper>
        </div>
        <div v-else>
          <div class="page-card__grid-cards">
            <OfferSmallCard
              v-for="offerCard in resultCards?.slice(0, visibleOffersCount)"
              :card="offerCard"
              without-scale
            />
          </div>
          <div class="center mt--20">
            <base-button
              v-if="resultCards?.length > visibleOffersCount"
              height="36"
              text="Показать еще"
              @click="visibleOffersCount = visibleOffersCount + 4"
            />
          </div>
        </div>
      </div>
      <div v-if="projectsLoading || projects?.length" class="page-card__content">
        <div class="font-size-title-block mb--20">Иные проекты застройщика</div>
        <div v-if="projectsLoading" class="page-card__grid-cards">
          <LoadingResidentialComplexCard v-for="i in 3" :key="i" />
        </div>
        <div v-else class="page-card__grid-cards">
          <ResidentialComplexCard
            v-for="(project, i) in projects"
            :key="i"
            :project="project"
            version="v1"
          />
        </div>
      </div>
    </div>
    <div class="page-card__aside">
      <div>
        <base-card class="page-card__main-info">
          <div class="m-font-size mb--10 medium">
            {{ residentialComplexData.title }}
          </div>
          <div class="mb--5">
            <span class="dark-text-color">Сдача: </span>
            <span>4 кв. 2026</span>
          </div>
          <div class="mb--5">
            <span class="dark-text-color">От: </span>
            <span>12 000 000 ₽</span>
          </div>
          <div>
            <span class="dark-text-color">Класс: </span>
            <span>44</span>
          </div>
        </base-card>
      </div>
      <div class="mt--25">
        <base-card>
          <MenuBlock :menu-items="menuItems" />
        </base-card>
      </div>
      <div class="mt--25">
        <base-card>
          <ActivityButtons
            :docs="residentialComplexData.presentation"
            :phone="residentialComplexData.phone"
          />
        </base-card>
      </div>
    </div>
    <teleport class="page-card__aside-mobile mb--22" to="#mobileDownMenu">
      <div class="page-card__aside-mobile-card">
        <PhoneButton
          :phone="residentialComplexData.phone"
          :phone-modal="useAppStore().getWindowWidth() <= 600"
          height="36"
        />
        <base-menu>
          <template #menuItems="menuItemsProps">
            <div class="page-card__menu">
              <div v-for="(item, i) in menuItems" :key="item.id">
                <a
                  :href="`#${item.id}`"
                  class="page-card__menu-item"
                  @click="menuItemsProps.events.handleClick()"
                >
                  {{ item.title }}
                </a>
                <base-divider v-if="i !== menuItems.length - 1" />
              </div>
            </div>
          </template>
        </base-menu>
      </div>
    </teleport>
    <ImageModal
      v-model="showImageModal"
      :images="imagesModal"
      title="Коттеджный посёлок 'Негород Заневский'"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, inject, onMounted, Ref, ref } from 'vue';
import {
  ActivityButtons,
  ImageModal,
  MenuBlock,
  PassportTable,
} from '../CardsComponents';
import {
  DeveloperSmallCard,
  DocumentList,
  MapBlock,
  PhoneButton,
  ResidentialComplexCard,
} from '@/components';
import { ResidentialComplexType, ResultCardType } from '@/types';
import { SmallCardLSDType } from '@/types/DeveloperTypes';
import LoadingResidentialComplexCard from '@/components/ResidentialComplexCard/LoadingResidentialComplexCard.vue';
import { SmoothScrollOptions } from 'vue3-smooth-scroll';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '@/store/modules/app';
import { OfferSmallCard } from '@/components/Cards';
import { goToCard } from '@/utils/Cards/OfferCard';

const props = withDefaults(
  defineProps<{
    residentialComplexData: ResidentialComplexType;
    projectsLoading: boolean;
    projects: SmallCardLSDType[] | undefined;
    resultCards: ResultCardType[];
  }>(),
  {},
);
const router = useRouter();
const menuItems = [
  {
    id: 'photoAndConstruction',
    title: 'Фото и строительство',
  },
  {
    id: 'passport',
    title: 'Паспорт объекта',
  },
  {
    id: 'map',
    title: 'На карте',
  },
  {
    id: 'documentation',
    title: 'Документация',
  },
  {
    id: 'aboutDeveloper',
    title: 'О Застройщике',
  },
  {
    id: 'ads',
    title: 'Объявления ЖК',
  },
  // {
  //   id: 'news',
  //   title: 'Новости',
  // },
];
const filtersMap = [
  'Инфраструктура',
  'Цена аренды',
  'Цена продажи',
  'Окупаемость',
  'Транспорт',
  'Площадь',
];

const showImageModal = ref(false);
const showAllDescription = ref(false);
const showFurther = ref(false);
const imagesModal: Ref<string[]> = ref([]);
const visibleOffersCount = ref(4);

const description = computed((): string => {
  if (!props.residentialComplexData?.description) {
    return '-';
  }
  let sliced: string;

  sliced = props.residentialComplexData.description?.slice(0, 400);

  if (sliced?.length < props.residentialComplexData.description?.length) {
    showFurther.value = true;
  }

  return showAllDescription.value ? props.residentialComplexData.description : sliced;
});
const slidersGroups = computed(() => {
  const slidersGroups = [];

  if (props.residentialComplexData.logo) {
    slidersGroups.push({
      id: 1,
      title: 'Лого',
      slides: [props.residentialComplexData.logo],
    });
  }

  if (props.residentialComplexData.renders.length) {
    slidersGroups[0].slides = [
      ...slidersGroups[0].slides,
      ...props.residentialComplexData.renders,
    ];
  }

  return slidersGroups;
});

const smoothScroll = inject('smoothScroll') as (args: SmoothScrollOptions) => void;

onMounted(() => {
  const hash = useRoute().hash;

  if (hash) {
    const myEl = document.getElementById(hash.replace('#', ''));

    if (myEl) {
      smoothScroll({
        scrollTo: myEl,
        container: myEl,
        updateHistory: true,
      });
    }
  }
});
</script>

<style lang="scss" scoped src="./ResidentialComplexCard.scss"></style>
<style lang="scss" scoped src="../CommonCardStyles.scss"></style>
