<template>
  <div
    :style="[
      {
        width: width === '100%' ? width : width + 'px',
        height: height === '100%' ? height : height + 'px',
      },
    ]"
  >
    <img
      :alt="name"
      :class="[
        {
          borderRadius: borderRadius,
        },
      ]"
      :src="newDevSrc ?? newSrc ?? src ?? require('@/assets/img/common/not-image.jpg')"
      :style="[
        {
          width: width === '100%' ? width : width + 'px',
          height: height === '100%' ? height : height + 'px',
          objectFit: objectFit,
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          minHeight: minHeight,
        },
      ]"
      :title="title"
      class="custom-img"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, Ref, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    src?: string;
    name?: string;
    title?: string;
    width?: string;
    height?: string;
    maxWidth?: string;
    maxHeight?: string;
    minHeight?: string;
    cover?: boolean;
    fill?: boolean;
    contain?: boolean;
    borderRadius?: boolean;
    version?: string;
  }>(),
  {
    name: '',
    title: '',
    height: '100%',
    width: '100%',
  },
);

const objectFit = computed((): string => {
  if (props.cover) {
    return 'cover';
  }
  if (props.fill) {
    return 'fill';
  }
  if (props.contain) {
    return 'contain';
  }

  return 'none';
});

const newDevSrc: Ref<string | undefined> = ref(undefined);
const newSrc: Ref<string | undefined> = ref(undefined);

const updateSrc = () => {
  if (props.src && process.env.VUE_APP_IS_DEV === 'true') {
    let img = new Image();

    img.src = props.src;
    newDevSrc.value = img.src;
    img.onerror = function () {
      if (props.src) {
        newDevSrc.value = img.src.replace(
          'https://xn--d1aefpjv.xn--p1ai/',
          'http://localhost:13010/',
        );
      }
    };
    if (props.version && newDevSrc.value) {
      let imgWithVersion = new Image();
      const srcWithVersion = newDevSrc.value.replace(
        'uploads',
        `preview/${props.version}/uploads`,
      );

      imgWithVersion.src = srcWithVersion;

      imgWithVersion.onload = function () {
        newDevSrc.value = srcWithVersion;
      };
    }
  } else if (props.version && props.src) {
    newSrc.value = props.src.replace('uploads', `preview/${props.version}/uploads`);
  }
};

onMounted(() => {
  updateSrc();
});

watch(
  () => props.src,
  () => {
    updateSrc();
  },
);
</script>

<style lang="scss" scoped>
@import '@/assets/styles/main';

.borderRadius {
  border-radius: $borderRadius;
}
</style>
