<template>
  <div class="mobile-down-menu none-PC">
    <div id="mobileDownMenu"></div>
    <div class="mobile-down-menu__items">
      <div v-for="item in mobileMenuItems" :key="item.id">
        <button class="items-in-column align-center gap--0" @click="item.clickHandler">
          <base-icon
            :color="activeMenuItemId === item.id ? '#009D3E' : '#94857d'"
            :name="item.icon"
            font-size="24"
          />
          <span
            :class="activeMenuItemId === item.id ? 'main-color' : 'third-color'"
            class="xxs-font-size"
            >{{ item.name }}</span
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { checkUserLoginHandler } from '@/utils/Common/Common';

const router = useRouter();
const route = useRoute();

const activeMenuItemId = computed((): string | undefined => {
  if (route.name === 'main') {
    return 'home';
  }
  if (route.name === 'profile') {
    if (route.params.id === 'favorite') {
      return 'favorite';
    }
    if (route.params.id === 'chat') {
      return 'chat';
    }
    if (route.params.id === 'searchHistory') {
      return 'search';
    }

    return 'profile';
  }

  return undefined;
});
const mobileMenuItems = computed(() => {
  return [
    {
      id: 'home',
      icon: 'home',
      name: 'Главная',
      clickHandler: () => {
        router.push({ name: 'main' });
      },
    },
    {
      id: 'search',
      icon: 'search',
      clickHandler: () =>
        checkUserLoginHandler().then(() => {
          router.push({ name: 'profile', params: { id: 'searchHistory' } });
        }),
      name: 'Поиск',
    },
    {
      id: 'favorite',
      icon: 'favorite',
      clickHandler: () =>
        checkUserLoginHandler().then(() => {
          router.push({ name: 'profile', params: { id: 'favorite' } });
        }),
      name: 'Избранное',
    },
    {
      id: 'profile',
      icon: 'person',
      clickHandler: () =>
        checkUserLoginHandler().then(() => {
          router.push({ name: 'profile' });
        }),
      name: 'Профиль',
    },
    {
      id: 'chat',
      icon: 'chat',
      clickHandler: () =>
        checkUserLoginHandler().then(() => {
          router.push({ name: 'profile', params: { id: 'chat' } });
        }),
      name: 'Чаты',
    },
  ];
});
</script>

<style lang="scss" scoped src="./MobileDownMenu.scss"></style>
