<template>
  <base-button
    v-if="!isIcon"
    :class="{ showPhone, 'none-Mobile': iconMobile, phoneButton: !phoneModal }"
    :disabled="phone === '-' || !phone"
    :height="height"
    :text="btnPhoneText"
    all-block-width
    @click="phoneModal ? openPhoneModal() : changeShowPhone()"
  />
  <base-button
    v-if="iconMobile || isIcon"
    :class="{ 'only-mobile': iconMobile }"
    :disabled="phone === '-' || !phone"
    :style-type="btnType"
    icon
    without-active
    @click="openPhoneModal"
  >
    <template #icon>
      <base-icon color="#009D3E" font-size="20" name="call" />
    </template>
  </base-button>
  <base-modal :show-modal="showPhoneModal" hide-title @close="showPhoneModal = false">
    <template #content>
      <div>
        {{ preparedPhone }}
      </div>
    </template>
    <template #btn>
      <base-button height="40" text="Ок" width="100" @click="showPhoneModal = false" />
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useAppStore } from '@/store/modules/app';

const props = withDefaults(
  defineProps<{
    phone: string | undefined;
    height?: string;
    iconMobile?: boolean;
    isIcon?: boolean;
    phoneModal?: boolean;
    btnType?: 'greenBorder' | 'border';
  }>(),
  {
    iconMobile: false,
    isIcon: false,
    btnType: 'greenBorder',
  },
);

const showPhone = ref(false);
const showPhoneModal = ref(false);

const changeShowPhone = () => {
  showPhone.value = !showPhone.value;
  if (showPhone.value) {
    useAppStore().sendStatistic(15);
  }
};
const openPhoneModal = () => {
  showPhoneModal.value = true;
  useAppStore().sendStatistic(15);
};

const preparedPhone = computed((): string => {
  if (props.phone) {
    return props.phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4');
  }

  return '-';
});

const btnPhoneText = computed((): string => {
  if (showPhone.value && props.phone) {
    return preparedPhone.value;
  } else {
    return 'Телефон';
  }
});
</script>

<style lang="scss" scoped src="./PhoneButton.scss"></style>
