<template>
  <div id="activeCard" class="active-card">
    <base-card
      :class="{ enlargedBlock: showEnlargedBlock }"
      class="active-card__infoBlock"
    >
      <base-icon
        class="active-card__infoBlock-close-icon"
        clickable
        color="#94857d"
        font-size="18"
        name="close"
        @click="$emit('changeActive', card.id)"
      />
      <div class="active-card__infoBlock-imgBlock relative">
        <button
          v-if="card.affiliateProgramId && card.isEstateAgent"
          class="active-card__infoBlock-partners"
        >
          ₽
        </button>
        <router-link :to="{ name: 'offerCard', params: { url: card.landUrl } }">
          <span class="relative">
            <button v-if="card.affiliateProgramId" class="partners-icon">₽</button>
            <base-brazzers-carousel
              :alt="card.address"
              :photos="
                card.id === card.landId ? card.landImgs : [...card.img, ...card.landImgs]
              "
              :title="card.address"
              borderRadius
              class="mb--15"
              height="129"
              version="x8"
              width="240"
            />
            <router-link
              v-if="!card.isPhysics"
              :to="{ name: 'checkResult', query: { id: card.landId } }"
              class="verifiedBtn verifiedBtn-text center"
              >Проверено
            </router-link>
          </span>

          <span class="items-in-line gap--10 mb--25">
            <base-image
              :name="String(card.id)"
              :src="card.img ? card.img[1] : card.landImgs[1]"
              borderRadius
              cover
              height="80"
              version="x9"
              width="115"
            />
            <base-image
              :name="String(card.id)"
              :src="card.img ? card.img[2] : card.landImgs[2]"
              borderRadius
              cover
              height="80"
              version="x9"
              width="115"
            />
          </span>
        </router-link>
        <router-link
          v-if="card.rating !== undefined"
          :to="{ name: 'classification', query: { id: card.landId } }"
        >
          <base-progress-bar :max-rate="card.classifier?.maxRate" :rating="card.rating" />
        </router-link>
      </div>
      <router-link
        :to="{ name: 'offerCard', params: { url: card.landUrl } }"
        class="active-card__infoBlock-title"
      >
        {{ card.abbreviation }}
      </router-link>
      <div v-if="card.landId !== card.id" class="active-card__infoBlock-area">
        <div>
          <span class="dark-text-color">Дом: </span>
          <span>{{ `${card.houseArea || '-'} м²` }}</span>
        </div>
        <div>
          <span class="dark-text-color">Этаж: </span>
          <span>{{ card.floor }}</span>
        </div>
      </div>
      <div class="active-card__infoBlock-type">
        <div>
          <span class="dark-text-color">Участок: </span>
          <span>{{ `${card.landArea || '-'} сот.` }}</span>
        </div>
        <div class="active-card__infoBlock-type-item">
          <span class="dark-text-color">Тип: </span>
          <span>{{ card.type || '-' }}</span>
        </div>
      </div>
      <div class="active-card__infoBlock-address">
        {{ card.address }}
      </div>
      <div v-if="showEnlargedBlock" class="active-card__infoBlock-description">
        {{ card.description }}
        <span @click="enlargeBlock">Свернуть</span>
      </div>
      <div v-else class="active-card__infoBlock-description">
        {{ description }}
        <span v-if="showFurther" @click="enlargeBlock">Далее</span>
      </div>
      <div :id="`infographic${card.id}`" class="active-card__infoBlock-info">
        <InfographicImgs :infographics="card.infographics" :is-green="false" />
      </div>
      <div class="active-card__infoBlock-topIcons">
        <base-button icon style-type="border" withoutActive @click="openComplainModal">
          <template #icon>
            <img :src="require('@/assets/img/mainPage/icons/icon-i.png')" alt="i" />
          </template>
        </base-button>
        <SaveDocsBtn v-if="card.docs.length" :docs="card.docs" />
        <CompareIcon :card="card" with-border />
        <base-button
          icon
          style-type="border"
          withoutActive
          @click="changeIsFavorite(card.landId)"
        >
          <template #icon>
            <base-icon
              :color="useUserStore().getIsFavorite(card.landId) ? '#EA1F1F' : '#969696'"
              font-size="20"
              name="favorite"
              width="22"
            />
          </template>
        </base-button>
      </div>
      <div class="active-card__infoBlock-phone">
        <PhoneButton :phone="card.phone" height="28" />
      </div>
      <div class="active-card__infoBlock-chat">
        <base-button
          height="28"
          style-type="coloredBorder"
          text="Чат"
          width="85"
          @click="useChatStore().openMiniMessenger(card)"
        />
      </div>

      <div class="active-card__infoBlock-bottomIcons">
        <base-button
          v-if="card.coordinates"
          :href="{
            name: 'offerCard',
            params: { url: card.landUrl },
            hash: '#map',
          }"
          icon
          style-type="coloredBorder"
          withoutActive
        >
          <template #icon>
            <img :src="require('@/assets/img/mainPage/icons/icon-map.png')" alt="map" />
          </template>
        </base-button>
        <router-link :to="{ name: 'offerCard', params: { url: card.landUrl } }">
          <base-button icon style-type="coloredBorder" withoutActive>
            <template #icon>
              <img :src="require('@/assets/img/mainPage/icons/icon-eye.png')" alt="eye" />
            </template>
          </base-button>
        </router-link>
      </div>
      <router-link
        v-if="card.aboutDeveloper"
        :to="{
          name: 'developer',
          params: { url: card.aboutDeveloper.url },
        }"
        class="items-in-line gap--10 active-card__infoBlock-seller"
      >
        <div class="active-card__infoBlock-seller-box">
          <base-image
            :src="card.aboutDeveloper?.logo[0]"
            contain
            height="50"
            name="seller"
            version="v2"
            width="74"
          />
          <base-divider vertical />
          <div class="active-card__infoBlock-seller-title">
            {{ card.aboutDeveloper.title }}
          </div>
        </div>
      </router-link>
    </base-card>
    <div class="active-card__calcBlock">
      <base-card>
        <div class="active-card__calcBlock-top">
          <div class="active-card__calcBlock-top-price">
            {{ card.price ? card.price.toLocaleString() : '-' }} ₽
          </div>
          <div>
            {{ `За м²: ${pricePerMeter} ₽` }}
          </div>
          <div class="active-card__calcBlock-top-btn">
            <base-button
              :href="{
                name: 'offerCard',
                params: { url: card.landUrl },
                hash: '#mortgage',
              }"
              height="28"
              style-type="coloredBorder"
              text="К расчету"
              width="164"
            />
          </div>
        </div>
      </base-card>
      <base-card>
        <div class="active-card__calcBlock-bottom">
          <div v-if="card.rates?.length" class="active-card__calcBlock-bottom-rates-box">
            <div v-for="(item, i) in card.rates" :key="i">
              <div class="active-card__calcBlock-bottom-textBox">
                <div>
                  <div class="medium mb--5">{{ item.title }}:</div>
                  <div v-if="item.error">
                    <span class="only-PC">{{ item.error }}</span>
                    <span class="none-PC">-</span>
                  </div>
                  <div v-else>
                    от <span class="blue-color">{{ item?.minRate }}%</span> |
                    {{ item.minPayment?.toLocaleString() }} ₽
                  </div>
                </div>
                <base-divider
                  v-if="i !== card.rates?.length - 1"
                  :vertical="window <= 820 && window > 600"
                />
              </div>
            </div>
          </div>
          <div v-else class="active-card__calcBlock-bottom-rates-box">
            Скоро здесь появятся доступные ипотечные программы
          </div>
          <base-button
            :disabled="!card.rates?.length"
            :href="{
              name: 'offerCard',
              params: { url: card.landUrl },
              hash: '#mortgage',
            }"
            class="active-card__calcBlock-bottom-btn"
            height="28"
            style-type="coloredBorder"
            text="К расчету"
            width="164"
          />
        </div>
      </base-card>
      <ComplainModal
        :entity-id="card.landId"
        :show-modal="showComplainModal"
        @close="showComplainModal = false"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ResultCardType } from '@/types';
import { computed, onMounted, ref, Ref, watch } from 'vue';
import { useAppStore } from '@/store/modules/app';
import { ComplainModal } from '@/components';
import {
  CompareIcon,
  InfographicImgs,
  SaveDocsBtn,
} from '@/components/Cards/CardsComponents';
import { useUserStore } from '@/store/modules/user';
import { useChatStore } from '@/store/modules/chat';
import PhoneButton from '../../../../PhoneButton/PhoneButton.vue';

const props = defineProps<{
  card: ResultCardType;
}>();
const emit = defineEmits(['updateActiveBlock', 'changeActive']);

const window = computed((): number => {
  return useAppStore().window;
});

const showFurther = ref(false);
const showEnlargedBlock = ref(false);
const showComplainModal = ref(false);
const infographicHeight: Ref<number | undefined> = ref(undefined);
const changeIsFavorite = (cardId: number) => {
  if (useUserStore().user) {
    useUserStore().changeIsFavorite(cardId);
  } else {
    useAppStore().openAuthModal(true);
  }
};

const openComplainModal = () => {
  if (useUserStore().getIsLogin()) {
    showComplainModal.value = true;
  } else {
    useAppStore().openAuthModal(true);
  }
};

const description = computed((): string => {
  let sliced: string;

  if ((infographicHeight.value && infographicHeight.value > 40) || window.value < 1264) {
    sliced = props.card.description?.slice(0, 250);
  } else {
    sliced = props.card.description?.slice(0, 420);
  }

  if (sliced?.length < props.card.description?.length) {
    showFurther.value = true;
  }

  return sliced;
});

const pricePerMeter = computed((): string => {
  if (
    props.card.id === props.card.landId &&
    props.card.price !== '-' &&
    props.card.price &&
    props.card.landArea !== '-' &&
    props.card.landArea
  ) {
    return Math.round(+props.card.price / +props.card.landArea).toLocaleString();
  }
  if (
    props.card.price !== '-' &&
    props.card.houseArea !== '-' &&
    props.card.houseArea &&
    props.card.price &&
    props.card.houseArea
  ) {
    return Math.round(+props.card.price / +props.card.houseArea).toLocaleString();
  }

  return '-';
});

const enlargeBlock = () => {
  showEnlargedBlock.value = !showEnlargedBlock.value;
  emit('updateActiveBlock');
};

onMounted(() => {
  setTimeout(() => {
    infographicHeight.value = document.getElementById(
      `infographic${props.card.id}`,
    )?.offsetHeight;
  }, 100);
});
watch(
  () => window.value,
  () => {
    emit('updateActiveBlock');
  },
);
</script>

<style lang="scss" scoped src="./ActiveOfferCard.scss"></style>
