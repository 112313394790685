<template>
  <div v-if="showRates">
    <div class="mortgage-block__cards mb--10">
      <div v-for="(mortgage, i) in mortgageData" :key="i" class="align-left">
        <base-card class="mortgage-block__card">
          <div class="medium textHidden">{{ mortgage.title }}</div>
          <div v-if="mortgage.error">-</div>
          <div v-else class="mortgage-block__card-info">
            <span> от </span>
            <span class="blue-color">{{ mortgage.minRate || '-' }}%</span>
            <base-divider color="#000" inset="80%" vertical />
            <span
              >{{
                mortgage.minPayment ? mortgage.minPayment?.toLocaleString() : '-'
              }}
              ₽</span
            >
          </div>
        </base-card>
      </div>
    </div>
  </div>
  <div class="mortgage-block__widget">
    <MortgageWidget />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { RateType } from '@/types/MainPageTypes';
import { MortgageWidget } from '@/components';

const props = withDefaults(
  defineProps<{
    mortgage?: RateType[];
    showRates?: boolean;
  }>(),
  {
    showRates: true,
  },
);
const mortgageData = computed((): RateType[] => {
  if (props.mortgage) {
    return props.mortgage;
  }

  return [];
});
</script>

<style lang="scss" scoped src="./MortgageBlock.scss"></style>
