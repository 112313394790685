<template>
  <div class="active">
    <div class="active__actions mb--20">
      <div class="active__actions__filters allBlockWidth">
        <AnnouncementsFilterInputs
          v-model:constructionLineId="constructionLineId"
          v-model:developerId="developerId"
          v-model:residentialComplexId="residentialComplexId"
          @update-values="filterHandler()"
        />
      </div>
      <button
        v-if="type === 'active'"
        class="gradient-color"
        @click="showCreateCategoryModal({ categoryTitle: 'Объявление' })"
      >
        Создать объявление
      </button>
    </div>
    <div class="mb--20">
      <div class="active__actions-sort only-mobile">
        <base-select-input
          :loading="isLoading"
          :modelValue="activeSort"
          :options="sortOptions.flat()"
          label="Сортировать по"
          @update:modelValue="activeSort = $event"
        />
      </div>
      <div class="none-Mobile items-in-line flex-start">
        <OpenSorting v-model="activeSort" :sort-options="sortOptions" />
      </div>
    </div>

    <div v-if="isLoading" class="active__cards">
      <base-skeleton-loader
        v-for="i in 4"
        :key="i"
        :styles="{ height: '126px', width: '100%' }"
        class="mb--5"
        type="mediumText"
      />
    </div>
    <div
      v-else-if="preparedResultCards && preparedResultCards.length"
      class="active__cards"
    >
      <div v-for="card in preparedResultCards" :key="card.id">
        <AnnouncementCard :card="card" :type="type" @deleteCategory="deleteCategory" />
      </div>
    </div>
    <div v-else class="center">Объявлений нет</div>
    <CreateDeveloperModal
      :create-category-modal-data="createCategoryModalData"
      isOfferCard
      @close="closeCreateCategoryModal"
      @create-category="createCategory($event)"
    />
  </div>
</template>

<script lang="ts" setup>
import AnnouncementCard from '@/components/ProfileComponents/MyAnnouncements/AnnouncementCard/AnnouncementCard.vue';
import { computed, onMounted, Ref, ref, watch } from 'vue';
import { useDevelopersStore } from '@/store/modules/developers';
import { SmallOfferCardType } from '@/types/MainPageTypes';
import {
  CreateCategoryModalType,
  GetAnnouncementsDataType,
  OptionType,
  TypesMyAdsType,
} from '@/types';
import { CreateDeveloperModal } from '@/components/ProfileComponents';
import { useRoute, useRouter } from 'vue-router';
import { OpenSorting } from '@/components';
import AnnouncementsFilterInputs from '@/components/ProfileComponents/AnnounsmentsFilterInputs/AnnouncementsFilterInputs.vue';

const props = withDefaults(
  defineProps<{
    type: TypesMyAdsType;
  }>(),
  {},
);

const route = useRoute();
const router = useRouter();

const developerId: Ref<number | undefined> = ref(undefined);
const residentialComplexId: Ref<number | undefined> = ref(undefined);
const constructionLineId: Ref<number | undefined> = ref(undefined);

const filterHandler = async () => {
  await getAnnouncements({
    developerId: developerId.value,
    residentialComplexId: residentialComplexId.value,
    queueId: constructionLineId.value,
  });
};

const activeSort: Ref<number | undefined> = ref(undefined);
const sortOptions: OptionType[][] = [
  [
    { id: 1, name: 'По цене', key: 'price', icon: 'north' },
    { id: 2, name: 'По цене', key: 'price', icon: 'south' },
  ],
  [
    { id: 3, name: 'По дате', key: 'activeFrom', icon: 'north' },
    { id: 4, name: 'По дате', key: 'activeFrom', icon: 'south' },
  ],
  [
    { id: 5, name: 'По просмотрам', key: 'views', icon: 'north' },
    { id: 6, name: 'По просмотрам', key: 'views', icon: 'south' },
  ],
  [
    { id: 7, name: 'По избранным', key: 'favorites', icon: 'north' },
    { id: 8, name: 'По избранным', key: 'favorites', icon: 'south' },
  ],
];

const resultCards = computed((): SmallOfferCardType[] | undefined => {
  if (props.type === 'active') {
    return useDevelopersStore().orderList;
  } else if (props.type === 'drafts') {
    return useDevelopersStore().draftAdList;
  } else if (props.type === 'archive') {
    return useDevelopersStore().archiveAdList;
  }

  return [];
});

const preparedResultCards = computed((): SmallOfferCardType[] => {
  const getValue = (card: SmallOfferCardType, option: OptionType) => {
    const value = card[option.key as keyof SmallOfferCardType] as number | string;

    if ((option.id === 3 || option.id === 4) && value) {
      return new Date(value as string);
    }

    return value;
  };
  const sortCardsByField = (option: OptionType) => {
    if ((option.id as number) % 2 === 0) {
      return (a: SmallOfferCardType, b: SmallOfferCardType) =>
        getValue(a, option) < getValue(b, option) ? 1 : -1;
    } else {
      return (a: SmallOfferCardType, b: SmallOfferCardType) =>
        getValue(a, option) > getValue(b, option) ? 1 : -1;
    }
  };

  const option = sortOptions.flat().find(el => el.id === activeSort.value);

  if (resultCards.value && option) {
    const copedResultCards: SmallOfferCardType[] = [...resultCards.value];

    return copedResultCards.sort(sortCardsByField(option));
  }

  return resultCards.value ?? [];
});

const createCategoryModalData: Ref<CreateCategoryModalType> = ref({
  show: false,
  categoryTitle: undefined,
});
const showCreateCategoryModal = (event: { categoryTitle: string }) => {
  router.push({ hash: '#create' });
  createCategoryModalData.value = {
    show: true,
    categoryTitle: event.categoryTitle,
  };
};
const closeCreateCategoryModal = () => {
  createCategoryModalData.value = {
    show: false,
    categoryTitle: undefined,
  };
};

const newDraftAdId: Ref<number | undefined> = ref(undefined);
const createCategory = (event: { id: number; title: string }) => {
  newDraftAdId.value = event.id;
  router.push({ query: { menuItem: 'drafts' } });
};

const deleteCategory = async (id: number) => {
  useDevelopersStore().deleteAd(props.type, id);
};

const isLoading = ref(false);
const getAnnouncements = async (data?: GetAnnouncementsDataType) => {
  try {
    isLoading.value = true;

    if (props.type === 'active') {
      await useDevelopersStore().getOrderList(data);
    } else if (props.type === 'drafts') {
      await useDevelopersStore().getDrafts(data);
      if (newDraftAdId.value) {
        useDevelopersStore().updateIsNewAd(newDraftAdId.value);
        newDraftAdId.value = undefined;
      }
    } else if (props.type === 'archive') {
      await useDevelopersStore().getArchive(data);
    }

    activeSort.value = 4;
  } finally {
    isLoading.value = false;
  }
};

const hash = computed(() => {
  return route?.hash;
});

const checkIsCreate = () => {
  if (hash.value) {
    const isCreate = hash.value === '#create';

    if (isCreate) {
      showCreateCategoryModal({ categoryTitle: 'Объявление' });
    }
  }
};

onMounted(async () => {
  checkIsCreate();
  if (!resultCards.value) {
    await getAnnouncements();
  }
});
watch(
  () => hash.value,
  () => {
    checkIsCreate();
  },
);
watch(
  () => props.type,
  () => {
    developerId.value = undefined;
    residentialComplexId.value = undefined;
    constructionLineId.value = undefined;
    getAnnouncements();
  },
);
</script>

<style lang="scss" scoped src="./AnnouncementList.scss"></style>
