import { ObjectParamsApiType, OtherDevelopersType } from '@/api/developer/types';
import { DeveloperCardType } from '@/types';
import {
  prepareArrByParam,
  prepareCoordinatesByParam,
  prepareDocsByParam,
  prepareParam,
  prepareParamOfArray,
  prepareUrl,
  prepareUrlsByParam,
  prepareYearByParam,
} from '@/utils/Common/Common';
import { SmallDeveloperCardType } from '@/types/DeveloperTypes';

export const prepareDeveloperCard = (
  developerId: number,
  developer: ObjectParamsApiType,
): DeveloperCardType => {
  return {
    id: developerId,
    title: prepareParam(6, developer),
    logo: prepareParamOfArray(9, developer),
    yearOfFoundation: prepareYearByParam(10, developer),
    description: prepareParam(11, developer),
    awards: prepareUrlsByParam(74, developer),
    team: developer[344]
      ? developer[344].map(el => {
          return {
            fio: el.value,
            job: prepareParam(345, developer) || '',
            photo: prepareParamOfArray(346, developer) || '',
          };
        })
      : undefined,
    documents: prepareDocsByParam(300, developer),
    coordinates: prepareCoordinatesByParam(225, developer),
    partners: prepareArrByParam(341, developer),
    phone: prepareParam(12, developer),
    builtCount: prepareParam(17, developer),
    buildingCount: prepareParam(18, developer),
  };
};

export const prepareDeveloperSmallCard = (
  developers: OtherDevelopersType[],
): SmallDeveloperCardType[] => {
  const smallCards: SmallDeveloperCardType[] = [];

  developers.forEach(developer => {
    Object.entries(developer).forEach(el => {
      smallCards.push({
        id: +el[0],
        logo: el[1][9]
          ? prepareUrl(
              Array.isArray(el[1][9].value) ? el[1][9].value[0].path : el[1][9].value,
            )
          : undefined,
        title: el[1][6]?.value,
        builtCount: el[1][17]?.numeric_value,
        buildingCount: el[1][18]?.value,
        documents: el[1][300]
          ? el[1][300].value.map(doc => {
              return {
                id: doc.id,
                name: doc.name || 'файл',
                path: prepareUrl(doc.path),
              };
            })
          : undefined,
        yearOfFoundation:
          el[1][10] && el[1][10].value !== ''
            ? new Date(el[1][10].value).getFullYear()
            : undefined,
        url: el[1].customUrl,
        name: el[1].name,
      });
    });
  });

  return smallCards;
};
