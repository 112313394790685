<template>
  <div
    :id="`textareaBox${uuid}`"
    :class="styleType"
    :style="[cssProps, heightConst]"
    class="textareaBox"
  >
    <textarea
      :id="`textarea${uuid}`"
      v-model="inputValue"
      :disabled="disabled"
      :rows="1"
      required
      @keyup="resize"
      v-on="listeners"
    >
    </textarea>
    <span class="textareaBox__label">{{ label }}</span>
    <span v-if="isError || errorMessage?.length || error?.length" class="inputBox__error">
      {{ errorMessage || error }}
    </span>
  </div>
</template>
<script lang="ts" setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import { StylesType } from '@/types';
import { ComponentInternalInstance } from '@vue/runtime-core';

// eslint-disable-next-line no-undef
const inputValue = defineModel<string | undefined>();

const props = withDefaults(
  defineProps<{
    label?: string;
    styleType?: 'light';
    styles?: StylesType;
    height?: string;
    maxHeight?: string;
    error?: string;
    withScroll?: boolean;
    disabled?: boolean;
    isEditInModal?: boolean;
  }>(),
  {
    styleType: undefined,
    height: '36',
  },
);
const emit = defineEmits(['focus', 'blur']);

const instance: ComponentInternalInstance | null = getCurrentInstance();
const uuid = ref(instance?.uid);

const listeners = computed(() => ({
  focus: (e: PointerEvent) => emit('focus', e),
  blur: (e: PointerEvent) => emit('blur', e),
}));
const errorMessage = ref('');
const isError = ref(false);

const cssProps = computed(() => {
  let styles: { [key: string]: string | undefined } = {
    '--border': props.styles?.border ?? '1px solid #a79f9a',
    '--background-color': props.styles?.backgroundColor,
  };

  if (props.maxHeight) {
    styles = {
      ...styles,
      '--max-height': `${props.maxHeight}px`,
    };
  }

  return styles;
});

const heightConst = ref({
  '--min-height': `${props.height}px`,
});
const resize = () => {
  let textarea: HTMLInputElement | null = document.querySelector(
    `#textarea${uuid.value}`,
  );
  let textareaBox: HTMLInputElement | null = document.querySelector(
    `#textareaBox${uuid.value}`,
  );

  if (textarea) {
    textarea.style.height = 'auto';
    textarea.style.height =
      textarea.scrollHeight < +props.height
        ? +props.height + 'px'
        : textarea.scrollHeight + 'px';

    if (
      props.withScroll &&
      props.maxHeight &&
      +textarea.scrollHeight > +props.maxHeight
    ) {
      textarea.style.overflow = 'auto';
    }
    if (textareaBox) {
      textareaBox.style.height = textarea.style.height;
    }
  }
};

onMounted(() => {
  if (inputValue.value) {
    resize();
  }
});
</script>

<style lang="scss" scoped src="./BaseInput.scss"></style>
