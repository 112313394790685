<template>
  <base-card :class="{ isSticky }" class="mb--20 second-header">
    <div class="second-header__container">
      <div :class="{ withBlock: $slots.data }" class="second-header__container-first">
        <div class="second-header__container-first-title">
          <base-image
            :src="logo"
            contain
            height="70"
            name="seller"
            version="v7"
            width="70"
          />
          <div class="items-in-column">
            <div v-if="!$slots.title" class="second-header__container-title-box">
              <span class="font-size-title-block">
                Результаты проверки: {{ title }}
              </span>
              <base-icon
                :class="{ open: showBlock }"
                class="second-header__container-expand-icon only-mobile"
                clickable
                color="#009D3E"
                name="expand_more"
                @click="showBlock = !showBlock"
              />
            </div>
            <slot name="title"></slot>
            <div class="second-header__container-actions">
              <div class="items-in-line gap--10 flex-start">
                <PhoneButton v-if="phone && phone.length" :phone="phone" iconMobile />
                <base-button
                  v-if="offerCardUrl"
                  all-block-width
                  class="none-Mobile"
                  style-type="coloredBorder"
                  text="Чат"
                  @click="useChatStore().openMiniMessenger(undefined, offerCardUrl)"
                />
                <base-button
                  v-if="offerCardUrl"
                  class="only-mobile"
                  icon
                  style-type="greenBorder"
                  @click="useChatStore().openMiniMessenger(undefined, offerCardUrl)"
                >
                  <template #icon>
                    <base-icon color="#009D3E" font-size="20" name="mail" />
                  </template>
                </base-button>
              </div>
              <div class="items-in-line gap--10">
                <slot name="btn"></slot>
                <span class="items-in-line only-mobile">
                  <ShareBtn
                    btn-type="greenBorder"
                    color="#009D3E"
                    font-size="20"
                    height="28"
                    width="28"
                /></span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="$slots.data" class="second-header__container-first-horizontal-divider">
          <base-divider />
        </div>
        <slot name="data" />
      </div>
      <div class="second-header__container-second none-Mobile">
        <div class="second-header__container-second-divider">
          <base-divider vertical />
        </div>

        <span class="second-header__container-right-box">
          <span class="items-in-line"> <ShareBtn /></span>

          <button class="gradient-color" @click="showBlock = !showBlock">
            {{ showBlock ? 'Свернуть' : 'Развернуть' }}
          </button>
        </span>
      </div>
    </div>
  </base-card>
</template>

<script lang="ts" setup>
import { PhoneButton, ShareBtn } from '@/components';
import { useChatStore } from '@/store/modules/chat';

const props = withDefaults(
  defineProps<{
    offerCardUrl?: string | undefined;
    title?: string;
    phone?: string;
    logo?: string;
    isSticky?: boolean;
  }>(),
  {
    isSticky: true,
  },
);

// eslint-disable-next-line no-undef
const showBlock = defineModel<boolean>();
</script>

<style lang="scss" scoped src="./SecondHeader.scss"></style>
