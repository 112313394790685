<template>
  <div v-if="loading" class="mt--20 container content center heightContent">
    <base-spinner :size="50" color="#009D3E" />
  </div>
  <div v-else-if="developerCardData" class="developer-card">
    <div class="container content developer-card__container">
      <div class="items-in-line flex-start">
        <h2 class="content__title developer-card__container-title">
          {{
            `${
              developerCardData.title?.length
                ? developerCardData.title
                : 'Название застройщика'
            }. ${developerCardData.yearOfFoundation ? `Строит с ${developerCardData.yearOfFoundation} года` : ''}`
          }}
        </h2>
      </div>
      <div>
        <DeveloperCard
          :developerCardData="developerCardData"
          :otherDevelopers="otherDeveloperCardsData"
          :otherDevelopersLoading="otherDevelopersLoading"
          :projects="developerProjects"
          :projectsLoading="projectsLoading"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, Ref, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDevelopersStore } from '@/store/modules/developers';
import { DeveloperCardType, SmallCardLSDType, SmallDeveloperCardType } from '@/types';
import { DeveloperCard } from '@/components/Cards';
import { useAppStore } from '@/store/modules/app';
import { errorHandle } from '@/utils/Common/Common';

const loading = ref(false);
const projectsLoading = ref(false);
const otherDevelopersLoading = ref(false);
const router = useRouter();
const developerCardData: Ref<DeveloperCardType | undefined> = ref(undefined);
const otherDeveloperCardsData: Ref<SmallDeveloperCardType[] | undefined> = ref(undefined);
const developerProjects: Ref<SmallCardLSDType[] | undefined> = ref(undefined);

const route = useRoute();
const developerCardUrl = computed(() => {
  return route.params.url;
});

const updateDeveloperCard = async () => {
  loading.value = true;

  if (developerCardUrl.value) {
    try {
      const res = await useDevelopersStore().searchDeveloperCardByUrl(
        developerCardUrl.value,
      );

      if (res) {
        developerCardData.value = res;

        projectsLoading.value = true;

        try {
          const projects = await useDevelopersStore().searchProjectsByUrlApi(
            developerCardUrl.value,
          );

          if (projects) {
            developerProjects.value = projects;
          }
        } finally {
          projectsLoading.value = false;
        }

        otherDevelopersLoading.value = true;
        try {
          const developers = await useDevelopersStore().searchOtherDevelopersByUrlApi(
            developerCardUrl.value,
          );

          if (developers) {
            otherDeveloperCardsData.value = developers;
          }
        } finally {
          otherDevelopersLoading.value = false;
        }
      }
    } catch (error) {
      errorHandle(error);
    } finally {
      loading.value = false;
    }
  } else {
    await router.push({ name: 'main' });
  }
};

onMounted(async () => {
  if (useAppStore().getWindowWidth() < 960) {
    document.documentElement.style.setProperty('--btn-up-bottom', '120px');
  }
  await updateDeveloperCard();
});
watch(
  () => developerCardUrl.value,
  () => {
    if (developerCardUrl.value) {
      updateDeveloperCard();
    }
  },
);
onUnmounted(() => {
  document.documentElement.style.removeProperty('--btn-up-bottom');
});
</script>

<style lang="scss" scoped src="./DeveloperCardPageLayout.scss"></style>
