<template>
  <base-modal
    :show-modal="createCategoryModalData.show"
    :title="`Создание сущности категории '${createCategoryModalData.categoryTitle}'`"
    @close="closeCreateCategoryModal"
  >
    <template #content>
      <div class="create-category-modal">
        <base-form class="mb--20" @submit="createCategory()">
          <base-input
            v-model="formData.newTitle"
            :error="errors.newTitle"
            :invalid="!!errors.newTitle"
            height="36"
            hideArrows
            label="Название"
            @blur="validationErrorsHandlerByKey('newTitle')"
            @focus="delete errors.newTitle"
            @input="v$.newTitle.$touch"
          />
          <div v-if="isOfferCard" class="items-in-column gap--20 mt--20">
            <AnnouncementsFilterInputs
              v-model:categoryId="categoryId"
              v-model:constructionLineId="constructionLineId"
              v-model:developerId="developerId"
              v-model:residentialComplexId="residentialComplexId"
            />
          </div>
          <base-select-input
            v-else-if="isResidentialComplex"
            v-model="legalEntityId"
            :options="listLegalEntitiesOptions"
            class="mt--20"
            label="Выберите Юр. Лицо"
          />
        </base-form>
        <div class="center">
          <base-button
            :loading="isCreateCategoryLoading"
            height="40"
            text="Сохранить"
            @click="createCategory()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/utils/Common/Common';
import { ref, Ref, watch } from 'vue';
import { CreateCategoryModalType, ErrorsType, OptionsType } from '@/types';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useDevelopersStore } from '@/store/modules/developers';
import { useUserStore } from '@/store/modules/user';
import { LegalEntitiesType } from '@/api/developer/types';
import AnnouncementsFilterInputs from '@/components/ProfileComponents/AnnounsmentsFilterInputs/AnnouncementsFilterInputs.vue';

type FormDataType = {
  newTitle: string;
};

const props = defineProps<{
  createCategoryModalData: CreateCategoryModalType;
  isResidentialComplex?: boolean;
  isOfferCard?: boolean;
}>();
const emit = defineEmits(['createCategory', 'close']);

const errors: Ref<ErrorsType> = ref({});
const isCreateCategoryLoading = ref(false);
const listLegalEntitiesOptions: Ref<OptionsType[]> = ref([]);
const currentEntitiesOptions: Ref<number | undefined> = ref(undefined);
const legalEntityId: Ref<number | undefined> = ref(undefined);
const developerId: Ref<number | undefined> = ref(undefined);
const residentialComplexId: Ref<number | undefined> = ref(undefined);
const constructionLineId: Ref<number | undefined> = ref(undefined);
const categoryId: Ref<number | string | undefined> = ref(undefined);
const formData: Ref<FormDataType> = ref({
  newTitle: '',
});

const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  newTitle: { required },
};
const prepareList = (res: LegalEntitiesType | undefined) => {
  if (res) {
    categoryId.value = res.childCategory;

    return res.list?.map(el => {
      return {
        id: el.id,
        name: el.value,
      };
    });
  } else {
    return [];
  }
};
const validationErrorsHandlerByKey = (key: string | number) => {
  let error: string | undefined = errorsHandlerByKey(String(key), rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};
const v$ = useVuelidate<FormDataType>(rules, formData, {
  $autoDirty: true,
  $stopPropagation: true,
});
const clearFormData = () => {
  v$.value.$reset();
  formData.value.newTitle = '';
  residentialComplexId.value = undefined;
  legalEntityId.value = undefined;
  developerId.value = undefined;
  constructionLineId.value = undefined;
};
const closeCreateCategoryModal = () => {
  clearFormData();
  emit('close');
};
const createCategory = async () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
    validationErrorsHandler();

    return;
  }
  if (categoryId.value) {
    let parentId;

    if (props.isOfferCard && useUserStore().user?.is_construction_company) {
      parentId = constructionLineId.value;
    } else if (props.isOfferCard) {
      parentId =
        residentialComplexId.value === 0 ? undefined : residentialComplexId.value;
    } else if (props.isResidentialComplex) {
      parentId = legalEntityId.value;
    } else {
      parentId = props.createCategoryModalData.parentId;
    }
    try {
      isCreateCategoryLoading.value = true;
      const preparedData = {
        categoryId: String(categoryId.value),
        parentId: parentId,
        value: formData.value.newTitle,
      };
      const res = await useDevelopersStore().createCategoryValue(preparedData);

      if (res) {
        emit('createCategory', {
          id: res.id,
          title: formData.value.newTitle,
          url: res.customUrl,
        });
        closeCreateCategoryModal();
      }
    } catch (error) {
      errors.value = validationErrorsByRequest(error);
    } finally {
      isCreateCategoryLoading.value = false;
    }
  }
};

watch(
  () => props.createCategoryModalData.show,
  async () => {
    if (props.createCategoryModalData.show) {
      if (props.isResidentialComplex || props.isOfferCard) {
        if (props.isResidentialComplex) {
          await useDevelopersStore()
            .getLegalEntities()
            .then(res => {
              listLegalEntitiesOptions.value = prepareList(res);
            });
        }
      } else {
        categoryId.value = props.createCategoryModalData.categoryId;
        currentEntitiesOptions.value = props.createCategoryModalData.parentId;
      }
    }
  },
);
</script>

<style lang="scss" scoped>
.create-category-modal {
  width: 100%;
}
</style>
