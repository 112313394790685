<template>
  <div class="container cardsBlock">
    <div class="mb--20 none-Mobile">
      <InfoTopBar
        text="В данном разделе вы можете ознакомиться с юридическими рисками. Сервис предоставляет информацию на основе сбора, систематизации и анализа сведений."
      />
    </div>
    <SecondHeader
      v-if="checkResult"
      v-model="showItems"
      :logo="checkResult?.logo"
      :offer-card-url="!checkResult?.isDeveloper ? checkResult.customUrl : undefined"
      :phone="checkResult?.phone"
      :title="checkResult?.isDeveloper ? checkResult?.brandName : checkResult?.address"
      address="offerCard.address"
    >
      <template #btn>
        <base-button
          :href="{
            name: checkResult?.isDeveloper ? 'developer' : 'offerCard',
            params: { url: checkResult.customUrl },
          }"
          :text="checkResult?.isDeveloper ? 'К застройщику' : 'К объявлению'"
          all-block-width
          class="no-wrap"
          style-type="coloredBorder"
        />
      </template>
    </SecondHeader>
    <div v-if="showItems && checkResult" class="mb--20">
      <div v-for="item in checkResult.entities" class="mb--25">
        <base-expansion-panels
          v-if="item.icons.length"
          :is-open="true"
          styleColor="green"
        >
          <template #title="titleProps">
            <div class="check-result__card-title">
              <div>
                <span class="m-font-size">{{ item.title }}</span>
                <span v-if="checkResult.isDeveloper" class="m-font-size"
                  >: {{ item.companyTitle }}</span
                >
                <span v-if="item.checkDate">, дата проверки: {{ item.checkDate }}</span>
              </div>

              <button class="gradient-color" @click="titleProps.events.handleClick()">
                {{ titleProps.data ? 'Свернуть' : 'Развернуть' }}
              </button>
            </div>
          </template>
          <template #content>
            <div class="check-result__card-options">
              <div v-for="(icon, i) in item.icons" :key="i">
                <span class="check-result__card-options-item">
                  {{ icon.title }}
                  <base-icon
                    v-if="icon.value === '2'"
                    color="#009D3E"
                    height="17"
                    name="check_circle"
                    width="17"
                  />
                  <base-icon
                    v-else-if="icon.value === '3'"
                    color="#EA1F1F"
                    height="17"
                    name="warning"
                    width="17"
                  />
                  <span v-else> - </span>
                </span>
              </div>
            </div>
          </template>
        </base-expansion-panels>
      </div>
      <div v-for="item in checkResult.entities" class="mb--25">
        <base-expansion-panels
          v-if="item.info.length"
          :is-open="true"
          styleColor="orange"
        >
          <template #title="titleProps">
            <div class="check-result__card-title">
              <div>
                <span class="m-font-size">{{ item.title }}</span>
                <span v-if="checkResult.isDeveloper" class="m-font-size"
                  >: {{ item.companyTitle }}</span
                >
                <span v-if="item.checkDate">, дата проверки: {{ item.checkDate }}</span>
              </div>
              <button class="gradient-color" @click="titleProps.events.handleClick()">
                {{ titleProps.data ? 'Свернуть' : 'Развернуть' }}
              </button>
            </div>
          </template>
          <template #content>
            <div class="check-result__card-options twoColumns">
              <div v-for="(infoItem, i) in item.info" :key="i">
                <div class="check-result__card-options-column-item">
                  <div class="third-color">{{ infoItem.title }}</div>
                  <div class="check-result__card-options-column-result">
                    {{ prepareValue(infoItem) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </base-expansion-panels>
      </div>
    </div>
    <div class="mb--20 only-mobile">
      <InfoTopBar
        text="В данном разделе вы можете ознакомиться с юридическими рисками. Сервис предоставляет информацию на основе сбора, систематизации и анализа сведений."
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, Ref, ref } from 'vue';
import { LocationQueryValue, useRoute, useRouter } from 'vue-router';
import { useCheckResultStore } from '@/store/modules/checkResult';
import { InfoTopBar, SecondHeader } from '@/components';
import { CheckResultItemType, CheckResultRequestType } from '@/api/check/types';
import { prepareUrl } from '@/utils/Common/Common';
import { prepareValueByType } from '@/utils/Common/DeveloperAdmin';

const showItems = ref(true);

const checkResult: Ref<CheckResultRequestType | undefined> = ref(undefined);
const loading = ref(false);
const router = useRouter();

const prepareValue = (param: CheckResultItemType) => {
  if (!param.value) {
    return '-';
  }
  if (param.type === 12 || param.type === 20) {
    return param.value;
  }

  return prepareValueByType(param).value;
};

const route = useRoute();
const checkId = computed((): LocationQueryValue | LocationQueryValue[] | undefined => {
  if (route?.query) {
    return route?.query?.id;
  } else {
    return undefined;
  }
});

onMounted(async () => {
  loading.value = true;

  if (checkId.value) {
    try {
      const res = await useCheckResultStore().searchCheckResultById(+checkId.value);

      if (res) {
        checkResult.value = { ...res, logo: prepareUrl(res.logo) as string };
      } else {
        await router.push({ name: 'main' });
      }
    } finally {
      loading.value = false;
    }
  } else {
    await router.push({ name: 'main' });
  }
});
</script>

<style lang="scss" scoped src="./CheckResultComponent.scss"></style>
