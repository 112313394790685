<template>
  <base-button-group
    :active="activeFilterBtn?.id"
    :buttons="filterBtnsByType"
    :isLoading="isLoadingTopBlock"
    btnHeight="34"
    btnWidth="100"
    class="banner__content-btns-left"
    type="translucent"
    @changeActiveBtn="useOfferCardsStore().changeActiveFilterBtn($event)"
  />
</template>

<script lang="ts" setup>
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { computed, onMounted, watch } from 'vue';
import { FilterBtnsType } from '@/types';
import { useAppStore } from '@/store/modules/app';

const activeFilterBtn = computed(() => {
  return useOfferCardsStore().activeFilterBtn;
});
const isLoadingTopBlock = computed(() => {
  return useOfferCardsStore().isLoadingTopBlock;
});

const filterBtnsByType = computed((): FilterBtnsType[] => {
  return useOfferCardsStore().filterBtnsByType;
});
const inputsValues = computed((): { [key: string]: string | number | undefined } => {
  return {
    202: Number(useOfferCardsStore().params[202]?.value) ?? undefined,
    113: Number(useOfferCardsStore().params[113]?.value) ?? undefined,
    2: useOfferCardsStore().params[203]?.min ?? undefined,
    3: useOfferCardsStore().params[203]?.max ?? undefined,
    4: useOfferCardsStore().params[199]?.min ?? undefined,
    5: useOfferCardsStore().params[199]?.max ?? undefined,
    7: useOfferCardsStore().params[365]?.min ?? undefined,
    8: useOfferCardsStore().params[365]?.max ?? undefined,
    9: useOfferCardsStore().params[116]?.min ?? undefined,
    10: useOfferCardsStore().params[116]?.max ?? undefined,
  };
});
const updateInputs = () => {
  //Подставляем уже заполненные параметры в inputs
  const inputIds = [202, 113, 2, 3, 4, 5, 7, 8, 9, 10];

  inputIds.forEach((el: number) => {
    useOfferCardsStore().updateMainInputsParams({
      id: el,
      value: inputsValues.value[el],
    });
  });
};

const landInputs = [
  {
    id: 113,
    type: 'select',
    label: 'Тип земельного участка',
    value: undefined,
    options: useAppStore().optionsConst[113],
  },
  {
    id: 7,
    type: 'number',
    label: 'Цена от',
    value: undefined,
    min: 0,
    max: 999999999,
  },
  {
    id: 8,
    type: 'number',
    label: 'Цена до',
    value: undefined,
    min: 0,
  },
  {
    id: 9,
    type: 'number',
    label: 'Соток от',
    value: undefined,
    min: 0,
  },
  {
    id: 10,
    type: 'number',
    label: 'Соток до',
    value: undefined,
    min: 0,
  },
  {
    id: 6,
    type: 'text',
    label: 'Адрес, район',
    value: undefined,
  },
];
const houseInputs = [
  {
    id: 202,
    type: 'select',
    label: 'Тип недвижимости',
    value: undefined,
    options: useAppStore().optionsConst[202],
  },
  {
    id: 2,
    type: 'number',
    label: 'Цена от',
    value: undefined,
    min: 0,
    max: 999999999,
  },
  {
    id: 3,
    type: 'number',
    label: 'Цена до',
    value: undefined,
    min: 0,
  },
  {
    id: 4,
    type: 'number',
    label: 'Метраж от',
    value: undefined,
    min: 0,
  },
  {
    id: 5,
    type: 'number',
    label: 'Метраж до',
    value: undefined,
    min: 0,
  },
  {
    id: 6,
    type: 'text',
    label: 'Адрес, район, ЖК',
    value: undefined,
  },
];

onMounted(() => {
  if (activeFilterBtn.value?.id === 3) {
    useOfferCardsStore().rewriteMainInputsParams(JSON.parse(JSON.stringify(landInputs)));
  } else {
    useOfferCardsStore().rewriteMainInputsParams(JSON.parse(JSON.stringify(houseInputs)));
  }

  //Подставляем уже заполненные параметры в inputs
  updateInputs();
});
watch(
  () => activeFilterBtn.value,
  (newBtnId?: FilterBtnsType, oldBtnId?: FilterBtnsType) => {
    if (newBtnId?.id === 3) {
      useOfferCardsStore().rewriteMainInputsParams(
        JSON.parse(JSON.stringify(landInputs)),
      );
    } else if (oldBtnId?.id === 3) {
      useOfferCardsStore().rewriteMainInputsParams(
        JSON.parse(JSON.stringify(houseInputs)),
      );
    }
    updateInputs();
  },
);
</script>

<style lang="scss" scoped></style>
