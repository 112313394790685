<template>
  <div class="container content compare">
    <div class="compare__title">
      <h2 v-if="isNewCompare">Сравнение объявлений</h2>
      <h2 v-else-if="compareData" class="items-in-line flex-start">
        Сравнение объявлений: {{ compareData.name }}
        <base-icon
          clickable
          font-size="28"
          name="edit"
          @click="
            () => {
              showChangeTitleModal = true;
            }
          "
        />
      </h2>
      <div v-if="!isLoading">
        <div v-if="useUserStore().user" class="compare__title-actions">
          <base-button
            v-if="compareData"
            :href="{ name: 'main' }"
            height="auto"
            style-type="coloredBorder"
            text="Добавить объявления"
          />
          <base-button
            :href="{ name: 'profile', params: { id: 'compare' } }"
            height="auto"
            style-type="coloredBorder"
            text="Список сравнений"
          />
          <base-button
            v-if="compareData"
            :text="
              useAppStore().getWindowWidth() > 600
                ? 'Создать новый список'
                : 'Новый список'
            "
            height="auto"
            style-type="coloredBorder"
            @click="createNewCompare"
          />
        </div>
        <div v-else class="compare__title-actions">
          <base-button
            style-type="coloredBorder"
            text="Сохранить"
            @click="openAuthModal"
          />
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="container center">
      <base-spinner :size="50" color="#009D3E" />
    </div>

    <div v-else-if="offerCardsForComparison.length">
      <CompareTable
        :offerCards="offerCardsForComparison"
        @changeIsCompare="deleteCard($event)"
        @changeIsFavorite="useUserStore().changeIsFavorite($event)"
      />
    </div>
    <div v-else class="items-in-column align-center">
      <div>Добавьте объявления, чтобы сравнить</div>
      <base-button :href="{ name: 'main' }" height="40" text="К объявлениям" />
    </div>
    <base-info-modal
      :key="deleteCardDataModal.card?.id"
      :show-info-modal="deleteCardDataModal.show"
      position="top-right"
      @close="closeInfoModal()"
    >
      <div class="items-in-column">
        <div>Объявление удалено</div>
        <button
          v-if="deleteCardDataModal.card !== undefined"
          class="underline white-color"
          @click="returnCard(deleteCardDataModal.card)"
        >
          Вернуть к сравнению
        </button>
      </div>
    </base-info-modal>
    <ChangeCompareTitleModal
      v-if="compareData"
      :compare-id="compareData?.id"
      :compare-name="compareData?.name"
      :show-modal="showChangeTitleModal"
      @close="showChangeTitleModal = false"
      @updateSuccessMessage="saveNewCompare"
    />
    <base-info-modal
      v-if="!!successMessage"
      :show-info-modal="!!successMessage"
      @close="successMessage = undefined"
    >
      <div>{{ successMessage }}</div>
    </base-info-modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref, watch } from 'vue';
import { CompareType, ResultCardType } from '@/types';
import { ChangeCompareTitleModal, CompareTable } from '@/components';
import { useRoute, useRouter } from 'vue-router';
import { useCompareStore } from '@/store/modules/compare';
import { useUserStore } from '@/store/modules/user';
import { useAppStore } from '@/store/modules/app';

const router = useRouter();
const offerCardsForComparison: Ref<ResultCardType[]> = ref([]);
const showChangeTitleModal = ref(false);
const isLoading = ref(false);
const isNewCompare = ref(false);
const compareData: Ref<CompareType | undefined> = ref(undefined);
const successMessage: Ref<string | undefined> = ref(undefined);

const deleteCardDataModal: Ref<{
  show: boolean;
  card: ResultCardType | undefined;
}> = ref({
  show: false,
  card: undefined,
});
const changeIsCompare = async (card: ResultCardType) => {
  await useCompareStore().changeIsCompare(card);
};

const openAuthModal = () => {
  useAppStore().openAuthModal(true);
};
const saveNewCompare = (data: { message: string }) => {
  successMessage.value = data.message;
  compareData.value = useCompareStore().compareData;
};

const returnCard = async (card: ResultCardType) => {
  await changeIsCompare(card);
  await closeInfoModal();
  offerCardsForComparison.value = useCompareStore().compareList;
};
const deleteCard = async (card: ResultCardType) => {
  if (isNewCompare.value) {
    changeIsCompare(card);
    deleteCardDataModal.value = {
      show: true,
      card: card,
    };
  } else {
    if (compareData.value) {
      if (offerCardsForComparison.value.length > 1) {
        deleteCardDataModal.value = {
          show: true,
          card: card,
        };
        await useCompareStore()
          .deleteHouseFromCompare(compareData.value.id, card.id)
          .then(() => {
            const compareIndex = offerCardsForComparison.value.findIndex(
              el => el.id === card.id,
            );

            if (compareIndex >= 0) {
              offerCardsForComparison.value?.splice(compareIndex, 1);
            }
          });
      } else {
        await useCompareStore()
          .deleteCompare(compareData.value.id)
          .then(() => {
            createNewCompare();
          });
      }
    }
  }
};

const closeInfoModal = async () => {
  deleteCardDataModal.value = {
    show: false,
    card: undefined,
  };
};

const createNewCompare = () => {
  offerCardsForComparison.value = [];
  isNewCompare.value = true;
  compareData.value = undefined;
  useCompareStore().changeCurrentCompare(undefined, []);
  useCompareStore().changeCompareList([]);
  router.push({ query: undefined });
};

const route = useRoute();
const comparisonId = computed(() => {
  return route.query.id;
});

const getCompare = async () => {
  if (comparisonId.value) {
    isLoading.value = true;
    try {
      const res = await useCompareStore().getComparisonById(+comparisonId.value);

      if (res) {
        offerCardsForComparison.value = res.found.map(el => {
          return { ...el };
        });
        useCompareStore().changeCompareList(res.found);
        compareData.value = useCompareStore().compareData;
      }
    } finally {
      isLoading.value = false;
    }
  }
};

onMounted(async () => {
  if (comparisonId.value) {
    await getCompare();
  } else if (useCompareStore().compareData) {
    compareData.value = useCompareStore().compareData;
    offerCardsForComparison.value = useCompareStore().compareList;
    await router.push({ query: { id: String(compareData.value?.id) } });
  } else {
    offerCardsForComparison.value = useCompareStore().compareList;
    isNewCompare.value = true;
  }
});

watch(
  () => comparisonId.value,
  () => {
    if (comparisonId.value) {
      getCompare();
      isNewCompare.value = false;
    }
  },
);

watch(
  () => useCompareStore().compareData,
  async () => {
    await router.push({ query: { id: String(useCompareStore().compareData?.id) } });
  },
);
</script>

<style lang="scss" scoped src="./Compare.scss"></style>
