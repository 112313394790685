import request from '@/axios';
import { FAQFromBackType, SupportListFromBackType } from '@/api/support/types';

export const sendSupportApi = (
  data: FormData,
): Promise<IResponse<{ message: string }>> => {
  return request.post({ url: process.env.VUE_APP_API_SUPPORT_SEND, data });
};

export const getSupportListApi = (): Promise<
  IResponse<{ data: SupportListFromBackType }>
> => {
  return request.get({ url: process.env.VUE_APP_API_SUPPORT });
};

export const getFAQListApi = (): Promise<IResponse<FAQFromBackType>> => {
  return request.get({ url: process.env.VUE_APP_API_FAQ_LIST });
};
