<template>
  <base-card
    :class="{
      isFull,

      hideDescription: !developer.description,
    }"
    class="small-card"
    full-width-mobile
    without-mobile-shadow
  >
    <router-link :to="{ name: 'developer', params: { url: developer.url } }">
      <base-image
        :src="Array.isArray(developer.logo) ? developer.logo[0] : developer.logo"
        class="small-card__img"
        contain
        height="65"
        name="seller"
        version="v3"
        width="95"
      />
    </router-link>

    <div class="small-card__title m-font-size">{{ developer.title }}</div>
    <div class="small-card__progress items-in-column">
      <base-progress-line name="Проверка рисков" />
      <base-progress-line name="Проверка ЛПР" />
    </div>
    <div v-if="developer.id" class="small-card__btn-more center">
      <base-button
        :all-block-width="useAppStore().getWindowWidth() <= 600"
        :href="{ name: 'checkResult', query: { id: developer.id } }"
        height="36"
        text="Подробнее"
        width="118"
      />
    </div>
    <base-divider class="small-card__divider" />
    <div v-if="showDescriptionBlock" class="small-card__description counters">
      <div class="items-in-line flex-start">
        <base-image
          :src="require('@/assets/img/sk/icon-SK-01.png')"
          contain
          height="40"
          name="sk1"
          width="40"
        />
        <div class="xl-font-size medium">{{ developer.builtCount }}</div>
        <div>Объектов сдано</div>
      </div>
      <div class="items-in-line flex-start">
        <base-image
          :src="require('@/assets/img/sk/icon-SK-02.png')"
          contain
          height="40"
          name="sk2"
          width="40"
        />
        <div class="xl-font-size medium">{{ developer.buildingCount }}</div>
        <div>Объектов строятся</div>
      </div>
    </div>
    <div v-if="developer.description" class="small-card__description">
      {{ developer.description }}
    </div>
    <div
      :class="{
        hideDescription: !developer.description,
      }"
      class="center small-card__btn-link"
    >
      <base-button
        :all-block-width="useAppStore().getWindowWidth() <= 600"
        :href="{ name: 'developer', params: { url: developer.url } }"
        :text="
          useAppStore().getWindowWidth() <= 600
            ? 'О застройщике'
            : 'На страницу Застройщика'
        "
        height="36"
        style-type="coloredBorder"
        width="234"
      />
    </div>
  </base-card>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useAppStore } from '@/store/modules/app';

const props = withDefaults(
  defineProps<{
    isFull?: boolean;
    developer: {
      id: number;
      title: string;
      logo: string | string[];
      builtCount: string | undefined;
      buildingCount: string | undefined;
      description?: string;
      url: string;
    };
  }>(),
  {
    isFull: false,
  },
);

const showDescriptionBlock = computed(() => {
  return props.isFull && (props.developer.builtCount || props.developer.buildingCount);
});
</script>

<style lang="scss" scoped src="./DeveloperSmallCard.scss"></style>
