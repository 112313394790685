import { defineStore } from 'pinia';
import { store } from '@/store';
import { getClassifierByIdApi } from '@/api/classifier';
import { ClassifierType } from '@/api/classifier/types';
import { prepareUrl } from '@/utils/Common/Common';

type classificationState = {
  maxRate: number | undefined;
};

export const useClassificationStore = defineStore('classification', {
  state: (): classificationState => {
    return {
      maxRate: undefined,
    };
  },
  actions: {
    async searchClassifierById(cardId: number) {
      const res = await getClassifierByIdApi(cardId);

      const prepareClassifier = (classifier: ClassifierType) => {
        return {
          ...classifier,
          categories: classifier.categories.map(el => {
            return {
              ...el,
              values: el.values.map(value => {
                const complexWeight = +value.coefficient * el.weight;
                const getRate = () => {
                  if (value.used) {
                    if (complexWeight <= el.weight / 5) {
                      return 1;
                    }
                    if (complexWeight <= (el.weight / 5) * 2) {
                      return 2;
                    }
                    if (complexWeight <= (el.weight / 5) * 3) {
                      return 3;
                    }
                    if (complexWeight <= (el.weight / 5) * 4) {
                      return 4;
                    }
                    if (complexWeight <= (el.weight / 5) * 5) {
                      return 5;
                    }
                  }
                };
                const rate = getRate();

                return {
                  subcategories: {
                    value: value.title,
                  },
                  weight: {
                    value: value.used ? el.weight : '',
                  },
                  influence: {
                    value: value.coefficient,
                  },
                  used: {
                    value: value.used,
                  },
                  complexWeight: {
                    value: value.used ? complexWeight : '',
                    color: +value.coefficient < 0 ? '#EA1F1F' : '',
                  },
                  '1': { value: rate === 1 },
                  '2': { value: rate === 2 },
                  '3': { value: rate === 3 },
                  '4': { value: rate === 4 },
                  '5': { value: rate === 5 },
                };
              }),
            };
          }),
          maxRate: classifier?.categories.reduce(function (a, b) {
            return a + b.weight;
          }, 0),
        };
      };

      if (res) {
        return {
          ...res.data,
          classifier: prepareClassifier(res.data.classifier),
          logo: prepareUrl(res.data.logo) as string,
          isResidentialComplex: res.data.is_residential_complex,
        };
      }
    },
  },
  getters: {},
});

export const useClassificationStoreWithOut = () => {
  return useClassificationStore(store);
};
