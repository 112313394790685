<template>
  <div v-if="preparedComponentBtns.length">
    <base-menu-with-tabs
      :activeComponent="activeComponentBtnId"
      :componentBtns="preparedComponentBtns"
      bgColor="#fff"
      withRadius
      @changeActiveBtn="changeActiveBtn($event)"
    >
      <template #content="contentProps">
        <div class="problem-solution">
          <div v-if="getGAQById()?.length">
            <base-expansion-panels
              v-for="category in getGAQById()"
              :key="category.id"
              styleType="simple"
            >
              <template #title="titleProps">
                <div>{{ category.title }}</div>
              </template>
              <template #content="contentProps">
                <div v-if="category.video" class="problem-solution__video">
                  <div>
                    <base-video-link
                      :id="`supportvideo${category.id}`"
                      :you-tube="category.video"
                    />
                  </div>
                </div>

                {{ category.text }}
              </template>
            </base-expansion-panels>
          </div>
          <div v-else>Данные отсутствуют</div>
        </div>
      </template>
    </base-menu-with-tabs>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue';
import { BtnsForTabsMenuType } from '@/types';
import { useUserStore } from '@/store/modules/user';
import { FaqFromBackType, FaqListFromBackType } from '@/api/support/types';

const isLoading = ref(false);
const activeComponentBtnId: Ref<undefined | string | number> = ref(undefined);
const componentBtns: Ref<BtnsForTabsMenuType[]> = ref([]);
const faqList: Ref<FaqListFromBackType> = ref({});

const changeActiveBtn = (btnId: string | number) => {
  activeComponentBtnId.value = btnId;
};

const getGAQById = (): FaqFromBackType[] | undefined => {
  if (activeComponentBtnId.value) {
    return faqList.value[activeComponentBtnId.value];
  }

  return undefined;
};

const preparedComponentBtns = computed((): BtnsForTabsMenuType[] => {
  return componentBtns.value.filter(el => !el.hide);
});

onMounted(async () => {
  isLoading.value = true;
  const res = await useUserStore().getFAQList();

  if (res) {
    Object.entries(res.categories).forEach(el => {
      componentBtns.value.push({
        id: el[0],
        title: el[1],
        hide: !res.list[el[0]].length,
      });
    });
    faqList.value = res.list;
  }
  changeActiveBtn(componentBtns.value[0].id);
  isLoading.value = false;
});
</script>

<style lang="scss" scoped src="./ProblemSolution.scss"></style>
