<template>
  <div class="progress-bar center">
    <div class="progress-bar__wrap">
      <span class="progress-bar__wrap-line">
        <span class="blue" />
        <span class="green" />
        <span class="yellow" />
        <span class="orange" />
        <span class="darkOrange" />
      </span>
    </div>
    <span
      :style="{
        left: `calc(${rating > maxRate ? 100 : percentRating}% - 13px)`,
      }"
      class="progress-bar__rating"
    >
      <base-rating-number :maxRate="maxRate" :rating="rating" />
    </span>
    <span
      :class="[rating >= maxRate / 2 ? 'left' : 'right']"
      :style="
        rating >= maxRate / 2
          ? {
              width: `calc(${rating > maxRate ? 100 : percentRating}% - 25px)`,
            }
          : {
              left: `calc(${percentRating}% + 20px)`,
              width: `calc(${100 - percentRating}% - 40px)`,
            }
      "
      class="progress-bar__caption"
    >
    </span>
    <span
      :class="[rating >= maxRate / 2 ? 'left' : 'right']"
      class="progress-bar__caption-text"
    >
      {{ ratingText }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    rating: number;
    maxRate?: number;
    size?: string;
  }>(),
  {
    maxRate: 100,
  },
);

const percentRating = computed((): number => {
  return (props.rating / props.maxRate) * 100;
});
const ratingText = computed((): string => {
  if (props.rating <= props.maxRate / 5) {
    return 'Эконом';
  }
  if (props.rating <= (props.maxRate / 5) * 2) {
    return 'Комфорт';
  }
  if (props.rating <= (props.maxRate / 5) * 3) {
    return 'Бизнес';
  }
  if (props.rating <= (props.maxRate / 5) * 4) {
    return 'Премиум';
  }

  return 'Элит';
});
</script>

<style lang="scss" scoped src="./BaseProgressBar.scss"></style>
