<template>
  <div v-if="loading">
    <base-skeleton-loader type="input" />
  </div>
  <div
    v-else
    ref="container"
    :class="{ active: showMenu }"
    :style="cssProps"
    class="custom-select"
  >
    <base-tooltip :active="tooltipActive" :show-in-mobile="false" :text="label">
      <button
        ref="selectInput"
        :class="[classProps, { invalid: error?.length }]"
        :disabled="disabled"
        :style="[
          {
            height: height + 'px',
          },
        ]"
        class="custom-select__button"
        role="combobox"
        type="button"
        @click="openMenu"
      >
        <span :class="{ text: type === 'text' }" class="selected-container-value">
          <span v-if="selectedItem" :class="{ filled }" class="selected-value">
            <span
              :class="{ 'selected-value__multi-value': withBg }"
              class="items-in-line gap--5"
            >
              <span class="textHidden">
                {{ selectedItem[textKey] }}
              </span>
              <base-icon
                v-if="selectedItem.icon"
                :color="type === 'green' ? '#ffffff' : '#009D3E'"
                :name="selectedItem?.icon"
                font-size="16"
              />
            </span>
          </span>

          <span
            v-if="multiSelectedItem && multiSelectedItem.length"
            :class="{ filled }"
            class="selected-value"
          >
            <span v-for="item in multiSelectedItem" class="selected-value__multi-value">
              <span class="textHidden">{{ item[textKey] }}</span>
              <base-icon
                clickable
                color="#94857d"
                font-size="20"
                name="close"
                @click="deleteMultiSelectItem(item)"
              />
            </span>
          </span>
        </span>

        <span
          ref="selectLabel"
          :class="{
            active: selectedItem || multiSelectedItem?.length,
            topMore: multiSelectedItem?.length || withBg,
            filled,
            displayNone: !showLabel,
          }"
          class="selected-label"
          >{{ label }}</span
        >
        <base-icon
          v-if="clearable && selectedItem && !isMulti"
          class="clear-icon"
          clickable
          color="#94857d"
          height="18"
          name="close"
          width="18"
          @click="selectItem(undefined)"
        />

        <span v-else class="arrow">
          <base-icon
            v-if="arrow"
            :color="type === 'light' ? '#868298' : '#94857d'"
            :name="arrow"
            height="18"
            width="18"
          />
          <span v-else-if="type === 'text'" class="arrow-two">
            <span :style="[{ height: '10px' }]">
              <base-icon
                class="step-up"
                height="10"
                name="arrow_drop_up"
                tabindex="-1"
                width="10"
              />
            </span>
            <span :style="[{ height: '10px' }]">
              <base-icon
                class="step-down"
                height="10"
                name="arrow_drop_down"
                tabindex="-1"
                width="10"
              />
            </span>
          </span>
          <base-icon
            v-else
            :color="type === 'green' ? '#ffffff' : undefined"
            height="18"
            name="arrow_drop_down"
            width="18"
          />
        </span>
      </button>
    </base-tooltip>
    <Teleport to="body">
      <div
        ref="selectDropdown"
        :class="{ active: showMenu }"
        :style="{
          width: `${container?.getBoundingClientRect().width}px`,
          top: topPosition,
          left: leftPosition,
        }"
        class="select-dropdown"
      >
        <div v-if="options.length <= 0">
          <span class="third-color">Список пуст</span>
        </div>
        <div v-else>
          <base-input
            v-if="options?.length > searchCount && showSearch"
            v-model="searchText"
            :disabled="disabled"
            append-icon="search"
            auto-focus
            class="select-dropdown__search"
            label-in-value
            placeholder="Поиск"
          />
          <button v-if="showCheckAll" class="main-color mb--10" @click="checkAll">
            Выбрать все
          </button>
          <ul id="select-dropdown" :class="{ showInTop }">
            <li
              v-for="option in filteredOptions"
              :key="option[valueKey]"
              @click="selectItem(option)"
            >
              <input
                :id="option[valueKey]"
                :checked="
                  option[valueKey] === selectedItem?.id ||
                  isActiveMultiSelectedItem(option)
                "
                type="radio"
              />
              <label :class="{ isMulti }">
                <span>{{ option[textKey] }}</span>
                <base-icon
                  v-if="option.icon"
                  :color="option[valueKey] === selectedItem?.id ? '#009D3E' : 'black'"
                  :name="option.icon"
                  height="18"
                  width="18"
                />
                <base-checkbox
                  v-if="isMulti"
                  :model-value="isActiveMultiSelectedItem(option)"
                  @update:model-value="selectMultiItem(option)"
                />
              </label>
            </li>
          </ul>
        </div>
      </div>
    </Teleport>

    <span v-if="error?.length" class="select-error">{{ error }}</span>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, Ref, ref, toRaw, watch } from 'vue';
import { OptionsType, SelectInputTypesType, StylesType } from '@/types';

const props = withDefaults(
  defineProps<{
    label?: string;
    height?: string;
    width?: string;
    filled?: boolean;
    styles?: StylesType;
    options?: OptionsType[];
    type?: SelectInputTypesType;
    showLabel?: boolean;
    disabled?: boolean;
    arrow?: string;
    clearable?: boolean;
    loading?: boolean;
    textKey?: string;
    valueKey?: string;
    error?: string;
    isMulti?: boolean;
    searchCount?: number;
    showSearch?: boolean;
    showCheckAll?: boolean;
    withBg?: boolean;
  }>(),
  {
    height: '36',
    width: '100%',
    filled: false,
    options: () => [
      { id: 1, name: 'name 1' },
      { id: 2, name: 'name 2' },
    ],
    type: 'base',
    showLabel: true,
    textKey: 'name',
    valueKey: 'id',
    searchCount: 7,
    showSearch: true,
  },
);
const emit = defineEmits([
  'update:modelValue',
  'blur',
  'focus',
  'deleteMultiSelectItem',
  'selectMultiItem',
]);

// eslint-disable-next-line no-undef
const modelValue = defineModel<number | string | (number | string)[] | undefined>();

const showMenu = ref(false);
const showInTop = ref(false);
const selectedItem: Ref<OptionsType | undefined> = ref(undefined);
const multiSelectedItem: Ref<OptionsType[]> = ref([]);

const selectInput = ref<HTMLElement | undefined>();
const selectLabel = ref<HTMLElement | undefined>();
const container = ref<HTMLDivElement | null>(null);
const selectDropdown = ref<HTMLDivElement | null>(null);
const tooltipActive = ref(false);
const openMenu = () => {
  if (selectInput.value) {
    const bottomDistance =
      window.innerHeight -
      selectInput.value.getBoundingClientRect().top -
      selectInput.value.getBoundingClientRect().height;

    showInTop.value = bottomDistance <= 200;
  }
  showMenu.value = !showMenu.value;
  calculateMenuPosition();
  emit('focus');
};

const isActiveMultiSelectedItem = (option: OptionsType | undefined): boolean => {
  return option ? multiSelectedItem.value.map(el => el.id).includes(option.id) : false;
};

const deleteMultiSelectItem = (option: OptionsType) => {
  multiSelectedItem.value = multiSelectedItem.value.filter(el => el.id !== option.id);
  if (multiSelectedItem.value.length) {
    modelValue.value = multiSelectedItem.value.map(el => el.id);
  } else {
    modelValue.value = undefined;
  }
  emit('deleteMultiSelectItem', option);
};

const checkAll = () => {
  props.options.forEach(option => {
    if (!isActiveMultiSelectedItem(option)) {
      multiSelectedItem.value.push(option);
      modelValue.value = multiSelectedItem.value.map(el => el.id);
      emit('selectMultiItem', option);
    }
  });
};
const selectMultiItem = (option: OptionsType | undefined) => {
  if (!props.isMulti) {
    return;
  }
  if (option) {
    if (isActiveMultiSelectedItem(option)) {
      deleteMultiSelectItem(option);
    } else {
      multiSelectedItem.value.push(option);
      modelValue.value = multiSelectedItem.value.map(el => el.id);

      emit('selectMultiItem', option);
    }
  } else {
    multiSelectedItem.value = [];

    modelValue.value = undefined;
  }
};
const selectItem = (option: OptionsType | undefined) => {
  if (props.isMulti) {
    return;
  }
  if (option) {
    selectedItem.value = {
      id: option[props.valueKey as keyof typeof option] as number,
      name: option[props.textKey as keyof typeof option] as string,
      icon: option.icon,
    };
    modelValue.value = selectedItem.value?.id;
  } else {
    selectedItem.value = undefined;

    modelValue.value = undefined;
  }
  showMenu.value = false;
};

const cssProps = computed(() => {
  return {
    '--border': props.styles?.border,
    '--background-color': props.styles?.backgroundColor,
    '--width-select-input': props.width === '100%' ? props.width : props.width + 'px',
  };
});
const classProps = computed((): string => {
  let classForInput: string;

  switch (props.type) {
    case 'mainColorBorder':
      classForInput = 'main-border-color';
      break;
    case 'light':
      classForInput = 'light';
      break;
    case 'text':
      classForInput = 'text';
      break;
    case 'green':
      classForInput = 'green';
      break;
    default:
      classForInput = 'base';
  }

  return classForInput;
});

const searchText = ref('');
const filteredOptions = computed(() => {
  const searchOptions = (val: string) => {
    return val.toLocaleLowerCase().includes(searchText.value.toLocaleLowerCase());
  };

  return props.options.filter(opt => {
    return searchOptions(opt[props.textKey as keyof OptionsType] as string);
  });
});

const topPosition = ref('0px');
const leftPosition = ref('0px');
const calculateMenuPosition = () => {
  if (container.value) {
    const rect = container.value.getBoundingClientRect();
    const scrollTop = window.scrollY ? window.scrollY : document.body.scrollTop;

    leftPosition.value = `${rect.x}px`;
    topPosition.value = `${rect.y + rect.height + scrollTop}px`;

    return;
  }

  topPosition.value = '100%';
  leftPosition.value = '0px';
};
const handleClickOutside = (event: MouseEvent) => {
  if (
    container.value &&
    !container.value.contains(event.target as Node) &&
    selectDropdown.value &&
    !selectDropdown.value.contains(event.target as Node)
  ) {
    showMenu.value = false;
    searchText.value = '';
  }
};

const updateSelectedItem = () => {
  if (props.isMulti) {
    if (modelValue.value) {
      (modelValue.value as number[]).forEach(value => {
        let foundValue = props.options.find(
          el => el[props.valueKey as keyof typeof el] === value,
        );

        if (
          foundValue &&
          !toRaw(multiSelectedItem.value).find(el => el.id === foundValue?.id)
        ) {
          multiSelectedItem.value.push(foundValue);
        }
      });
    } else {
      multiSelectedItem.value = [];
    }
  } else {
    selectedItem.value = props.options.find(
      el => el[props.valueKey as keyof typeof el] === modelValue.value,
    );
  }
  if (!props.loading) {
    calculateMenuPosition();
  }
};

onMounted(() => {
  if (selectLabel.value) {
    if (selectLabel.value.clientWidth < selectLabel.value.scrollWidth) {
      tooltipActive.value = true;
    }
  }

  document.addEventListener('click', handleClickOutside);
  updateSelectedItem();
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
watch(
  () => modelValue.value,
  () => {
    updateSelectedItem();
  },
);
watch(
  () => props.loading,
  () => {
    if (!props.loading) {
      calculateMenuPosition();
    }
  },
);
</script>

<style lang="scss" scoped src="./BaseSelectInput.scss"></style>
