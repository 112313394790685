<template>
  <base-select-input
    v-model="developerId"
    :disabled="isLoading"
    :options="listDevelopersOptions"
    label="Выберите СК"
    @update:modelValue="functionWithUpdate(updateResidentialComplex, $event)"
  />
  <base-select-input
    v-model="residentialComplexId"
    :disabled="isLoading"
    :options="listResidentialComplexOptions"
    label="Выберите ЖК"
    @update:modelValue="functionWithUpdate(updateConstructionLines, $event)"
  />
  <base-select-input
    v-if="useUserStore().user?.is_construction_company"
    v-model="constructionLineId"
    :disabled="
      isLoading || (developerId !== undefined && residentialComplexId === undefined)
    "
    :options="listConstructionLinesOptions"
    label="Выберите очередь"
    @update:modelValue="
      functionWithUpdate(() => {
        constructionLineId = $event;
      })
    "
  />
</template>
<script lang="ts" setup>
import { useUserStore } from '@/store/modules/user';
import { onMounted, ref, Ref } from 'vue';
import { OptionsType } from '@/types';
import { useDevelopersStore } from '@/store/modules/developers';
import { LegalEntitiesType } from '@/api/developer/types';

// eslint-disable-next-line no-undef
const developerId = defineModel<number | undefined>('developerId');
// eslint-disable-next-line no-undef
const residentialComplexId = defineModel<number | undefined>('residentialComplexId');
// eslint-disable-next-line no-undef
const constructionLineId = defineModel<number | undefined>('constructionLineId');
// eslint-disable-next-line no-undef
const categoryId = defineModel<number | undefined>('categoryId');

const emit = defineEmits(['updateValues']);

const listDevelopersOptions: Ref<OptionsType[]> = ref([]);
const listResidentialComplexOptions: Ref<OptionsType[]> = ref([]);
const listConstructionLinesOptions: Ref<OptionsType[]> = ref([]);

const oldDeveloperId: Ref<number | undefined> = ref(undefined);
const oldResidentialComplexId: Ref<number | undefined> = ref(undefined);
const oldConstructionLineId: Ref<number | undefined> = ref(undefined);
const isLoading: Ref<boolean> = ref(false);

const prepareList = (res: LegalEntitiesType | undefined) => {
  categoryId.value = res.childCategory;

  if (res) {
    return res.list?.map(el => {
      return {
        id: el.id,
        name: el.value,
      };
    });
  } else {
    return [];
  }
};

const functionWithUpdate = async (action?: (id?: number) => void, id?: number) => {
  if (action) {
    await action(id);
  }

  if (
    oldDeveloperId.value !== developerId.value ||
    oldResidentialComplexId.value !== residentialComplexId.value ||
    oldConstructionLineId.value !== constructionLineId.value
  ) {
    emit('updateValues');
    oldDeveloperId.value = developerId.value;
    oldResidentialComplexId.value = residentialComplexId.value;
    oldConstructionLineId.value = constructionLineId.value;
  }
};

const updateDevelopers = async (id?: number) => {
  isLoading.value = true;
  await useDevelopersStore()
    .getDevelopers(id)
    .then(res => {
      listDevelopersOptions.value = prepareList(res);

      if (!useUserStore().user?.is_construction_company) {
        listDevelopersOptions.value.unshift({
          id: 0,
          name: 'Нет моего СК',
        });
      }
      if (!listDevelopersOptions.value?.find(el => el.id === developerId.value)) {
        if (developerId.value !== undefined) {
          developerId.value = undefined;
        }
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
};
const updateConstructionLines = async (id?: number) => {
  isLoading.value = true;
  await useDevelopersStore()
    .getConstructionLines(id)
    .then(res => {
      listConstructionLinesOptions.value = prepareList(res);
      if (
        !listConstructionLinesOptions.value.find(el => el.id === constructionLineId.value)
      ) {
        if (constructionLineId.value !== undefined) {
          constructionLineId.value = undefined;
        }
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
};
const updateResidentialComplex = async (id?: number) => {
  if (id !== 0) {
    isLoading.value = true;
    await useDevelopersStore()
      .getCategoryResidentialComplexes(id)
      .then(res => {
        listResidentialComplexOptions.value = prepareList(res);
        if (!useUserStore().user?.is_construction_company) {
          listResidentialComplexOptions.value.unshift({
            id: 0,
            name: 'Нет моего ЖК',
          });
        }
        if (
          !listResidentialComplexOptions.value.find(
            el => el.id === residentialComplexId.value,
          )
        ) {
          if (residentialComplexId.value !== undefined) {
            residentialComplexId.value = undefined;
            if (constructionLineId.value !== undefined) {
              constructionLineId.value = undefined;
            }
          }
        }
      })
      .finally(() => {
        isLoading.value = false;
      });
  }
};

onMounted(async () => {
  await updateDevelopers();
  await updateResidentialComplex();

  if (useUserStore().user?.is_construction_company) {
    await updateConstructionLines();
  }
});
</script>

<style lang="scss" scoped></style>
