<template>
  <div v-if="isLoading" class="center my-cards">
    <base-card class="my-cards__container" full-width-mobile>
      <div class="mb--22 my-cards__container-actions">
        <base-skeleton-loader
          :key="2"
          :styles="{ width: '150px', height: '34px' }"
          type="text"
        />
      </div>
      <div class="my-cards__cards">
        <div v-for="i in 3" :key="i" class="my-cards__cards-item">
          <LoadingMediumDeveloperCard />
        </div>
      </div>
      <div></div>
    </base-card>
  </div>
  <div v-else class="my-cards">
    <base-card class="my-cards__container" full-width-mobile>
      <div class="mb--22 my-cards__container-actions">
        <button
          class="gradient-color"
          @click="
            showCreateCategoryModal({ categoryTitle: 'Застройщик', categoryId: '1' })
          "
        >
          Создать СК
        </button>
      </div>
      <div class="my-cards__cards">
        <div
          v-for="developer in developerCards"
          :key="developer.id"
          class="my-cards__cards-item"
        >
          <MediumDeveloperCard :developer="developer" :with-link="false" />
          <div v-if="!developer.url" class="my-cards__cards-disabled-text">
            Для активации объекта "{{ developer.name }}", добавьте бренд
            <div class="items-in-line">
              <base-button
                :href="{ name: 'updateDeveloper', query: { id: developer.id } }"
                height="36"
                style-type="white"
                text="Редактировать"
              />
              <base-button
                height="36"
                icon
                style-type="white"
                width="36"
                @click="showDeleteCategoryModal(developer.id)"
              >
                <template #icon>
                  <base-icon name="delete" />
                </template>
                <template #iconActive>
                  <base-icon color="#009D3E" name="delete" />
                </template>
              </base-button>
            </div>
          </div>
          <div v-else class="my-cards__cards-actions-btn items-in-line gap--5">
            <base-button
              :href="{ name: 'updateDeveloper', query: { id: developer.id } }"
              icon
              style-type="border"
            >
              <template #icon>
                <base-icon name="edit" />
              </template>
              <template #iconActive>
                <base-icon color="#009D3E" name="edit" />
              </template>
            </base-button>
            <base-button
              icon
              style-type="border"
              @click="showDeleteCategoryModal(developer.id)"
            >
              <template #icon>
                <base-icon name="delete" />
              </template>
              <template #iconActive>
                <base-icon color="#009D3E" name="delete" />
              </template>
            </base-button>
          </div>
        </div>
      </div>
      <div></div>
    </base-card>
  </div>
  <CreateDeveloperModal
    :create-category-modal-data="createCategoryModalData"
    @close="closeCreateCategoryModal"
    @create-category="createCategory($event)"
  />
  <DeleteDeveloperModal
    :delete-category-modal-data="deleteCategoryModalData"
    @close="closeDeleteCategoryModal"
    @delete-category="deleteCategory"
  />
</template>

<script lang="ts" setup>
import { onMounted, ref, Ref } from 'vue';
import { useDevelopersStore } from '@/store/modules/developers';
import {
  CreateDeveloperModal,
  DeleteDeveloperModal,
} from '@/components/ProfileComponents';
import {
  CreateCategoryModalType,
  DeleteCategoryModalType,
  SmallDeveloperCardType,
} from '@/types';
import {
  LoadingMediumDeveloperCard,
  MediumDeveloperCard,
} from '@/components/Cards/MediumCards';

const isLoading: Ref<boolean> = ref(false);
const developerCards: Ref<SmallDeveloperCardType[]> = ref([]);
const createCategoryModalData: Ref<CreateCategoryModalType> = ref({
  show: false,
  categoryTitle: undefined,
  categoryId: undefined,
  parentId: undefined,
});

const deleteCategoryModalData: Ref<DeleteCategoryModalType> = ref({
  show: false,
  id: undefined,
});

const showCreateCategoryModal = (event: {
  categoryTitle: string;
  categoryId: string;
  parentId?: number;
}) => {
  createCategoryModalData.value = {
    show: true,
    categoryTitle: event.categoryTitle,
    categoryId: event.categoryId,
    parentId: event.parentId,
  };
};

const closeCreateCategoryModal = () => {
  createCategoryModalData.value = {
    show: false,
    categoryTitle: undefined,
    parentId: undefined,
    categoryId: undefined,
  };
};
const createCategory = async (event: { id: number; title: string }) => {
  developerCards.value = [
    {
      id: event.id,
      name: event.title,
      title: undefined,
      logo: undefined,
      builtCount: undefined,
      buildingCount: undefined,
      documents: undefined,
      yearOfFoundation: undefined,
      url: null,
    },
    ...developerCards.value,
  ];
};

const showDeleteCategoryModal = (id: number) => {
  deleteCategoryModalData.value = {
    show: true,
    id: id,
  };
};

const closeDeleteCategoryModal = () => {
  deleteCategoryModalData.value = {
    show: false,
    id: undefined,
  };
};

const deleteCategory = async (id: number) => {
  developerCards.value = developerCards.value.filter(el => el.id !== id);
};

onMounted(async () => {
  try {
    isLoading.value = true;
    const developers = await useDevelopersStore().getCategoryValueList();

    if (developers) {
      developerCards.value = developers;
    }
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped src="./MyDeveloper.scss"></style>
