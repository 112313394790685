<template>
  <div v-if="isLoading" class="container center">
    <base-spinner :size="50" color="#009D3E" />
  </div>
  <div v-else>
    <base-card v-if="preparedBtns.length" class="search-history" fullWidthMobile>
      <base-button-group
        v-if="preparedBtns.length > 1"
        :active="activeBtnId"
        :buttons="preparedBtns"
        btnHeight="34"
        btnWidth="100"
        class="mb--20"
        text-key="title"
        type="border"
        @change-active-btn="activeBtnId = $event.id"
      />
      <div class="search-history__table">
        <base-table
          :emits="['showCompare']"
          :headers="headers"
          :items="searchHistoryData[activeBtnId].values"
          :style="'simple'"
        />
      </div>
    </base-card>
    <base-card v-else class="search-history items-in-column">
      <div class="items-in-column tertiary-dop-color">
        <span class="l-font-size medium">Здесь хранятся сохраненные поиски</span>
        <span> Переходите к поиску недвижимости, чтобы увидеть сохраненные поиски</span>
      </div>
      <div class="mt--20">
        <base-button :href="{ name: 'main' }" height="40" text="Перейти к поиску" />
      </div>
    </base-card>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue';
import { HeaderType, ItemType } from '@/types/TableTypes';
import { useUserStore } from '@/store/modules/user';
import { useAppStore } from '@/store/modules/app';
import qs from 'qs';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { useRoute, useRouter } from 'vue-router';

type ActiveBtnIdTypes = 265 | 266 | 267;
type SearchHistoryDataType = {
  [key: number]: {
    id: ActiveBtnIdTypes;
    title: string;
    values: ItemType[];
  };
};
const searchHistoryData: Ref<SearchHistoryDataType> = ref({
  265: {
    id: 265,
    title: 'Готовый',
    values: [],
  },
  266: {
    id: 266,
    title: 'Новостройки',
    values: [],
  },
  267: {
    id: 267,
    title: 'Земля',
    values: [],
  },
});
const activeBtnId: Ref<ActiveBtnIdTypes> = ref(265);

const preparedBtns = computed(() => {
  return Object.values(searchHistoryData.value).filter(data => {
    return !!data.values.length;
  });
});

const headers = computed((): HeaderType[] => {
  if (activeBtnId.value === 267) {
    return [
      {
        text: 'Создан',
        value: 'createDate',
        align: 'left',
      },
      {
        text: 'Тип земельного участка',
        value: 'type',
        align: 'left',
        alignValue: 'left',
      },
      {
        text: 'Цена, руб',
        value: 'price',
        align: 'left',
        alignValue: 'left',
      },
      {
        text: 'Площадь, сот',
        value: 'area',
        align: 'left',
        alignValue: 'left',
      },
      {
        text: '',
        value: 'eye',
        align: 'center',
        click: showSearch,
      },
    ];
  }

  return [
    {
      text: 'Создан',
      value: 'createDate',
      align: 'left',
    },
    {
      text: 'Тип недвижимости',
      value: 'type',
      align: 'left',
      alignValue: 'left',
    },
    {
      text: 'Цена, руб',
      value: 'price',
      align: 'left',
      alignValue: 'left',
    },
    {
      text: 'Площадь, м²',
      value: 'area',
      align: 'left',
      alignValue: 'left',
    },
    {
      text: '',
      value: 'eye',
      align: 'center',
      click: showSearch,
    },
  ];
});
const values: Ref<ItemType[]> = ref([]);
const isLoading: Ref<boolean> = ref(false);
const route = useRoute();
const router = useRouter();
const showSearch = async (item: ItemType) => {
  useOfferCardsStore().returnToInitialState();
  const str = qs.stringify(item['params'].value, {
    arrayFormat: 'brackets',
    skipNulls: true,
    encodeValuesOnly: true,
  });

  await router.push(`/?${str}`);
};

onMounted(async () => {
  isLoading.value = true;

  try {
    const searchHistoryList = await useUserStore().getSearchHistory();

    if (searchHistoryList) {
      searchHistoryList.forEach(el => {
        const params = JSON.parse(el.parameters);

        const getOptionValue = (id: number) => {
          return (
            useAppStore().optionsConst[id].find(el => el.id === +params[id].value)
              ?.name || ''
          );
        };

        const getTextValue = (id: number): string => {
          if (params[id].min && params[id].max) {
            return `${params[id].min.toLocaleString()} - ${params[id].max.toLocaleString()}`;
          }
          if (params[id].min) {
            return `от ${params[id].min.toLocaleString()}`;
          }
          if (params[id].max) {
            return `до ${params[id].max.toLocaleString()}`;
          }

          return '';
        };

        const groupId = +params[306]?.value;

        if (groupId) {
          searchHistoryData.value[groupId].values.unshift({
            id: { value: el.id },
            createDate: { value: new Date(el.created_at).toLocaleString() },
            price: {
              value: getTextValue(groupId === 267 ? 365 : 203),
            },
            area: { value: getTextValue(groupId === 267 ? 116 : 199) },
            type: { value: getOptionValue(groupId === 267 ? 113 : 202) },
            eye: { value: '', icon: 'visibility' },
            params: { value: params },
          });
        }
      });
    }
    activeBtnId.value = preparedBtns.value[0]?.id;
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped src="./SearchHistoryList.scss"></style>
