<template>
  <base-modal
    :hide-title="!!sendMessage"
    :show-modal="showModal"
    :show-padding="false"
    close-outside
    title="Пожаловаться"
    @close="closeModal"
  >
    <template #content>
      <div v-if="sendMessage" class="mt--20 mb--20 ml--20 mr--20 m-font-size">
        {{ sendMessage }}
      </div>
      <div v-else-if="showDescriptionForm" class="complain-modal__form">
        <base-form>
          <base-textarea
            v-model="formData.message"
            :error="errors.message"
            :invalid="!!errors.message"
            height="85"
            label="Комментарий"
            textarea
            @blur="validationErrorsHandlerByKey('message')"
            @focus="delete errors.message"
            @input="v$.message?.$touch"
          />
        </base-form>
      </div>
      <div v-else class="items-in-column gap--0 complain-modal__reasons">
        <div
          v-for="reason in Object.entries(complainReasons)"
          class="complain-modal__reasons-item"
        >
          <button class="complain-modal__reasons-btn" @click="chooseReason(reason[0])">
            {{ reason[1] }}
          </button>
        </div>
      </div>
    </template>
    <template #btn>
      <div class="pb--17">
        <base-button
          v-if="showDescriptionForm && !sendMessage"
          :loading="loading"
          height="40"
          text="Отправить"
          width="150"
          @click="formSubmit"
        />
        <base-button v-else height="40" text="Закрыть" width="150" @click="closeModal" />
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { computed, Ref, ref } from 'vue';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ErrorsType } from '@/types';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/utils/Common/Common';
import { useAppStore } from '@/store/modules/app';
import { useOfferCardsStore } from '@/store/modules/offerCards';

const props = defineProps<{
  showModal?: boolean;
  entityId: number;
}>();
const emit = defineEmits(['close']);

const complainReasons = computed(() => {
  return useAppStore().complaint_types;
});

type FormDataType = {
  message: string | undefined;
};

const formData: Ref<FormDataType> = ref({
  message: undefined,
});

const clearFormData = () => {
  v$.value.$reset();
  formData.value.message = undefined;
};
const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  message: { required },
};
const v$ = useVuelidate<FormDataType>(rules, formData);
const errors: Ref<ErrorsType> = ref({});
const loading: Ref<boolean> = ref(false);
const complainType: Ref<number | undefined> = ref(undefined);
const showDescriptionForm = ref(false);
const sendMessage: Ref<string | undefined> = ref(undefined);

const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};
const formSubmit = async () => {
  if (v$.value.$invalid || !complainType.value) {
    v$.value?.$touch();
    validationErrorsHandler();

    return;
  }

  loading.value = true;
  const preparedFormData = {
    entity_id: props.entityId,
    type: complainType.value,
    message: formData.value.message,
  };

  try {
    const res = await useOfferCardsStore().sendComplain(preparedFormData);

    if (res) {
      clearFormData();
      sendMessage.value = 'Жалоба успешно отправлена';
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error);
  } finally {
    loading.value = false;
  }
};

const chooseReason = (reasonId: string) => {
  complainType.value = +reasonId;
  showDescriptionForm.value = true;
};

const closeModal = () => {
  showDescriptionForm.value = false;
  sendMessage.value = undefined;
  clearFormData();
  emit('close');
};
</script>

<style lang="scss" scoped src="./ComplainModal.scss"></style>
