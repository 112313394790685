<template>
  <base-card class="call-order" full-width-mobile without-mobile-shadow>
    <div v-if="isCallOrdered" class="align-center">
      <div class="l-font-size mt--10 mb--15">Спасибо!</div>
      <div class="m-font-size">
        Ваша заявка отправлена продавцу и
        <span class="medium"
          >в ближайшее время он свяжется с вами по телефону
          <span class="no-wrap">+7 {{ formData.phone }}</span></span
        >
      </div>
    </div>
    <div v-else>
      <div class="m-font-size mt--10 mb--15">Закажите бесплатный звонок</div>
      <base-form class="grid--1 gap--15">
        <base-input
          v-model="formData.phone"
          v-maska
          :error="errors.phone"
          :invalid="!!errors.phone"
          data-maska="(###)###-##-##"
          height="34"
          label="Телефон"
          placeholder="(999)000-99-99"
          prefix="+7 "
          @blur="validationErrorsHandlerByKey('phone')"
          @focus="delete errors.phone"
          @input="v$.phone.$touch"
        />
        <base-textarea
          v-model="formData.text"
          :error="errors.text"
          :invalid="!!errors.text"
          :styleType="'light'"
          height="34"
          label="Уточните запрос, если хотите"
          textarea
          @blur="validationErrorsHandlerByKey('text')"
          @focus="delete errors.text"
          @input="v$.text.$touch"
        />
        <div class="call-order__bottom-box">
          <div class="center">
            <span class="second-color xs-font-size align-center call-order__info"
              >Нажимая кнопку, вы даете свое согласие на обработку
              <span class="underline">персональных данных</span></span
            >
          </div>

          <div class="center">
            <base-button
              :height="useAppStore().getWindowWidth() < 600 ? 34 : 46"
              :loading="isLoading"
              text="Заказать звонок"
              @click="formSubmit"
            />
          </div>
        </div>
      </base-form>
    </div>
  </base-card>
</template>
<script lang="ts" setup>
import { Ref, ref } from 'vue';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/utils/Common/Common';
import { ErrorsType } from '@/types';
import { vMaska } from 'maska';
import { useAppStore } from '@/store/modules/app';
import { FreeCallDataType } from '@/api/common/types';

type FormDataType = {
  phone: string | undefined;
  text: string | undefined;
};

const props = withDefaults(
  defineProps<{
    cardId: number;
  }>(),
  {},
);
const formData: Ref<FormDataType> = ref({
  phone: undefined,
  text: undefined,
});
const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  phone: { required },
  text: {},
};
const v$ = useVuelidate<FormDataType>(rules, formData);

const errors: Ref<ErrorsType> = ref({});

const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};
const isLoading = ref(false);
const isCallOrdered = ref(false);
const formSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
    validationErrorsHandler();

    return;
  }

  if (formData.value.phone) {
    isLoading.value = true;

    const preparedData: FreeCallDataType = {
      ...formData.value,
      text: formData.value.text,
      phone: formData.value.phone?.replace(/[^\d]/g, ''),
      offer_card_id: props.cardId,
    };

    try {
      const res = await useAppStore().orderFreeCall(preparedData);

      if (res) {
        isCallOrdered.value = true;
      }
    } catch (error) {
      errors.value = validationErrorsByRequest(error);
    } finally {
      isLoading.value = false;
    }
  }
};
</script>

<style lang="scss" scoped src="./CallOrderCard.scss"></style>
