<template>
  <base-menu-with-tabs
    :activeComponent="activeComponentBtnId"
    :componentBtns="componentBtns"
    bgColor="#fff"
    withRadius
    @changeActiveBtn="changeActiveBtn($event)"
  >
    <template #content="contentProps">
      <div
        v-if="getActiveComponent(contentProps.data)"
        :class="{ services__mortgage: contentProps.data === 'mortgage' }"
        class="menuWithTabsContent"
      >
        <component :is="getActiveComponent(contentProps.data)" :key="contentProps.data" />
      </div>
    </template>
  </base-menu-with-tabs>
</template>

<script lang="ts" setup>
import { BtnsForTabsMenuType } from '@/types';
import { markRaw, onMounted, ref, Ref } from 'vue';
import { MortgageWidget, ServicesAndChecksWidget } from '@/components';
import { useRoute, useRouter } from 'vue-router';

const componentBtns: BtnsForTabsMenuType[] = [
  {
    id: 'checks',
    title: 'Сервисы и проверки',
    component: markRaw(ServicesAndChecksWidget),
  },
  { id: 'mortgage', title: 'Ипотечный калькулятор', component: markRaw(MortgageWidget) },
];

const getActiveComponent = (btnId: string | number) => {
  return componentBtns.find(el => el.id === btnId)?.component;
};

const activeComponentBtnId: Ref<undefined | number | string> = ref(undefined);

const router = useRouter();
const changeActiveBtn = (btnId: number | string) => {
  activeComponentBtnId.value = btnId;
  router.push({ query: { menuItem: btnId } });
};

onMounted(() => {
  const activeBtnId = useRoute().query.menuItem;

  if (activeBtnId && !!componentBtns.find(el => el.id === activeBtnId)) {
    changeActiveBtn(`${activeBtnId}`);
  } else {
    changeActiveBtn(componentBtns[0].id);
  }
});
</script>

<style lang="scss" scoped src="./Services.scss"></style>
