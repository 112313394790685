<template>
  <div class="container content not-found items-in-column">
    <div class="not-found__404 medium third-color">404</div>
    <div class="xl-font-size-title-block medium third-color align-center">
      Страница не найдена!
    </div>
    <div class="mb--20 allBlockWidth">
      <OfferCardsBlock
        v-if="topOfferCards.length || isLoading"
        :isLoading="isLoading"
        :resultCards="topOfferCards"
        class="mt--0"
        isTop
        title="Популярные объявления"
        title-with-line
      />
    </div>
    <div class="align-center">
      Возможно, у вас опечатка в адресе страницы, или её просто не существует :(
    </div>
    <base-button :href="{ name: 'main' }" text="На главную" />
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import { useAppStore } from '@/store/modules/app';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { OfferCardsBlock } from '@/components/Cards';
import { ResultCardType } from '@/types';

const isLoading = ref(true);

const route = useRoute();
const topOfferCards = computed((): ResultCardType[] => {
  return useOfferCardsStore().topOfferCards;
});

onMounted(async () => {
  try {
    isLoading.value = true;
    await useOfferCardsStore().getTopOfferCards();
  } finally {
    isLoading.value = false;
  }
});
watch(
  () => route.name,
  () => {
    useAppStore().togglePageNotFound(false);
  },
);
</script>

<style lang="scss" scoped>
@import '@/assets/styles/main';

.not-found {
  margin-top: 20px;
  align-items: center;

  &__404 {
    font-size: 200px;
    line-height: 0.8;

    @include media('max', 'lg') {
      font-size: 150px;
    }
    @include media('max', 'sm') {
      font-size: 100px;
    }
  }
}
</style>
