<template>
  <base-modal
    :fullScreen="useAppStore().getWindowWidth() < 600"
    :show-modal="showModal"
    close-outside
    title="Геоданные"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        {{ `Адрес: ${address ?? '-'}` }}
      </div>
      <div v-if="currentCoordinates">
        {{
          `Долгота: ${(+currentCoordinates[0]).toFixed(6)} Широта: ${(+currentCoordinates[1]).toFixed(9)}`
        }}
      </div>
      <div class="map-modal__map-block">
        <yandex-map
          :settings="{
            location: {
              center: point && point[0] !== 0 ? point : [39.720353, 47.22208],
              zoom: 15,
            },
          }"
          height="100%"
          width="100%"
        >
          <yandex-map-default-scheme-layer />
          <yandex-map-default-features-layer />
          <yandex-map-marker
            v-model="defaultMarker"
            :settings="{
              coordinates: defaultMarker ? defaultMarker.coordinates : currentCoordinates,
              draggable: true,
              onDragMove,
              onDragEnd,
            }"
          >
            <div class="pin">
              <base-image
                :src="require('@/assets/img/banner/icon-for-map.svg')"
                contain
                name="map"
              />
            </div>
          </yandex-map-marker>
        </yandex-map>
      </div>
    </template>
    <template #btn>
      <base-button height="40" text="Применить" @click="saveChanges" />
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import {
  YandexMap,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapMarker,
} from 'vue-yandex-maps';
import { computed, ref, Ref, shallowRef, triggerRef, watch } from 'vue';
import type { YMapDefaultMarker } from '@yandex/ymaps3-types/packages/markers';
import type { LngLat } from '@yandex/ymaps3-types';
import { getAddressByGeolocate } from '@/api/ymaps';
import { useAppStore } from '@/store/modules/app';

const defaultMarker = shallowRef<YMapDefaultMarker | null>(null);
const address: Ref<string> = ref('');

const currentCoordinates = computed(() => {
  const point = defaultMarker.value ? defaultMarker.value.coordinates : props.point;

  if (point[0] === 0 && point[1] === 0) {
    return [39.720353, 47.22208];
  }

  return point;
});
const onDragMove = () => {
  triggerRef(defaultMarker);
};
const onDragEnd = () => {
  if (defaultMarker.value) {
    getAddressByGeolocate({
      lat: +currentCoordinates.value[1],
      lon: +currentCoordinates.value[0],
    }).then(res => {
      address.value = res.data.suggestions[0].value;
    });
  }
};

const props = defineProps<{
  showModal?: boolean;
  point?: LngLat;
  addressValue?: string;
}>();
const emit = defineEmits(['close', 'changePoint']);
const saveChanges = () => {
  emit('changePoint', {
    point: [
      (+currentCoordinates.value[0]).toFixed(6),
      (+currentCoordinates.value[1]).toFixed(6),
    ],
    address: address.value,
  });
  emit('close');
};

watch(
  () => props.addressValue,
  () => {
    address.value = props.addressValue;
  },
);
</script>

<style lang="scss" scoped>
.map-modal__map-block {
  width: min(calc(100vw - 40px), 900px);
  height: min(95vh, 500px);
}
</style>
