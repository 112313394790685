<template>
  <base-card class="developer-project-card">
    <component
      :is="project.url && project.public ? 'router-link' : 'button'"
      :to="{ name: 'residentialComplex', params: { url: project.url } }"
      target="_blank"
      @click="goToRCHandler"
    >
      <base-image
        :name="project.title"
        :src="project.logo"
        :title="project.title"
        :version="version"
        border-radius
        contain
        height="150"
        width="100%"
      />
    </component>
    <div class="developer-project-card__box">
      <div class="developer-project-card__title-box">
        <div>
          <base-tooltip :active="tooltipActive" :text="project.title">
            <div class="developer-project-card__title">
              {{ title }}
            </div>
          </base-tooltip>
        </div>
        <router-link
          :to="{ name: 'classification', query: { id: project.id } }"
          target="_blank"
        >
          <base-rating-number :rating="project.coefficient" />
        </router-link>
      </div>
      <div class="developer-project-card__content">
        <div class="developer-project-card__content-info">
          <div>
            <div class="third-color">Адрес:</div>
            <div>
              <div ref="addressEl" class="developer-project-card__content-address">
                {{ props.project.address }}
              </div>
              <button v-if="isOverflowed" class="main-color" @click="showAddress">
                Далее
              </button>
            </div>
          </div>
          <div>
            <div class="third-color">Застройщик:</div>
            <div>
              {{ project.developerTitle || '-' }}
            </div>
          </div>
          <div>
            <component
              :is="project.url && project.public ? 'router-link' : 'button'"
              :to="{
                name: 'residentialComplex',
                params: { url: project.url },
                hash: '#ads',
              }"
              @click="goToRCHandler"
            >
              <div class="underline main-color">Витрина объектов</div>
            </component>
          </div>
        </div>
      </div>
      <div class="developer-project-card__bottom-content none-Mobile mt--0 mb--0">
        <base-button
          :href="
            project.url && project.public
              ? { name: 'residentialComplex', params: { url: project.url } }
              : undefined
          "
          all-block-width
          style-type="coloredBorder"
          text="Подробнее"
          @click="goToRCHandler"
        />
        <base-button
          :href="
            project.url && project.public
              ? { name: 'residentialComplex', params: { url: project.url }, hash: '#map' }
              : undefined
          "
          icon
          style-type="coloredBorder"
          withoutActive
          @click="goToRCHandler"
        >
          <template #icon>
            <img :src="require('@/assets/img/mainPage/icons/icon-map.png')" alt="map" />
          </template>
        </base-button>
      </div>
    </div>
  </base-card>
  <base-modal
    :show-modal="addressModal"
    close-outside
    hide-title
    @close="addressModal = false"
  >
    <template #content>
      <div class="developer-project-card__modal">{{ project.address }}</div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { SmallCardLSDType } from '@/types';
import { computed, ref } from 'vue';
import { useAppStore } from '@/store/modules/app';

const props = withDefaults(
  defineProps<{
    project: SmallCardLSDType;
    version?: string;
  }>(),
  {},
);

const tooltipActive = ref(false);
const title = computed(() => {
  if (props.project.title) {
    let sliced: string = props.project.title.slice(
      0,
      useAppStore().getWindowWidth() > 600 ? 15 : 12,
    );

    if (sliced.length < props.project.title.length) {
      tooltipActive.value = true;
      sliced = sliced + '...';
    }

    return sliced;
  }

  return '-';
});

const addressModal = ref(false);
const addressEl = ref<HTMLElement | undefined>();
const isOverflowed = computed((): boolean => {
  if (addressEl.value) {
    return addressEl.value?.scrollHeight > addressEl.value.offsetHeight;
  }

  return false;
});
const showAddress = () => {
  addressModal.value = true;
};

const goToRCHandler = () => {
  if (!props.project.public) {
    useAppStore().openAlert(
      '',
      'Данная карточка не опубликована, поэтому переход невозможен.',
      'error',
    );
  }
};
</script>

<style lang="scss" scoped src="./ResidentialComplexCard.scss"></style>
