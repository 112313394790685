<template>
  <div class="page-card">
    <div>
      <div
        v-if="
          offerCard.img
            ? offerCard.img.length || offerCard.landImgs.length
            : offerCard.landImgs.length
        "
        id="photoAndAddress"
        class="section page-card__content"
      >
        <base-image-gallery
          :alt="offerCard.address"
          :img-name="offerCard.address"
          :img-title="offerCard.address"
          :navigation="true"
          :slides-group="[
            {
              id: 1,
              title: 'Фотографии дома',
              slides: offerCard.img
                ? [...offerCard.img, ...offerCard.landImgs]
                : offerCard.landImgs,
            },
          ]"
          :space-between="5"
        >
          <template #item="itemProps">
            <button class="page-card__item-img-box" @click="showImageModal = true">
              <button v-if="offerCard.affiliateProgramId" class="partners-icon">₽</button>
              <base-image
                :name="offerCard.address"
                :src="itemProps.data.item"
                :title="offerCard.address"
                border-radius
                class="page-card__item-img"
                contain
                height="100%"
                width="100%"
              />
              <router-link
                v-if="!offerCard.isPhysics"
                :to="{ name: 'checkResult', query: { id: offerCard.landId } }"
                class="verifiedBtn verifiedBtn-text center"
              >
                Проверено
              </router-link>
            </button>
          </template>
        </base-image-gallery>
      </div>
      <div id="mainInfo" class="only-mobile section page-card__content">
        <div class="page-card__main-info">
          <div class="items-in-line flex-start">
            <div class="l-font-size medium">
              {{ offerCard.price ? offerCard.price.toLocaleString() : '-' }} ₽
            </div>
            <div class="third-color">
              {{
                `${pricePerMeter(offerCard) ? pricePerMeter(offerCard).toLocaleString() : '-'} ₽ за м²`
              }}
            </div>
          </div>
          <div class="items-in-line flex-start">
            <div v-if="offerCard.rates && offerCard.rates[0]?.minPayment" class="mt--10">
              Ипотека от:
              {{ offerCard.rates[0].minPayment.toLocaleString() }}
              ₽
            </div>
            <div v-if="offerCard.affiliateProgramId && commission" class="mt--10">
              Комиссия:
              {{ ((offerCard.price * commission) / 100).toLocaleString() }} ₽
            </div>
          </div>
          <div v-if="offerCard.affiliateProgramId && partnerFiles.length" class="mt--10">
            <base-button
              all-block-width
              height="36"
              style-type="greenBorder"
              text="Материалы продаж"
              @click="$emit('showPartnersFiles', offerCard.id)"
            />
          </div>
        </div>
      </div>
      <div id="about" class="section page-card__content">
        <InfographicImgs :infographics="offerCard.infographics" />
        <PassportTable :tableData="passportTableData" class="mt--27" />
        <div class="mt--27">
          <div class="m-font-size mb--10">Описание</div>
          <div class="page-card__content-description">
            {{ description || '-' }}
            <button
              v-if="showFurther"
              class="main-color underline"
              @click="showAllDescription = !showAllDescription"
            >
              {{ showAllDescription ? 'Скрыть' : 'Далее' }}
            </button>
          </div>
        </div>
      </div>
      <div
        id="developerAndLCD"
        :class="{ 'page-card__content': useAppStore().getWindowWidth() > 600 }"
        class="section page-card-cards"
      >
        <div
          v-if="offerCard.aboutResidentialComplex"
          :class="{ 'page-card__content': useAppStore().getWindowWidth() <= 600 }"
        >
          <div class="font-size-title-block mb--20">О ЖК</div>
          <div class="page-card-card-box">
            <ResidentialComplexSmallCard
              :offer-card="offerCard"
              :residential-complex="offerCard.aboutResidentialComplex"
            />
          </div>
        </div>
        <div
          v-if="offerCard.aboutDeveloper"
          :class="{ 'page-card__content': useAppStore().getWindowWidth() <= 600 }"
        >
          <div class="font-size-title-block mb--20">О Застройщике</div>
          <div class="page-card-card-box">
            <DeveloperSmallCard :developer="offerCard.aboutDeveloper" />
          </div>
        </div>
      </div>
      <div
        v-if="offerCard.coordinates"
        id="map"
        class="section page-card__map page-card__content"
      >
        <div class="font-size-title-block mb--10">На карте</div>
        <div class="mb--20">{{ offerCard.address }}</div>
        <base-card full-width-mobile without-mobile-shadow>
          <MapBlock
            :center-point-map="offerCard.coordinates"
            :result-cards-coordinates="[
              { id: offerCard.id, coordinates: offerCard.coordinates },
            ]"
            class="mt--20"
            height="370"
          />
        </base-card>
      </div>
      <div id="schedule" class="section page-card__content">
        <div class="font-size-title-block mb--20">График изменения цены</div>
        <base-skeleton-loader
          v-if="costLoading"
          :style="{ height: '260px' }"
          type="img"
        />
        <base-card v-else without-mobile-shadow>
          <apexchart
            :options="options"
            :series="series"
            height="260"
            type="area"
          ></apexchart>
        </base-card>
      </div>
      <div id="mortgage" class="section page-card__content">
        <div class="font-size-title-block mb--20">Ипотека</div>
        <MortgageBlock :mortgage="offerCard.rates" />
      </div>
      <div v-if="showSellerBlock" id="seller" class="mt--27 section page-card__content">
        <div class="font-size-title-block mb--10">О Продавце</div>
        <div class="page-card-cards mt--27">
          <div>
            <base-card
              class="page-card__seller-info"
              full-width-mobile
              without-mobile-shadow
            >
              <base-image
                :src="offerCard.seller.logo_path_company"
                class="small-card__img"
                contain
                height="65"
                name="seller"
                width="100%"
              />
              <div>
                <span class="medium">Компания: </span
                ><span>{{ offerCard.seller.title_company || '-' }}</span>
              </div>
              <div>
                <span class="medium">Сайт: </span
                ><span>{{ offerCard.seller.site_company || '-' }}</span>
              </div>
            </base-card>
          </div>
          <div>
            <CallOrderCard :cardId="offerCard.id" />
          </div>
        </div>
      </div>
      <div v-if="viewedAds.length" class="page-card__content">
        <div class="font-size-title-block mb--20">Ранее смотрели</div>
        <div>
          <div v-if="useAppStore().getWindowWidth() > 600">
            <base-swiper
              :slides="[...viewedAds]"
              :space-between="20"
              :width-item="240"
              class="mt--20 mb--20"
            >
              <template #item="itemProps">
                <div class="pb--10 pt--10 center">
                  <OfferSmallCard
                    :card="itemProps.data"
                    without-scale
                    @change-active="
                      $router.push({
                        name: 'offerCard',
                        params: { url: itemProps.data.landUrl },
                      })
                    "
                  />
                </div>
              </template>
            </base-swiper>
          </div>
          <div v-else class="page-card__grid-cards">
            <OfferSmallCard
              v-for="card in viewedAds"
              :key="card.id"
              :card="card"
              without-scale
              @change-active="
                $router.push({
                  name: 'offerCard',
                  params: { url: itemProps.data.landUrl },
                })
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="page-card__aside">
      <div>
        <base-card class="page-card__main-info">
          <div class="l-font-size mb--5">
            {{ offerCard.price ? offerCard.price.toLocaleString() : '-' }} ₽
          </div>
          <div>
            {{
              `За м²: ${pricePerMeter(offerCard) ? pricePerMeter(offerCard).toLocaleString() : '-'} ₽`
            }}
          </div>
          <div v-if="offerCard.rates && offerCard.rates[0]?.minPayment">
            Ипотека от:
            {{ offerCard.rates[0].minPayment.toLocaleString() }}
            ₽
          </div>
          <base-divider
            v-if="offerCard.affiliateProgramId"
            class="page-card__main-info-divider"
          />
          <div v-if="offerCard.affiliateProgramId && commission">
            Комиссия B2B:
            {{ ((offerCard.price * commission) / 100).toLocaleString() }} ₽
          </div>
        </base-card>
      </div>

      <div v-if="offerCard.affiliateProgramId && partnerFiles.length" class="mt--25">
        <base-button
          all-block-width
          height="36"
          style-type="coloredBorder"
          text="Материалы продаж"
          @click="$emit('showPartnersFiles', offerCard.id)"
        />
      </div>
      <div class="mt--25">
        <base-card>
          <MenuBlock :menu-items="menuItems.filter(el => !el.hide)" />
        </base-card>
      </div>
      <div class="mt--25">
        <base-card>
          <ActivityButtons
            :docs="offerCard.docs"
            :isCompare="useCompareStore().getIsCompare(offerCard.id)"
            :isFavorite="useUserStore().getIsFavorite(offerCard.landId)"
            :offer-card="offerCard"
            :phone="offerCard.phone"
            @changeIsFavorite="$emit('changeIsFavorite', offerCard.landId)"
          />
        </base-card>
      </div>
    </div>

    <ImageModal v-model="showImageModal" :images="offerCard.img || offerCard.landImgs">
      <template #title>
        <div class="image-modal__top-block">
          <h3 class="image-modal__top-block-title">{{ offerCard.name }}</h3>
          <div class="items-in-line image-modal__top-block-btns none-Mobile">
            <ActionsForImageModal
              :offer-card="offerCard"
              :phone="offerCard.phone"
              @changeIsFavorite="$emit('changeIsFavorite', offerCard.landId)"
              @close="showImageModal = !showImageModal"
            />
          </div>
        </div>
      </template>
      <template #bottom-box>
        <div class="only-mobile">
          <ActionsForImageModal
            :offer-card="offerCard"
            :phone="offerCard.phone"
            @changeIsFavorite="$emit('changeIsFavorite', offerCard.landId)"
            @close="showImageModal = !showImageModal"
          />
        </div>
      </template>
    </ImageModal>
    <teleport
      v-if="!showImageModal && !useChatStore().miniMessenger.show"
      class="page-card__aside-mobile"
      to="#mobileDownMenu"
    >
      <div class="page-card__aside-mobile-card withChat">
        <div class="l-font-size medium none-Mobile">
          {{ offerCard.price?.toLocaleString() }} ₽
        </div>
        <base-button
          all-block-width
          height="36"
          style-type="coloredBorder"
          text="Написать"
          @click="useChatStore().openMiniMessenger(offerCard)"
        />
        <PhoneButton :phone="offerCard.phone" height="36" />
        <base-menu>
          <template #menuItems="menuItemsProps">
            <div class="page-card__menu">
              <div v-for="(item, i) in menuItems.filter(el => !el.hide)" :key="item.id">
                <a
                  :href="`#${item.id}`"
                  class="page-card__menu-item"
                  @click="menuItemsProps.events.handleClick()"
                >
                  {{ item.title }}
                </a>
                <base-divider v-if="i !== menuItems.filter(el => !el.hide).length - 1" />
              </div>
              <ActivityButtons
                :docs="offerCard.docs"
                :isCompare="useCompareStore().getIsCompare(offerCard.id)"
                :isFavorite="useUserStore().getIsFavorite(offerCard.landId)"
                :offer-card="offerCard"
                :phone="offerCard.phone"
                :withFeedBack="false"
                class="page-card__menu-activities"
                @changeIsFavorite="$emit('changeIsFavorite', offerCard.landId)"
              />
            </div>
          </template>
        </base-menu>
      </div>
    </teleport>
  </div>
</template>
<script lang="ts" setup>
import { computed, inject, onMounted, Ref, ref } from 'vue';
import { FileType, ResultCardType } from '@/types';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { OfferSmallCard } from '@/components/Cards';

import {
  CallOrderCard,
  DeveloperSmallCard,
  MapBlock,
  ResidentialComplexSmallCard,
} from '@/components';
import {
  ActivityButtons,
  ImageModal,
  InfographicImgs,
  MenuBlock,
  MortgageBlock,
  PassportTable,
} from '../CardsComponents';
import { useRoute } from 'vue-router';
import { SmoothScrollOptions } from 'vue3-smooth-scroll';
import { useUserStore } from '@/store/modules/user';
import { useCompareStore } from '@/store/modules/compare';
import PhoneButton from '../../PhoneButton/PhoneButton.vue';
import { useChatStore } from '@/store/modules/chat';
import ActionsForImageModal from './ActionsForImageModal/ActionsForImageModal.vue';
import { useAppStore } from '@/store/modules/app';

type SeriesType = {
  name: string;
  data: { x: string; y: number }[];
}[];

type MenuItemType = {
  id: string;
  title: string;
  hide?: boolean;
};

const props = withDefaults(
  defineProps<{
    offerCard: ResultCardType;
    commission: number | undefined;
    partnerFiles: FileType[];
  }>(),
  {},
);
const emit = defineEmits(['changeIsFavorite', 'showPartnersFiles']);
const showImageModal = ref(false);
const showAllDescription = ref(false);
const showFurther = ref(false);
const costLoading = ref(false);
const viewedAds = computed((): ResultCardType[] => {
  return useOfferCardsStore().viewedAds.filter(el => el.id !== props.offerCard.id);
});
const showSellerBlock = computed(() => {
  return (
    props.offerCard.seller.logo_path_company ||
    props.offerCard.seller.title_company ||
    props.offerCard.seller.site_company
  );
});
const options = {
  chart: {
    width: '100%',
    height: '100%',
    dataLabels: {
      enabled: true,
    },
    markers: {
      size: 1,
    },
    toolbar: {
      show: false,
    },
    locales: [
      {
        name: 'ru',
        options: {
          months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Мая',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
          ],
          shortMonths: [
            'Янв',
            'Феб',
            'Март',
            'Апр',
            'Май',
            'Июнь',
            'Июль',
            'Авг',
            'Сент',
            'Окт',
            'Ноя',
            'Дек',
          ],
        },
      },
    ],
    defaultLocale: 'ru',
    fontFamily: 'Roboto',
    foreColor: '#757575',
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },

    zoom: {
      enabled: false,
    },
  },
  colors: ['#009D3E'],
  fontSize: '12px',
  stroke: {
    curve: 'straight',
    width: 2,
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: ['#F9F9F9'],
    },
    column: {
      colors: ['#F9F9F9'],
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      format: 'MM.yyyy',
      style: {
        fontSize: '14px !important',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: '10px',
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      gradientToColors: ['#ABBA00'],
      opacityFrom: 0.7,
      opacityTo: 0.5,
    },
  },
  tooltip: {
    enabled: true,
    x: {
      show: false,
    },
  },
  markers: {
    size: 1,
  },
};
const series: Ref<SeriesType> = ref([]);

const menuItems = computed((): MenuItemType[] => {
  return [
    {
      id: 'photoAndAddress',
      title: 'Фото и адрес',
    },
    {
      id: 'about',
      title: 'О доме / Описание',
    },
    {
      id: 'developerAndLCD',
      title: 'О Застройщике / ЖК',
      hide: !props.offerCard.aboutResidentialComplex && !props.offerCard.aboutDeveloper,
    },
    {
      id: 'map',
      title: 'На карте',
    },
    {
      id: 'schedule',
      title: 'График изменения цены',
    },
    {
      id: 'mortgage',
      title: 'Ипотека',
    },
    {
      id: 'seller',
      title: 'О продавце',
      hide: !showSellerBlock.value,
    },
  ];
});

const description = computed((): string => {
  let sliced: string;

  sliced = props.offerCard.description?.slice(0, 400);

  if (sliced?.length < props.offerCard.description?.length) {
    showFurther.value = true;
  }

  return showAllDescription.value ? props.offerCard.description : sliced;
});
const pricePerMeter = (card: ResultCardType): number | undefined => {
  if (card.id === card.landId && card.landArea) {
    return Math.round(+card.price / +card.landArea);
  }
  if (card.houseArea) {
    return Math.round(+card.price / +card.houseArea);
  }

  return undefined;
};

const passportTableData = computed(() => {
  let tableData = [];

  if (props.offerCard.passportHouse && props.offerCard.passportHouse.length) {
    tableData.push({
      title: 'Дом',
      items: props.offerCard.passportHouse,
    });
  }
  if (props.offerCard.passportLand && props.offerCard.passportLand.length) {
    tableData.push({
      title: 'Земля',
      items: props.offerCard.passportLand,
    });
  }

  return tableData;
});

const smoothScroll = inject('smoothScroll') as (args: SmoothScrollOptions) => void;

onMounted(async () => {
  const hash = useRoute().hash;

  if (hash) {
    const myEl = document.getElementById(hash.replace('#', ''));

    if (myEl) {
      smoothScroll({
        scrollTo: myEl,
        container: myEl,
      });
    }
  }

  costLoading.value = true;
  const res = await useOfferCardsStore().getHouseCostHistory(props.offerCard.id);

  if (res) {
    series.value.push({
      name: 'цена',
      data: res.map(el => {
        return {
          x: el.date,
          y: +el.cost,
        };
      }),
    });
    if (!series.value[0].data.length && props.offerCard.price) {
      series.value[0].data.push({
        x: new Date().toISOString().slice(0, 10),
        y: +props.offerCard.price,
      });
    }
  }
  costLoading.value = false;
});
</script>

<style lang="scss" scoped src="../CommonCardStyles.scss"></style>
