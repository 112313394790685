import { defineStore } from 'pinia';
import { store } from '@/store';
import {
  createPartnerProgramApi,
  deletePartnerProgramApi,
  getAllPartnerProgramsApi,
  getCommissionByIdApi,
  getHousesListApi,
  getPartnerDocumentsApi,
  getQueueListApi,
  getResidentialComplexesApi,
  updatePartnerProgramApi,
} from '@/api/partnerProgram';
import { preparePartnerProgram } from '@/utils/Common/PartnerProgram';
import { PartnerProgramType } from '@/types';

type PartnerProgramState = {
  partnerPrograms: PartnerProgramType[];
};
export const usePartnerProgramStore = defineStore('partnerProgram', {
  state: (): PartnerProgramState => {
    return {
      partnerPrograms: [],
    };
  },
  actions: {
    async getResidentialComplexes() {
      const res = await getResidentialComplexesApi();

      if (res) {
        return res.data.found;
      }
    },
    async getQueueList(residentialComplexId: number) {
      const res = await getQueueListApi(residentialComplexId);

      if (res) {
        return res.data.found;
      }
    },
    async getHousesList(queueCompanyId: number) {
      const res = await getHousesListApi(queueCompanyId);

      if (res) {
        return res.data.found;
      }
    },
    async createPartnerProgram(data: FormData) {
      const res = await createPartnerProgramApi(data);

      if (res) {
        this.partnerPrograms.unshift(preparePartnerProgram(res.data.affiliate_programs));

        return 'success';
      }
    },
    async deletePartnerProgram(affiliateProgramId: number) {
      const res = await deletePartnerProgramApi(affiliateProgramId);

      if (res) {
        return 'success';
      }
    },
    async updatePartnerProgram(preparedData: FormData, id: number) {
      const res = await updatePartnerProgramApi(preparedData);

      if (res) {
        this.partnerPrograms = this.partnerPrograms.map(el => {
          if (el['id' as keyof typeof el].value === id) {
            return preparePartnerProgram(res.data.affiliate_programs);
          }

          return el;
        });

        return 'success';
      }
    },
    async getAllPartnerPrograms(paginationData?: { page: number; pageSize: number }) {
      const res = await getAllPartnerProgramsApi(paginationData);

      this.partnerPrograms = [];
      if (res) {
        if (res.data.list) {
          res.data.list.forEach(el => {
            this.partnerPrograms.push(preparePartnerProgram(el));
          });
        }

        return res.data;
      }
    },
    async getCommissionById(affiliateProgramId: number) {
      const res = await getCommissionByIdApi(affiliateProgramId);

      if (res) {
        return res.data.commission;
      }
    },
    async getPartnerDocsById(affiliateProgramId: number) {
      const res = await getPartnerDocumentsApi(affiliateProgramId);

      if (res) {
        return res.data.files;
      }
    },
    async downloadDocuments(id: number) {
      const a = document.createElement('a');

      a.href = `${process.env.VUE_APP_API_AFFILIATE_PROGRAM_DOWNLOAD_ZIP}?id=${id}`;
      a.click();
    },
  },
  getters: {},
});

export const usePartnerProgramStoreWithOut = () => {
  return usePartnerProgramStore(store);
};
