<template>
  <base-modal :show-modal="showModal" title="Изменить название" @close="closeModal">
    <template #content>
      <div class="allBlockWidth">
        <base-form>
          <base-input
            v-model="formData.name"
            :error="errors.name"
            :invalid="!!errors.name"
            label="Введите название"
            @blur="validationErrorsHandlerByKey('name')"
            @focus="delete errors.name"
            @input="v$.name?.$touch"
          />
        </base-form>
      </div>
    </template>
    <template #btn>
      <div class="items-in-line">
        <base-button
          style-type="coloredBorder"
          text="Отмена"
          width="100"
          @click="closeModal"
        />
        <base-button
          :loading="loading"
          text="Сохранить"
          width="100"
          @click="formSubmit"
        />
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { onMounted, ref, Ref, watch } from 'vue';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ErrorsType } from '@/types';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/utils/Common/Common';
import { useCompareStore } from '@/store/modules/compare';

type FormDataType = {
  name: string | undefined;
};

const props = defineProps<{
  showModal?: boolean;
  compareId: number;
  compareName: string;
}>();
const emit = defineEmits(['close', 'updateSuccessMessage']);

const formData: Ref<FormDataType> = ref({
  name: undefined,
});

const clearFormData = () => {
  v$.value.$reset();
  errors.value = {};
  for (const [key] of Object.entries(formData.value)) {
    formData.value[key as keyof FormDataType] = undefined;
  }
};
const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  name: { required },
};

const v$ = useVuelidate<FormDataType>(rules, formData);
const errors: Ref<ErrorsType> = ref({});

const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};

const loading = ref(false);
const formSubmit = async () => {
  if (v$.value.$invalid || !formData.value.name) {
    v$.value?.$touch();
    validationErrorsHandler();

    return;
  }

  loading.value = true;
  try {
    const res = await useCompareStore().updateCompareName({
      id: props.compareId,
      name: formData.value.name,
    });

    if (res) {
      emit('updateSuccessMessage', {
        message: 'Успешно сохранено',
      });
      clearFormData();
      closeModal();
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error);
  } finally {
    loading.value = false;
  }
};

const closeModal = () => {
  emit('close');
};

onMounted(() => {
  formData.value.name = props.compareName;
});
watch(
  () => props.compareName,
  () => {
    formData.value.name = props.compareName;
  },
);
</script>

<style lang="scss" scoped src="./ChangeCompareTitleModal.scss"></style>
