<template>
  <div v-if="loading" class="mt--20 container content center heightContent">
    <base-spinner :size="50" color="#009D3E" />
  </div>
  <div v-else-if="residentialComplexData" class="offer-card">
    <div class="container content offer-card__container">
      <div class="offer-card__container-title-box">
        <h2 class="content__title offer-card__container-title">
          {{ residentialComplexData.title }}
        </h2>
        <span class="offer-card__container-address">{{
          residentialComplexData.address
        }}</span>
      </div>

      <div>
        <ResidentialComplexCard
          :projects="otherProjects"
          :projects-loading="projectsLoading"
          :residential-complex-data="residentialComplexData"
          :resultCards="resultCards"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, Ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDevelopersStore } from '@/store/modules/developers';
import { ResidentialComplexCard } from '@/components/Cards';
import { ResidentialComplexType, ResultCardType, SmallCardLSDType } from '@/types';
import { useAppStore } from '@/store/modules/app';
import { errorHandle } from '@/utils/Common/Common';

const residentialComplexData: Ref<ResidentialComplexType | undefined> = ref(undefined);
const otherProjects: Ref<SmallCardLSDType[] | undefined> = ref(undefined);
const resultCards: Ref<ResultCardType[] | undefined> = ref(undefined);
const loading = ref(false);
const projectsLoading = ref(false);
const router = useRouter();
const route = useRoute();

const residentialComplexUrl = computed(() => {
  return route.params.url;
});
const updateResidentialComplex = async () => {
  loading.value = true;

  if (residentialComplexUrl.value) {
    try {
      const res = await useDevelopersStore().searchResidentialComplexByUrl(
        residentialComplexUrl.value,
      );

      if (res) {
        residentialComplexData.value = res;

        projectsLoading.value = true;

        try {
          const projects = await useDevelopersStore().searchOtherProjectsByUrlApi(
            residentialComplexUrl.value,
          );

          if (projects) {
            otherProjects.value = projects;
          }

          const offers = await useDevelopersStore().getOffersOfResidentialComplex(
            residentialComplexUrl.value,
          );

          if (offers) {
            resultCards.value = offers;
          }
        } finally {
          projectsLoading.value = false;
        }
      }
    } catch (error) {
      errorHandle(error);
    } finally {
      loading.value = false;
    }
  } else {
    await router.push({ name: 'main' });
  }
};

onMounted(async () => {
  if (useAppStore().getWindowWidth() < 960) {
    document.documentElement.style.setProperty('--btn-up-bottom', '120px');
  }
  await updateResidentialComplex();
});

watch(
  () => residentialComplexUrl.value,
  () => {
    if (residentialComplexUrl.value) {
      updateResidentialComplex();
    }
  },
);
onUnmounted(() => {
  document.documentElement.style.removeProperty('--btn-up-bottom');
});
</script>

<style lang="scss" scoped src="./ResidentialComplexPageLayout.scss"></style>
