import { AffiliateProgramsType } from '@/api/partnerProgram/types';
import { PartnerProgramType } from '@/types';

export const preparePartnerProgram = (
  affiliate_programs: AffiliateProgramsType,
): PartnerProgramType => {
  const prepareDate = (date: string) => {
    return new Date(date).toLocaleString().split(',')[0];
  };

  const colorPeriod = (startDate: string, endDate: string): string => {
    if (new Date() > new Date(endDate)) {
      return '#EA1F1F';
    }
    if (new Date() < new Date(startDate)) {
      return '#0097CB';
    }

    return '#009D3E';
  };

  const ads = affiliate_programs.adverts_id.map(el => {
    return {
      id: el,
      url: affiliate_programs.customUrl[el],
    };
  });

  return {
    id: { value: affiliate_programs.id },
    start_action: { value: affiliate_programs.start_action },
    end_action: { value: affiliate_programs.end_action },
    createDate: { value: new Date(affiliate_programs.created_at).toLocaleString() },
    period: {
      value: `С ${prepareDate(affiliate_programs.start_action)} по ${prepareDate(affiliate_programs.end_action)}`,
      color: colorPeriod(affiliate_programs.start_action, affiliate_programs.end_action),
    },
    ads: { value: ads },
    percent: { value: affiliate_programs.percent },
    files: {
      value: affiliate_programs.files ? affiliate_programs.files : '-',
      icon: affiliate_programs.files ? 'download' : '',
    },
    edit: { value: '', icon: 'edit' },
    delete: { value: '', icon: 'delete' },
  };
};
