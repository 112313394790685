<template>
  <div class="date-picker">
    <VueDatePicker
      ref="datepicker"
      v-model="dateValue"
      :clearable="clearable"
      :disabled="disabled"
      :flow="flow"
      :format="format"
      :format-locale="ru"
      :month-picker="monthPicker"
      :style="[cssProps]"
      :year-picker="yearPicker"
      auto-apply
      model-type="yyyy-MM-dd hh:mm:ss"
      @blur="$emit('blur')"
    >
      <template #dp-input="{ value }">
        <base-input v-model="dateValue" :label="label" :with-bg="withBg" />
      </template>
    </VueDatePicker>
    <span v-if="error?.length" class="date-picker__error">{{ error }}</span>
  </div>
</template>

<script lang="ts" setup>
// eslint-disable-next-line import/named
import VueDatePicker, { DatePickerInstance } from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ru } from 'date-fns/locale';
import { computed, onMounted, ref } from 'vue';

type FlowType = 'month' | 'year' | 'calendar';

const props = withDefaults(
  defineProps<{
    clearable?: boolean;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    monthPicker?: boolean;
    yearPicker?: boolean;
    flow?: FlowType[];
    height?: string;
    backgroundColor?: string;
    error?: string;
    withBg?: boolean;
  }>(),
  {
    clearable: true,
    label: 'Выберите дату',
    height: '36',
    backgroundColor: '#ffffff',
  },
);
const emit = defineEmits(['focus', 'blur']);
// eslint-disable-next-line no-undef
const dateValue = defineModel<string | number | null>();

const datepicker = ref<DatePickerInstance>(null);
const tooltipActive = ref(false);
const datePickerLabel = ref<HTMLElement | undefined>();

const format = computed((): string => {
  if (props.monthPicker) {
    return 'MMMM yyyy';
  }
  if (props.yearPicker) {
    return 'yyyy';
  }

  return 'dd.MM.yyyy';
});

const cssProps = computed(() => {
  return {
    '--dp-background-color': props.backgroundColor,
    '--dp-height': props.height + 'px',
  };
});

onMounted(() => {
  if (datePickerLabel.value) {
    if (datePickerLabel.value.clientWidth < datePickerLabel.value.scrollWidth) {
      tooltipActive.value = true;
    }
  }
});
</script>

<style lang="scss" scoped src="./BaseDatePicker.scss"></style>
<style lang="scss">
@import '@/assets/styles/main';

.dp__input {
  height: var(--dp-height, 36px);
}

.dp__theme_light {
  --dp-primary-color: #009d3e;
  --dp-border-color: #94857d;
  --dp-icon-color: #94857d;
  --dp-text-color: #2d1204;
  --dp-font-size: 16px;
  --dp-scroll-bar-background: #fbf8f7;
  --dp-scroll-bar-color: #94857d;
}

:root {
  /*General*/
  --dp-font-family: Roboto, Arial, sans-serif;
  --dp-border-radius: 10px;
  --dp-cell-border-radius: 10px;
  --dp-common-transition: all 0.1s ease-in; /*Generic transition applied on buttons and calendar cells*/
}
</style>
