<template>
  <teleport :disabled="disabled" to="#portal">
    <div>
      <div
        id="popup"
        ref="popupContent"
        :class="[{ showPopup }, `popup${uuid}`]"
        class="popup"
      >
        <base-card
          v-click-outside="() => closePopup()"
          :class="{ 'with-padding': withPadding }"
          class="popup__content"
        >
          <base-icon
            v-if="showCloseIcons"
            class="popup__content-close-icon"
            clickable
            font-size="24"
            name="close"
            @click="closePopup"
          />
          <slot name="content"></slot>
        </base-card>
      </div>
    </div>
  </teleport>

  <div ref="popupBtn" @click.stop="openSheet">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { getCurrentInstance, ref } from 'vue';
import { ComponentInternalInstance } from '@vue/runtime-core';
import { useAppStore } from '@/store/modules/app';

const props = withDefaults(
  defineProps<{
    start?: boolean;
    showCloseIcons?: boolean;
    withPadding?: boolean;
    disabled?: boolean;
  }>(),
  {},
);
const showPopup = ref(false);
const popupBtn = ref<HTMLElement | undefined>();
const popupContent = ref<HTMLElement | undefined>();
const instance: ComponentInternalInstance | null = getCurrentInstance();
const uuid = ref(instance?.uid);

const closePopup = () => {
  showPopup.value = false;
  if (useAppStore().window <= 600) {
    document.body.classList.remove('overflow-hidden');
  }
};

const openSheet = (e: Event) => {
  if (!props.disabled) {
    e.stopPropagation();
    showPopup.value = true;
    if (useAppStore().window > 600) {
      setTimeout(() => {
        let popup = document.querySelector<HTMLElement>(`.popup${uuid.value}`)!;

        if (popupBtn.value && popupContent.value) {
          let styleLeft: number;

          if (props.start) {
            styleLeft = popupBtn.value.getBoundingClientRect().x;
          } else {
            styleLeft =
              popupBtn.value.getBoundingClientRect().x -
              popupContent.value.getBoundingClientRect().width +
              popupBtn.value.getBoundingClientRect().width;
          }
          popup.style.left = styleLeft < 0 ? '10px' : styleLeft + 'px';

          popup.style.top =
            popupBtn.value.getBoundingClientRect().y +
            popupBtn.value.getBoundingClientRect().height +
            document.documentElement.scrollTop +
            10 +
            'px';
        }
      }, 10);
    } else {
      document.body.classList.add('overflow-hidden');
    }
  }
};
</script>

<style lang="scss" scoped src="./BasePopup.scss"></style>
