import { defineStore } from 'pinia';
import { store } from '@/store';
import {
  addToArchiveApi,
  categoryDataForUpdateApi,
  categoryValueListApi,
  createCategoryValueApi,
  getAllDevelopersApi,
  getAllResidentialComplexApi,
  getArchiveApi,
  getCategoryResidentialComplexesApi,
  getConstructionLinesApi,
  getDevelopersApi,
  getDraftsApi,
  getLegalEntitiesApi,
  getOffersOfResidentialComplexApi,
  getOrderListApi,
  getResidentialComplexListApi,
  prolongAdApi,
  removeFromArchiveApi,
  searchDeveloperByUrlApi,
  searchDeveloperProjectsApi,
  searchOtherDevelopersByUrlApi,
  searchOtherProjectsByUrlApi,
  searchResidentialComplexByUrlApi,
} from '@/api/developer';
import { prepareDeveloperCard, prepareDeveloperSmallCard } from '@/utils/Cards/Developer';
import {
  prepareResidentialComplex,
  prepareSmallCardLSD,
} from '@/utils/Cards/ResidentialComplex';
import { ListDataType, UpdateTypes } from '@/api/developer/types';
import { prepareOfferCard, prepareSmallOfferCard } from '@/utils/Cards/OfferCard';
import { SmallOfferCardType } from '@/types/MainPageTypes';
import { GetAnnouncementsDataType, TypesMyAdsType } from '@/types';

export type CreateCategoryDataType = {
  categoryId: string;
  parentId: number | undefined;
  value: string;
};

type DevelopersStoreType = {
  orderList: SmallOfferCardType[] | undefined;
  draftAdList: SmallOfferCardType[] | undefined;
  archiveAdList: SmallOfferCardType[] | undefined;
};

export const defaultDevelopersState: DevelopersStoreType = {
  orderList: undefined,
  draftAdList: undefined,
  archiveAdList: undefined,
};

export const useDevelopersStore = defineStore('developers', {
  state: (): DevelopersStoreType => {
    return { ...defaultDevelopersState };
  },
  actions: {
    async searchResidentialComplexByUrl(residentialComplexUrl: string) {
      const res = await searchResidentialComplexByUrlApi(residentialComplexUrl);

      if (res) {
        return prepareResidentialComplex(res.data);
      }
    },
    async searchProjectsByUrlApi(developerUrl: string) {
      const res = await searchDeveloperProjectsApi(developerUrl);

      if (res) {
        return prepareSmallCardLSD(res.data);
      }
    },
    async searchOtherProjectsByUrlApi(residentialComplexUrl: string) {
      const res = await searchOtherProjectsByUrlApi(residentialComplexUrl);

      if (res) {
        return prepareSmallCardLSD(res.data);
      }
    },
    async searchDeveloperCardByUrl(developerUrl: string) {
      const res = await searchDeveloperByUrlApi(developerUrl);

      if (res) {
        return prepareDeveloperCard(149, res.data);
      }
    },
    async searchOtherDevelopersByUrlApi(developerUrl: string) {
      const res = await searchOtherDevelopersByUrlApi(developerUrl);

      if (res) {
        return prepareDeveloperSmallCard(res.data);
      }
    },
    async getCategoryValueList() {
      const res = await categoryValueListApi();

      if (res) {
        return prepareDeveloperSmallCard(res.data);
      }
    },
    async getResidentialComplexList() {
      const res = await getResidentialComplexListApi();

      if (res) {
        return prepareSmallCardLSD(res.data);
      }
    },
    async getOrderList(data?: GetAnnouncementsDataType) {
      const res = await getOrderListApi(data);

      if (res) {
        this.orderList = prepareSmallOfferCard(res.data);

        return 'success';
      }
    },
    async getDrafts(data?: GetAnnouncementsDataType) {
      const res = await getDraftsApi(data);

      if (res) {
        this.draftAdList = prepareSmallOfferCard(res.data);

        return 'success';
      }
    },
    async getArchive(data?: GetAnnouncementsDataType) {
      const res = await getArchiveApi(data);

      if (res) {
        this.archiveAdList = prepareSmallOfferCard(res.data);

        return 'success';
      }
    },
    clearMyAnnouncement() {
      this.orderList = undefined;
      this.draftAdList = undefined;
      this.archiveAdList = undefined;
    },
    async addToArchive(announcement: SmallOfferCardType) {
      const res = await addToArchiveApi(announcement.id);

      if (res) {
        this.deleteAd('active', announcement.id);
        if (this.archiveAdList) {
          this.archiveAdList = [...this.archiveAdList, announcement];
        }

        return 'success';
      }
    },
    async removeFromArchive(announcement: SmallOfferCardType) {
      const res = await removeFromArchiveApi(announcement.id);

      if (res) {
        this.deleteAd('archive', announcement.id);
        if (this.orderList) {
          this.orderList = [
            { ...announcement, activeTo: res.data.activeTo },
            ...this.orderList,
          ];
        }

        return 'success';
      }
    },
    async prolongAnnouncement(id: number) {
      const res = await prolongAdApi(id);

      if (res) {
        if (this.orderList) {
          this.orderList = this.orderList.map(el => {
            if (el.id === id) {
              return {
                ...el,
                activeTo: res.data.activeTo,
              };
            }

            return { ...el };
          });
        }

        return Promise.resolve('success');
      }
    },
    async createCategoryValue(preparedData: CreateCategoryDataType) {
      const res = await createCategoryValueApi(preparedData);

      if (res) {
        return res.data;
      }
    },
    updateIsNewAd(id: number) {
      if (this.draftAdList) {
        this.draftAdList = this.draftAdList.map(el => {
          if (el.id === id) {
            return { ...el, isNew: true };
          }

          return { ...el };
        });
      }
    },
    deleteAd(type: TypesMyAdsType, id: number) {
      const deleteAdById = (resultCards: SmallOfferCardType[] | undefined) => {
        if (resultCards) {
          return resultCards.filter(el => el.id !== id);
        }

        return undefined;
      };

      switch (type) {
        case 'active':
          this.orderList = deleteAdById(this.orderList);
          break;
        case 'drafts':
          this.draftAdList = deleteAdById(this.draftAdList);
          break;
        case 'archive':
          this.archiveAdList = deleteAdById(this.archiveAdList);
          break;
      }
    },
    async getCategoryDataForUpdate(type: UpdateTypes, id: number) {
      const res = await categoryDataForUpdateApi(type, id);

      if (res) {
        return res.data;
      }
    },
    async getConstructionLines(id?: number) {
      const res = await getConstructionLinesApi(id);

      if (res) {
        return res.data;
      }
    },
    async getDevelopers(id?: number) {
      const res = await getDevelopersApi(id);

      if (res) {
        return res.data;
      }
    },
    async getCategoryResidentialComplexes(id?: number) {
      const res = await getCategoryResidentialComplexesApi(id);

      if (res) {
        return res.data;
      }
    },
    async getLegalEntities() {
      const res = await getLegalEntitiesApi();

      if (res) {
        return res.data;
      }
    },
    async searchAllDevelopers(data: ListDataType) {
      const res = await getAllDevelopersApi(data);

      if (res) {
        return {
          developers: prepareDeveloperSmallCard(res.data.found),
          page: res.data.page,
          pageSize: res.data.pageSize,
          count: res.data.count,
        };
      }
    },
    async searchAllResidentialComplex(data: ListDataType) {
      const res = await getAllResidentialComplexApi(data);

      if (res) {
        return {
          residentialComplex: prepareSmallCardLSD(res.data.found),
          page: res.data.page,
          pageSize: res.data.pageSize,
          count: res.data.count,
        };
      }
    },
    async getOffersOfResidentialComplex(residentialComplexUrl: string) {
      const res = await getOffersOfResidentialComplexApi(residentialComplexUrl);

      if (res) {
        return res.data
          .filter(el => el.about_house || el.about_land)
          .map(el => prepareOfferCard(el));
      }
    },
  },
  getters: {},
});

export const useDevelopersStoreWithOut = () => {
  return useDevelopersStore(store);
};
