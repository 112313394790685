<template>
  <div v-click-outside="clickOutside" class="base-input-address">
    <base-input
      v-model="inputValue"
      :filled="filled"
      :height="height"
      :hideArrows="hideArrows"
      :label="label"
      :styles="styles"
      :type="type"
      @update:model-value="updateAddress"
    />
    <div v-if="showAddressItems" class="base-input-address__dadata-items">
      <button
        v-for="item in addressItems"
        class="base-input-address__dadata-items-item"
        @click.prevent="
          changeLocation(item);
          inputValue = item;
        "
      >
        {{ item }}
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, Ref } from 'vue';
import { getAddress, getLocation } from '@/api/ymaps';
import { StylesType } from '@/types';

const props = withDefaults(
  defineProps<{
    height?: string;
    label?: string;
    type?: string;
    hideArrows?: boolean;
    filled?: boolean;
    styles?: StylesType;
  }>(),
  {
    height: '36',
  },
);
const emit = defineEmits(['changeLocation']);
// eslint-disable-next-line no-undef
const inputValue = defineModel<string | number | undefined | null>();

const addressItems: Ref<string[]> = ref([]);
const showAddressItems: Ref<boolean> = ref(false);
const clickOutside = () => {
  if (showAddressItems.value) {
    showAddressItems.value = false;
  }
};

const updateAddress = (event: string) => {
  if (event) {
    getAddress(event).then(res => {
      addressItems.value = res.data.suggestions.map((el: { value: string }) => el.value);
      if (addressItems.value.length > 0) {
        showAddressItems.value = true;
      }
    });
  }
};

const changeLocation = (value: string) => {
  getLocation(value).then(res => {
    const point =
      res.data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(
        ' ',
      );

    emit('changeLocation', point);
  });
  showAddressItems.value = false;
};
</script>

<style lang="scss" scoped src="./BaseAddressInput.scss"></style>
