import { AxiosInstance, AxiosResponse, ErrorDataType, RequestConfig } from './types';
import axios, { AxiosError } from 'axios';
import { useAppStore } from '@/store/modules/app';
import Cookies from 'js-cookie';
import router from '@/router';
import { ClearStateAndStorage } from '@/utils/Common/ClearStateAndStorage';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: window.location.origin,
});

axiosInstance.interceptors.request.use(config => {
  const isDev = process.env.VUE_APP_IS_DEV === 'true';

  const url = new URL(config.url ?? '', config.baseURL);

  if (isDev) {
    url?.searchParams.append('XDEBUG_SESSION_START', Cookies.get('XDEBUG_SESSION') ?? '');
  }

  const configEl = config;

  configEl.url = url.toString();
  if (!isDev && config.method === 'post') {
    configEl.headers.set('X-XSRF-TOKEN', Cookies.get('XSRF-TOKEN-RP') ?? '');
  }

  return configEl;
});

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    return res;
  },
  (error: AxiosError) => {
    const data = error.response?.data as ErrorDataType;

    if (data.code === 401) {
      router.push({ name: 'main' }).then(() => ClearStateAndStorage());
      useAppStore().openAuthModal();

      return Promise.reject(error);
    }
    if (data.code === 404) {
      useAppStore().togglePageNotFound(true);

      return Promise.reject(error);
    }
    const errorValidation = data.error === 2;

    if (errorValidation) {
      return Promise.reject(error);
    }

    const errorStatus = data.code ? `код: ${data.code}` : '';
    let errorMessage = data.message ? data.message : undefined;

    if (!errorMessage) {
      switch (data.code) {
        case 400: {
          errorMessage = 'Запрос не принят';
          break;
        }
        case 403: {
          errorMessage = 'В доступе отказано';
          break;
        }
        case 500: {
          errorMessage = 'Внутрення ошибка сервера';
          break;
        }
        default: {
          errorMessage = 'Возникла ошибка, повторите позже';
        }
      }
    }

    useAppStore().openAlert(errorStatus, errorMessage);

    return Promise.reject(error);
  },
);

const service = {
  request: (config: RequestConfig) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .request(config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};

export default service;
