<template>
  <div
    v-if="showBtn && !useChatStore().miniMessenger.show"
    id="upButton"
    class="up-button"
  >
    <button class="up-button__btn center" @click="scrollToTop">
      <base-icon color="#ffffff" name="arrow_upward" />
    </button>
  </div>
</template>
<script lang="ts" setup>
import { useWindowScroll } from '@vueuse/core';
import { computed } from 'vue';
import { useChatStore } from '@/store/modules/chat';

const { x, y } = useWindowScroll();

const showBtn = computed(() => {
  return y.value > window.innerHeight;
});
const scrollToTop = () => {
  y.value = 0;
};
</script>

<style lang="scss" scoped src="./BaseUpButton.scss"></style>
