<template>
  <div class="video__posterContainer pointer" @click="openVideo(video, id)">
    <img :src="imgSrc" alt="" class="video__posterContainer__poster" />
    <div class="video__posterContainer__play"></div>
  </div>
  <base-modal :show-modal="modal" close-outside hide-title @close="modal = false">
    <template #content>
      <div class="video__wrapper">
        <div v-if="modalLoader" class="container center video__wrapper-loading">
          <base-spinner :size="50" color="#009D3E" />
        </div>
        <div :id="id" />
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { computed, ref, Ref } from 'vue';

const props = defineProps<{
  youTube: string;
  id: string;
}>();
const modal: Ref<boolean> = ref(false);
const modalLoader: Ref<boolean> = ref(false);
const videoLinkCode = computed((): string => {
  let regexp = new RegExp('\\?v=([^&]+)(&.*)?$', 'i');

  if (props.youTube.match(regexp)) {
    return props.youTube.match(regexp)![1];
  } else {
    return '';
  }
});

const video = computed((): string => {
  return `https://www.youtube.com/embed/${videoLinkCode.value}`;
});
const imgSrc = computed((): string => {
  return `//img.youtube.com/vi/${videoLinkCode.value}/mqdefault.jpg`;
});

const openVideo = (youTube: string, id: string) => {
  modal.value = true;
  modalLoader.value = true;
  setTimeout(() => {
    const frame = `<iframe v-if="youTube" class="video__frame" style="position:absolute; left: 0; top: 0; width: 100%; height: 100%; border: 0" src="${youTube}" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

    document.querySelector(`#${CSS.escape(id)}`)!.insertAdjacentHTML('afterbegin', frame);
    modalLoader.value = false;
  }, 1000);
};
</script>

<style lang="scss" scoped src="./BaseVideoLink.scss"></style>
