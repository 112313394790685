<template>
  <div>
    <base-card class="request-history" full-width-mobile>
      <div class="m-font-size mb--20">История</div>
      <div v-if="isLoading" class="container center">
        <base-spinner :size="50" color="#009D3E" />
      </div>
      <div v-else-if="prepareValues.length">
        <div class="none-Mobile">
          <base-table :headers="headers" :items="prepareValues" :style="'simple'" />
        </div>
        <div class="mobile-table">
          <div
            v-for="value in prepareValues"
            class="request-history__mobile-table-row mobile-table__row"
          >
            <div
              v-for="header in headers"
              :style="{ gridArea: header.value }"
              class="request-history__mobile-table-text"
            >
              <div v-if="value[header.value]?.icon !== undefined" class="center">
                <base-icon
                  :name="value[header.value]?.icon"
                  clickable
                  color="#888298"
                  height="18"
                  width="21"
                  @click="header.click ? header.click(value) : () => {}"
                />
              </div>
              <div :class="{ medium: header.value === 'theme' }">
                {{ value[header.value].value }}
              </div>
            </div>
          </div>
        </div>
        <base-pagination-group
          v-model="prepareValues"
          :max-for-visible="values.length"
          :show-btn="false"
          :values="values"
          class="mt--20"
        />
      </div>
      <div v-else>Обращений нет</div>
    </base-card>
    <base-modal
      :show-modal="modalInfo.show"
      close-outside
      hide-title
      @close="modalInfo.show = false"
    >
      <template #content>
        <div class="request-history__modal">
          <div>
            <span class="medium mr--5">Дата/Время:</span>
            <span>{{ modalInfo.item['date'].value }}</span>
          </div>
          <div class="mt--10">
            <span class="medium mr--5">Тема:</span>
            <span>{{ modalInfo.item['theme'].value }}</span>
          </div>
          <div class="mt--10">
            <span class="medium mr--5">Сообщение:</span>
            <span>{{ modalInfo.item['comment'].value }}</span>
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref, watch } from 'vue';
import { HeaderType, ItemType } from '@/types/TableTypes';
import { useUserStore } from '@/store/modules/user';

const isLoading = ref(false);
const modalInfo: Ref<{ show: boolean; item?: ItemType }> = ref({ show: false });
const prepareValues: Ref<ItemType[]> = ref([]);
const values = computed((): ItemType[] => {
  return useUserStore().supportList;
});

const showInfoModal = async (item: ItemType) => {
  modalInfo.value = { show: true, item: item };
};
const headers = computed((): HeaderType[] => {
  return [
    {
      text: 'Дата/Время',
      value: 'date',
      align: 'left',
    },
    {
      text: 'Тема',
      value: 'theme',
      align: 'left',
    },
    {
      text: 'Сообщение',
      value: 'comment',
      align: 'left',
    },
    {
      text: '',
      value: 'view',
      align: 'center',
      click: showInfoModal,
    },
  ];
});

onMounted(async () => {
  isLoading.value = true;
  await useUserStore().getSupportList();
  isLoading.value = false;
});

watch(
  () => values.value,
  () => {
    prepareValues.value = values.value;
  },
);
</script>

<style lang="scss" scoped src="./RequestHistory.scss"></style>
