import { FreeCallDataType, InfoType, SendReviewDataType } from './types';
import request from '@/axios';
import { useAppStore } from '@/store/modules/app';

export const getInfoApi = (): Promise<IResponse<InfoType>> => {
  return request.get({ url: process.env.VUE_APP_API_INFO });
};

export const sendReview = (
  data: SendReviewDataType,
): Promise<IResponse<{ message: string }>> => {
  const preparedData = { ...data, url: window.location.href };

  return request.post({
    url: process.env.VUE_APP_API_REVIEW,
    data: preparedData,
  });
};

export const sendStatisticApi = (id: number): Promise<IResponse<{ message: string }>> => {
  return request.get({
    url: process.env.VUE_APP_API_EVENT,
    params: {
      id: id,
      region_id: useAppStore().regions_for_select.defaultForUser,
    },
  });
};

export const orderFreeCallApi = (
  data: FreeCallDataType,
): Promise<IResponse<{ message: string }>> => {
  return request.post({ url: process.env.VUE_APP_API_FREE_CALLS_CREATE, data });
};
