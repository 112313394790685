<template>
  <base-form>
    <div v-if="paramsData" class="update-developer-form">
      <div v-for="input in params">
        <div v-if="input.type === 24 || input.type === 25 || input.type === 28">
          <div class="items-in-line gap--5">
            <base-input
              v-model="paramsData[String(input.id)].value.min"
              :error="errors[String(input.id)]"
              :invalid="!!errors[String(input.id)]"
              :label="input.title + ' от'"
              :type="input.type === 24 || input.type === 25 ? 'number' : 'text'"
              height="34"
              hideArrows
              with-bg
              @blur="$emit('validationErrorsHandlerByKey', input.id)"
              @focus="$emit('focus', input.id)"
              @input="$emit('inputTouch', input.id)"
            />
            <base-input
              v-model="paramsData[String(input.id)].value.max"
              :invalid="!!errors[String(input.id)]"
              :label="input.title + ' до'"
              :type="input.type === 24 || input.type === 25 ? 'number' : 'text'"
              height="34"
              hideArrows
              with-bg
              @blur="$emit('validationErrorsHandlerByKey', input.id)"
              @focus="$emit('focus', input.id)"
              @input="$emit('inputTouch', input.id)"
            />
            <base-button
              v-if="input.type === 28 && input.id === activeAddressIds.pointId"
              height="34"
              icon
              style-type="white"
              width="34"
              without-active
              @click="
                showMapBlock([
                  paramsData[String(input.id)].value.max,
                  paramsData[String(input.id)].value.min,
                ])
              "
            >
              <template #text>
                <base-image
                  :src="require('@/assets/img/banner/icon-for-map.svg')"
                  contain
                  height="34"
                  name="map"
                  width="20"
                />
              </template>
            </base-button>
          </div>
        </div>
        <div v-else-if="input.id === activeAddressIds.addressId">
          <base-address-input
            v-model="paramsData[String(input.id)].value"
            :label="input.title"
            height="36"
            @changeLocation="changeAddressValue({ point: $event })"
          />
        </div>
        <base-date-picker
          v-else-if="
            input.type === 12 ||
            input.type === 13 ||
            input.type === 19 ||
            input.type === 20
          "
          v-model="paramsData[String(input.id)].value"
          :error="errors[String(input.id)]"
          :flow="['year', 'month', 'calendar']"
          :label="input.title"
          :month-picker="input.type === 20"
          :year-picker="input.type === 19"
          height="36"
          with-bg
          @blur="$emit('validationErrorsHandlerByKey', input.id)"
          @focus="$emit('focus', input.id)"
          @input="$emit('inputTouch', input.id)"
        />
        <base-checkbox-input
          v-else-if="input.type === 3"
          v-model="paramsData[String(input.id)].value"
          :error="errors[String(input.id)]"
          :label="input.title"
          height="36"
          @blur="$emit('validationErrorsHandlerByKey', input.id)"
          @focus="$emit('focus', input.id)"
          @input="$emit('inputTouch', input.id)"
        />
        <base-file-input
          v-else-if="
            input.type === 10 ||
            input.type === 11 ||
            input.type === 26 ||
            input.type === 15
          "
          v-model="paramsData[String(input.id)].value.newValue"
          :files="paramsData[String(input.id)].value.oldValue"
          :label="input.title"
          :multiple="input.type === 11 || input.type === 26"
          with-bg
          withIcon
          @deleteFile="openModal(input.id, $event)"
          @focus="$emit('focus', input.id)"
          @update="paramsData[input.id] = $event"
        />
        <div v-else-if="input.type === 4 || input.type === 14 || input.type === 30">
          <base-select-input
            :key="input.id"
            v-model="paramsData[String(input.id)].value"
            :error="errors[String(input.id)]"
            :isMulti="input.type === 14"
            :label="input.title"
            :options="input.list"
            :searchCount="
              input.options['search2-visual'] === '15' ||
              input.options['search2-visual'] === '13'
                ? 5
                : 7
            "
            arrow="expand_more"
            clearable
            height="36"
            textKey="value"
            with-bg
            @blur="$emit('validationErrorsHandlerByKey', input.id)"
            @focus="$emit('focus', input.id)"
            @input="$emit('inputTouch', input.id)"
          />
        </div>

        <base-input
          v-else-if="input.type === 23"
          v-model="paramsData[input.id].value"
          v-maska
          :error="errors[String(input.id)]"
          :invalid="!!errors[String(input.id)]"
          :label="input.title"
          data-maska="##:##:######:##"
          height="36"
          hideArrows
          placeholder="47:14:120301:814"
          with-bg
          @blur="$emit('validationErrorsHandlerByKey', input.id)"
          @focus="$emit('focus', input.id)"
          @input="$emit('inputTouch', input.id)"
        />
        <base-input
          v-else-if="input.type === 8"
          v-model="paramsData[input.id].value"
          v-maska
          :error="errors[String(input.id)]"
          :invalid="!!errors[String(input.id)]"
          :label="input.title"
          data-maska="(###)###-##-##"
          height="36"
          hideArrows
          placeholder="+7 (999)000-99-99"
          prefix="+7 "
          with-bg
          @blur="$emit('validationErrorsHandlerByKey', input.id)"
          @focus="$emit('focus', input.id)"
          @input="$emit('inputTouch', input.id)"
        />
        <div v-else-if="input.type === 22">
          <UpdateCategories
            :category-id="input.options['list-category']"
            :editable-category="input.value"
            :parent-id="parentId"
            :title="input.title"
            is-input
            @change-category="$emit('changeCategory', $event)"
            @create-category="$emit('createCategory', [$event, input.id])"
            @delete-category="$emit('deleteCategory', [$event, input.id])"
          />
        </div>
        <base-textarea
          v-else-if="input.type === 7 || input.type === 1"
          v-model="paramsData[String(input.id)].value"
          :error="errors[String(input.id)]"
          :invalid="!!errors[String(input.id)]"
          :label="input.title"
          height="36"
          max-height="150"
          textarea
          withScroll
          @blur="$emit('validationErrorsHandlerByKey', input.id)"
          @focus="$emit('focus', input.id)"
          @input="$emit('inputTouch', input.id)"
        />
        <base-input
          v-else
          v-model="paramsData[String(input.id)].value"
          :error="errors[String(input.id)]"
          :invalid="!!errors[String(input.id)]"
          :isPercent="input.type === 6"
          :label="input.title"
          :max="
            input.type === 6 ? 100 : input.options.max ? +input.options.max : undefined
          "
          :min="input.options.min ? +input.options.min : undefined"
          :styles="{ border: '1px solid #94857d' }"
          :type="
            input.type === 2 || input.type === 5 || input.type === 6 ? 'number' : 'text'
          "
          height="36"
          hideArrows
          with-bg
          @blur="$emit('validationErrorsHandlerByKey', input.id)"
          @focus="$emit('focus', input.id)"
          @input="$emit('inputTouch', input.id)"
        >
          <template #appendIcon>
            <span v-if="input.type === 27" class="third-color xs-font-size">метр.</span>
          </template>
        </base-input>
      </div>
    </div>
    <base-modal :show-modal="modalData.show" hide-title @close="closeModal">
      <template #content>
        <div>Вы уверены, что хотите удалить файл?</div>
      </template>
      <template #btn>
        <div class="items-in-line">
          <base-button
            height="40"
            style-type="border"
            text="Отмена"
            width="118"
            @click="closeModal"
          />
          <base-button
            :loading="modalData.btnLoading"
            height="40"
            text="Да"
            width="118"
            @click="deleteFile"
          />
        </div>
      </template>
    </base-modal>
    <MapModal
      :address-value="mapModalData.addressValue"
      :point="mapModalData.point"
      :show-modal="mapModalData.show"
      @changePoint="changeAddressValue"
      @close="closeMapBlock"
    />
  </base-form>
</template>

<script lang="ts" setup>
import { ErrorsType, ParamsDataType } from '@/types';
import { vMaska } from 'maska';
import { ValidationRuleWithoutParams } from '@vuelidate/core';
import { ParamsValuesType } from '@/api/developer/types';
import { UpdateCategories } from '@/components';
import { onMounted, ref, Ref } from 'vue';
import { deleteFileApi } from '@/api/developer';
import { FileParamType } from '@/types/UpdateDeveloperTypes';
import MapModal from './MapModal/MapModal.vue';
import type { LngLat } from '@yandex/ymaps3-types';

type addressIdsType = {
  pointId: number | undefined;
  addressId: number | undefined;
};

const props = defineProps<{
  params: ParamsValuesType[];
  errors: ErrorsType;
  rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } };
  parentId: number;
}>();
const emit = defineEmits([
  'validationErrorsHandlerByKey',
  'inputTouch',
  'focus',
  'changeCategory',
  'createCategory',
  'deleteCategory',
  'update:paramsData',
  'deleteFile',
]);
// eslint-disable-next-line no-undef
const paramsData = defineModel<ParamsDataType>('paramsData');
// eslint-disable-next-line no-undef
const v$ = defineModel<ParamsDataType>('v$');

const addressIds = [
  {
    pointId: 226,
    addressId: 250,
  },
  {
    pointId: 225,
    addressId: 88,
  },
];
const activeAddressIds: Ref<{
  addressIdsType;
}> = ref({
  pointId: undefined,
  addressId: undefined,
});
const modalData: Ref<{
  show: boolean;
  fileId: undefined | number;
  inputId: undefined | number;
  btnLoading: boolean;
}> = ref({
  show: false,
  fileId: undefined,
  inputId: undefined,
  btnLoading: false,
});
const mapModalData: Ref<{
  show: boolean;
  point: LngLat | undefined;
  addressValue: string | undefined;
}> = ref({
  show: false,
  point: undefined,
  addressValue: undefined,
});
const openModal = (inputId: number, fileId: number) => {
  modalData.value = {
    show: true,
    fileId: fileId,
    inputId: inputId,
    btnLoading: false,
  };
};
const closeModal = () => {
  modalData.value = {
    show: false,
    fileId: undefined,
    inputId: undefined,
    btnLoading: false,
  };
};

const deleteFile = async () => {
  if (modalData.value.fileId && paramsData.value && modalData.value.inputId) {
    try {
      modalData.value.btnLoading = true;

      const res = await deleteFileApi(modalData.value.fileId);

      if (res) {
        // useAppStore().openInfoModal('Файл удален');
        (paramsData.value[modalData.value.inputId].value as FileParamType).oldValue = (
          paramsData.value[modalData.value.inputId].value as FileParamType
        ).oldValue!.filter(el => el.id !== modalData.value.fileId);
      }
    } finally {
      closeModal();
    }
  }
};

const changeAddressValue = (data: { point: [string, string]; address?: string }) => {
  if (activeAddressIds.value.pointId) {
    paramsData.value[activeAddressIds.value?.pointId].value.min = data.point[1];
    paramsData.value[activeAddressIds.value?.pointId].value.max = data.point[0];
    if (data.address) {
      paramsData.value[activeAddressIds.value?.addressId].value = data.address;
    }
  }
};

const showMapBlock = (point: LngLat) => {
  mapModalData.value = {
    show: true,
    point: [+point[0], +point[1]],
    addressValue: paramsData.value[activeAddressIds.value?.addressId]?.value,
  };
};
const closeMapBlock = () => {
  mapModalData.value = {
    show: false,
    point: undefined,
    addressValue: undefined,
  };
};

onMounted(() => {
  addressIds.forEach(el => {
    if (paramsData.value[el.pointId] && paramsData.value[el.addressId]) {
      activeAddressIds.value = { ...el };
    }
  });
});
</script>

<style lang="scss" scoped src="./UpdateForm.scss"></style>
