import App from './App.vue';
import axios from 'axios';
import { createApp } from 'vue';
import VueAxios from 'vue-axios';
import { setupRouter } from '@/router';
import { setupStore } from '@/store';
import './assets/styles/main.scss';
import {
  BaseAddressInput,
  BaseBottomSheet,
  BaseBrazzersCarousel,
  BaseButton,
  BaseButtonGroup,
  BaseButtonsCarousel,
  BaseCard,
  BaseCheckbox,
  BaseCheckboxInput,
  BaseDatePicker,
  BaseDivider,
  BaseExpansionPanels,
  BaseFileInput,
  BaseForm,
  BaseIcon,
  BaseImage,
  BaseImageCarousel,
  BaseImageGallery,
  BaseInfoBtn,
  BaseInfoModal,
  BaseInput,
  BaseMenu,
  BaseMenuWithTabs,
  BaseModal,
  BaseOverlay,
  BasePagination,
  BasePaginationGroup,
  BasePhoneInput,
  BasePopup,
  BaseProgressBar,
  BaseProgressLine,
  BaseRatingNumber,
  BaseSelectInput,
  BaseSkeletonLoader,
  BaseSpinner,
  BaseSwiper,
  BaseTable,
  BaseTextArea,
  BaseTooltip,
  BaseUpButton,
  BaseVideoLink,
} from '@/components/UI';
import { clickOutside } from '@/directives/click-outside';
import VueSmoothScroll from 'vue3-smooth-scroll';
import VueApexCharts from 'vue3-apexcharts';
import { createYmaps } from 'vue-yandex-maps';

const app = createApp(App);

setupRouter(app);
setupStore(app);

//Глобальные компоненты
app.component('base-menu', BaseMenu);
app.component('base-icon', BaseIcon);
app.component('base-input', BaseInput);
app.component('base-table', BaseTable);
app.component('base-divider', BaseDivider);
app.component('base-checkbox', BaseCheckbox);
app.component('base-file-input', BaseFileInput);
app.component('base-tooltip', BaseTooltip);
app.component('base-info-btn', BaseInfoBtn);
app.component('base-button', BaseButton);
app.component('base-overlay', BaseOverlay);
app.component('base-spinner', BaseSpinner);
app.component('base-swiper', BaseSwiper);
app.component('base-card', BaseCard);
app.component('base-form', BaseForm);
app.component('base-image', BaseImage);
app.component('base-modal', BaseModal);
app.component('base-popup', BasePopup);
app.component('base-textarea', BaseTextArea);
app.component('base-info-modal', BaseInfoModal);
app.component('base-pagination', BasePagination);
app.component('base-button-group', BaseButtonGroup);
app.component('base-select-input', BaseSelectInput);
app.component('base-phone-input', BasePhoneInput);
app.component('base-date-picker', BaseDatePicker);
app.component('base-progress-bar', BaseProgressBar);
app.component('base-bottom-sheet', BaseBottomSheet);
app.component('base-rating-number', BaseRatingNumber);
app.component('base-progress-line', BaseProgressLine);
app.component('base-menu-with-tabs', BaseMenuWithTabs);
app.component('base-checkbox-input', BaseCheckboxInput);
app.component('base-image-carousel', BaseImageCarousel);
app.component('base-image-gallery', BaseImageGallery);
app.component('base-buttons-carousel', BaseButtonsCarousel);
app.component('base-skeleton-loader', BaseSkeletonLoader);
app.component('base-expansion-panels', BaseExpansionPanels);
app.component('base-pagination-group', BasePaginationGroup);
app.component('base-brazzers-carousel', BaseBrazzersCarousel);
app.component('base-up-button', BaseUpButton);
app.component('base-video-link', BaseVideoLink);
app.component('base-address-input', BaseAddressInput);

//Глобальные директивы
app.directive('click-outside', clickOutside);
app.use(VueSmoothScroll);
app.use(VueApexCharts);
app.use(
  createYmaps({
    apikey: '72e7233b-21bd-4a70-b726-6feb8f7d3bc7',
  }),
);
app.use(VueAxios, axios);
app.mount('#app');
