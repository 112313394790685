export enum RouterPath {
  main = '/',
  classification = '/classification',
  checkResult = '/result',
  profile = '/profile/:id?',
  offerCard = '/offerCard/:url',
  updateOfferCard = '/offerCard/update',
  residentialComplex = '/residentialComplex/:url',
  updateResidentialComplex = '/residentialComplex/update',
  updateDeveloper = '/developer/update',
  developer = '/developer/:url',
  login = '/user/login',
  passwordRestore = '/user/password-restore',
  developerCards = '/developerCards',
  residentialComplexCards = '/residentialComplexCards',
  mortgage = '/mortgage',
  services = '/services',
  compare = '/compare',
  juristicDocuments = '/juristicDocuments',
  fullMap = '/fullMap',
  notFound = '/404',
}

export enum RouterName {
  main = 'main',
  classification = 'classification',
  checkResult = 'checkResult',
  profile = 'profile',
  offerCard = 'offerCard',
  updateOfferCard = 'updateOfferCard',
  residentialComplex = 'residentialComplex',
  updateResidentialComplex = 'updateResidentialComplex',
  updateDeveloper = 'updateDeveloper',
  developer = 'developer',
  login = 'login',
  passwordRestore = 'passwordRestore',
  developerCards = 'developerCards',
  residentialComplexCards = 'residentialComplexCards',
  mortgage = 'mortgage',
  services = 'services',
  compare = 'compare',
  juristicDocuments = 'juristicDocuments',
  fullMap = 'fullMap',
  notFound = '404',
}

export enum RouterHash {
  empty = '',
}
