<template>
  <div
    :class="[{ active: isActive }, { scale: !isActive && !withClose && !withoutScale }]"
    class="card-container none-Mobile"
  >
    <div class="result-card">
      <button v-if="card.affiliateProgramId" class="partners-icon">₽</button>
      <div v-if="withClose" class="result-card__close">
        <base-icon clickable font-size="28" name="close" @click="$emit('close')" />
      </div>
      <div class="relative">
        <router-link :to="{ name: 'offerCard', params: { url: card.landUrl } }">
          <base-brazzers-carousel
            :alt="card.address"
            :photos="photos"
            :title="card.address"
            borderRadius
            height="150"
            version="x1"
            width="240"
          />
        </router-link>
        <router-link
          v-if="!card.isPhysics"
          :to="{ name: 'checkResult', query: { id: card.landId } }"
          class="verifiedBtn verifiedBtn-text center"
        >
          Проверено
        </router-link>
      </div>

      <div class="result-card__container">
        <div class="result-card__container-info">
          <div>
            <div class="result-card__container-price">
              {{ card.price?.toLocaleString() || '-' }} ₽
            </div>
            <div
              v-if="card.rates && card.rates[0]?.minPayment"
              class="result-card__container-item"
            >
              <span class="dark-text-color">Ипотека от: </span>
              <span class="blue-color"
                >{{ card.rates[0].minPayment.toLocaleString() }} ₽</span
              >
            </div>
          </div>
          <div>
            <div v-if="card.id !== card.landId" class="result-card__container-item">
              <span class="dark-text-color">Дом: </span>
              <span>{{ `${card.houseArea || '-'} м²` }}</span>
            </div>
            <div v-if="card.id !== card.landId" class="result-card__container-item">
              <span class="dark-text-color">Этаж: </span>
              <span>{{ card.floor || '-' }}</span>
            </div>
            <div class="mt--10 result-card__container-item">
              <span class="dark-text-color">Участок: </span>
              <span>{{ `${card.landArea || '-'} сот.` }}</span>
            </div>
            <div class="result-card__container-item">
              <span class="dark-text-color">Тип: </span>
              <span>{{ card.type || '-' }}</span>
            </div>
          </div>
          <base-button
            :text="isActive ? 'Скрыть' : 'Подробнее'"
            allBlockWidth
            style-type="coloredBorder"
            @click="$emit('changeActive', card.id)"
            @mousedownHandler="mousedownHandler"
          />
        </div>
        <div class="result-card__container-icons">
          <router-link
            v-if="card.rating !== undefined"
            :to="{ name: 'classification', query: { id: card.landId } }"
          >
            <base-rating-number
              :max-rate="card.classifier?.maxRate"
              :rating="card.rating"
            />
          </router-link>
          <div v-else />
          <base-button
            class="center"
            icon
            style-type="border"
            withoutActive
            @click="changeIsFavorite(card.landId)"
          >
            <template #icon>
              <base-icon
                :color="useUserStore().getIsFavorite(card.landId) ? '#EA1F1F' : '#969696'"
                font-size="20"
                name="favorite"
                width="22"
              />
            </template>
          </base-button>
          <CompareIcon :card="card" with-border />
          <SaveDocsBtn :docs="card.docs" />
          <div>
            <base-button
              v-show="card.coordinates"
              :href="{
                name: 'offerCard',
                params: { url: card.landUrl },
                hash: '#map',
              }"
              icon
              style-type="coloredBorder"
              withoutActive
            >
              <template #icon>
                <img
                  :src="require('@/assets/img/mainPage/icons/icon-map.png')"
                  alt="map"
                />
              </template>
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-container-mobile only-mobile">
    <div class="result-card-mobile">
      <button v-if="card.affiliateProgramId" class="partners-icon">₽</button>
      <div v-if="withClose" class="result-card__close">
        <base-icon
          clickable
          font-size="28"
          name="close"
          @click.prevent="$emit('close')"
        />
      </div>
      <div class="relative">
        <button v-if="card.affiliateProgramId" class="partners-icon">₽</button>
        <router-link :to="{ name: 'offerCard', params: { url: card.landUrl } }">
          <base-image
            :name="String(card.id)"
            :src="card.img ? card.img[0] : card.landImgs[0]"
            borderRadius
            cover
            height="150"
            version="x1"
            width="100%"
          />
        </router-link>
        <router-link
          v-if="!card.isPhysics"
          :to="{ name: 'checkResult', query: { id: card.landId } }"
          class="verifiedBtn verifiedBtn-text center"
        >
          Проверено
        </router-link>
      </div>

      <div class="result-card-mobile__container">
        <div>
          <div class="items-in-column gap--0 result-card-mobile__container-price">
            <div class="medium">{{ card.price?.toLocaleString() || '-' }} ₽</div>
            <div
              v-if="card.rates && card.rates[0]?.minPayment"
              class="result-card-mobile__container-payment"
            >
              <span>от {{ card.rates[0].minPayment.toLocaleString() }} ₽/мес</span>
            </div>
          </div>
          <div
            v-if="card.houseArea || card.floor"
            class="result-card-mobile__container-item"
          >
            <span class="third-color">Дом</span>
            <span class="items-in-line">
              <span v-if="card.houseArea"> {{ card.houseArea }}м² </span>
              <span v-if="card.floor">{{ card.floor }} этaж</span></span
            >
          </div>
          <div
            v-if="card.type || card.landArea"
            class="result-card-mobile__container-item"
          >
            <span class="third-color">Земля</span>
            <span class="items-in-line">
              <span v-if="card.type">{{ card.type }}</span>
              <span v-if="card.landArea">{{ card.landArea }} сот.</span>
            </span>
          </div>
        </div>

        <div class="result-card-mobile__container-icons">
          <router-link
            v-if="card.rating !== undefined"
            :to="{ name: 'classification', query: { id: card.landId } }"
          >
            <base-rating-number
              :max-rate="card.classifier?.maxRate"
              :rating="card.rating"
            />
          </router-link>
          <div v-else />
          <base-icon
            :color="useUserStore().getIsFavorite(card.landId) ? '#EA1F1F' : '#969696'"
            clickable
            font-size="24"
            name="favorite"
            @click.prevent="changeIsFavorite(card.landId)"
          />
          <CompareIcon :card="card" />
          <div class="center">
            <a :href="`${card.linkUrl}#map`">
              <base-icon v-show="card.coordinates" clickable>
                <template #icon>
                  <img
                    :src="require('@/assets/img/mainPage/icons/icon-map.png')"
                    alt="map"
                  />
                </template>
              </base-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ResultCardType } from '@/types';
import { CompareIcon, SaveDocsBtn } from '@/components/Cards/CardsComponents';
import { useUserStore } from '@/store/modules/user';
import { useAppStore } from '@/store/modules/app';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const props = defineProps<{
  card: ResultCardType;
  isActive?: boolean;
  withClose?: boolean;
  withoutScale?: boolean;
}>();

const emit = defineEmits(['changeActive', 'close', 'hideActive', 'middle']);

const router = useRouter();
const photos = computed((): string[] => {
  return props.card.id === props.card.landId
    ? props.card.landImgs
    : [...props.card.img, ...props.card.landImgs];
});

const changeIsFavorite = (cardId: number) => {
  if (useUserStore().user) {
    useUserStore().changeIsFavorite(cardId);
  } else {
    useAppStore().openAuthModal(true);
  }
};

const mousedownHandler = (event: MouseEvent) => {
  if (event.button === 1) {
    emit('middle');
  }
};
</script>

<style lang="scss" scoped src="./OfferSmallCard.scss"></style>
