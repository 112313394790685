<template>
  <div class="items-in-line">
    <div v-for="(options, i) in sortOptions" :key="i">
      <button
        :disabled="loading"
        class="items-in-line gap--3"
        @click="changeModelValue(options)"
      >
        <span :class="activeValueIndex(options) >= 0 ? 'main-color' : 'third-color'">
          {{ getCurrentOption(options).name }}
        </span>
        <base-icon
          v-if="getCurrentOption(options).icon"
          :color="activeValueIndex(options) >= 0 ? '#009D3E' : '#94857d'"
          :name="getCurrentOption(options).icon"
          font-size="16"
        />
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { OptionType } from '@/types';

const props = withDefaults(
  defineProps<{
    sortOptions: OptionType[][];
    loading?: boolean;
  }>(),
  {},
);
// eslint-disable-next-line no-undef
const modelValue = defineModel<number | string | (number | string)[] | undefined>();

const changeModelValue = (options: OptionType[]) => {
  if (activeValueIndex(options) === 0) {
    modelValue.value = options[1].id;

    return;
  }
  modelValue.value = options[0].id;
};

const activeValueIndex = (options: OptionType[]) => {
  return options.findIndex(el => el.id === modelValue.value);
};

const getCurrentOption = (options: OptionType[]) => {
  return activeValueIndex(options) >= 0 ? options[activeValueIndex(options)] : options[0];
};
</script>

<style lang="scss" scoped src="./OpenSorting.scss"></style>
