<template>
  <div>
    <div
      :class="{ styleType, withText: !withIcon }"
      :style="[cssProps]"
      class="fileInputBox"
    >
      <base-input
        id="txt"
        :class="{ paddingRight: withIcon }"
        :label="label"
        :model-value="textValue"
        :styleType="styleType"
        :with-bg="withBg"
        class="attach_file"
        type="text"
        withoutFocus
        @click="fileInputClick"
      />
      <input
        :id="`fileInput${uuid}`"
        :class="{ paddingRight: withIcon }"
        :multiple="multiple"
        class="hidden fileInputBox__hidden-input"
        type="file"
        @change="updateValue($event)"
      />
      <span v-if="withIcon" class="inputBox__append-icon">
        <base-icon
          :height="height"
          clickable
          color="#94857d"
          name="attach_file"
          width="21"
          @click="fileInputClick"
        />
      </span>
      <base-button
        v-if="!withIcon"
        :text="btnText"
        class="fileInputBox__btn"
        height="36"
        style-type="greenBorder"
        width="162"
        @click="fileInputClick"
      />
      <span v-if="isError || error?.length" class="inputBox__error">{{ error }}</span>
    </div>

    <div
      v-if="files && (Array.isArray(files) ? files.length : true)"
      class="inputBox__file-link mt--5"
    >
      <base-popup :show-close-icons="false" start>
        <button class="hoverUnderline">Прикрепленные файлы</button>
        <template #content>
          <div v-if="Array.isArray(files)" class="fileInputBox__popup-menu">
            <div
              v-for="(file, i) in files"
              :key="i"
              class="fileInputBox__popup-menu-item"
            >
              <a :href="getLink(file)" target="_blank">
                {{ file.name }}
              </a>
              <base-icon
                v-if="withDeleteFile"
                clickable
                color="black"
                font-size="16"
                height="10"
                name="close"
                width="10"
                @click="$emit('deleteFile', file.id)"
              />
            </div>
          </div>
          <div v-else class="fileInputBox__popup-menu">
            <div class="fileInputBox__popup-menu-item">
              <a :href="getLink(files as FileType)" target="_blank">
                {{ (files as FileType).name }}
              </a>
              <base-icon
                v-if="withDeleteFile"
                clickable
                color="black"
                font-size="16"
                height="10"
                name="close"
                width="10"
                @click="$emit('deleteFile', (files as FileType).id)"
              />
            </div>
          </div>
        </template>
      </base-popup>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, getCurrentInstance, ref } from 'vue';
import { FileType, StylesType } from '@/types';
import { ComponentInternalInstance } from '@vue/runtime-core';
import { prepareUrl } from '@/utils/Common/Common';

// eslint-disable-next-line no-undef
const inputValue = defineModel<FileList | null>();
const instance: ComponentInternalInstance | null = getCurrentInstance();
const uuid = ref(instance?.uid);

const props = withDefaults(
  defineProps<{
    label?: string;
    btnText?: string;
    styleType?: 'light';
    styles?: StylesType;
    height?: string;
    withIcon?: boolean;
    multiple?: boolean;
    files?: FileType | FileType[];
    error?: string;
    withDeleteFile?: boolean;
    withBg?: boolean;
  }>(),
  {
    styleType: undefined,
    btnText: 'Загрузить',
    height: '36',
    withDeleteFile: true,
  },
);

const emit = defineEmits(['focus', 'deleteFile']);

const isError = ref(false);

const getLink = (file: FileType): string | null => {
  if (file?.path?.includes('http')) {
    return file?.path;
  }
  if (file?.path) {
    return prepareUrl(file.path) as string;
  }

  return null;
};

const cssProps = computed(() => {
  return {
    '--border': props.styles?.border,
    '--background-color': props.styles?.backgroundColor,
    '--min-height': `${props.height}px`,
  };
});

const fileInputClick = () => {
  emit('focus');
  const fileInput = document.getElementById(`fileInput${uuid.value}`);

  if (fileInput) {
    fileInput.click();
  }
};

const textValue = ref('');

type Event<T = EventTarget> = {
  target: T;
};
const updateValue = (val: Event) => {
  inputValue.value = val.target.files;
  if (val.target.files) {
    textValue.value = Object.values(val.target.files)
      ?.map(el => el.name)
      .join(' ');
  }
};
</script>

<style lang="scss" scoped src="./BaseInput.scss"></style>
